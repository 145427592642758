@import 'global-styles/colors', '../../../WidgetSettingsShared/sharedStyles';

.RivalBrandsForm {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .Label {
    @include label;
  }

  .FormControl {
    height: 24px;

    span {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      font-family: 'Scansky';
    }
  }
}
