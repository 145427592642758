@import 'global-styles/mixins/factor-colors';

.MarkerTooltip {
  @include FactorColors;

  border-radius: 8px;
  pointer-events: none;
  position: absolute;

  padding: 5px;

  transform: translate(-50%, 0);
  transition: all 0.1s ease;

  width: fit-content;

  .TooltipContents {
    position: relative;

    padding: 12px;

    width: 100%;
  }
}

.Score {
  height: 32px;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  position: absolute;

  border-radius: 5px;
  padding: 5px;

  .ScoreValue {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }

  .ScoreDate {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }
}

.MarkerEventTooltip {
  position: absolute;
  border-radius: 8px;
  height: max-content;

  width: max-content;

  background-color: #6b6b75;

  .DescriptionText {
    color: #ffffff;
    padding: 10px;
    font-weight: 300;
    font-size: 14px;
    text-align: right;
  }
}
