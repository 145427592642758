@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.MarketIndexTrend {
  .Title {
    @include H2DarkLeft;
  }

  .Paragraph {
    @include Intro;
  }

  .GraphLegend {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .GraphSupport {
    margin-top: 5px;
    padding-left: 35px;
    padding-right: 5px;

    @media (min-width: $breakpoint-md) {
      padding-left: 48px;
      margin-top: -8px;
    }
  }

  .GraphContainer {
    width: 100%;

    height: 250px;
  }

  .DateSelector {
    [class*='RadioDateSelector'] {
      display: none;
      @media (min-width: $breakpoint-md) {
        display: flex;
      }
    }
  }

  [class*='ScoreLegend'] {
    display: none;
    @media (min-width: $breakpoint-md) {
      display: flex;
    }
  }
}
