@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

$label-padding: 16px;

.TypeaheadInput {
  position: relative;
  padding: 0 4px;
  height: 32px;

  &.HasLabel {
    height: 46px;

    .InputWrap {
      margin-top: $label-padding;
    }

    .ResultList {
      margin-top: $label-padding;
    }
  }

  &.ResultsOpen {
    .InputWrap {
      transition: all 0.15s ease-in-out;
      padding: 8px;

      .Input {
        transition: all 0.15s ease-in-out;
        width: calc(100% - 14px);
      }
    }
  }

  &.Dropdown {
    .InputWrap {
      .Input {
        border: solid 1px $input-disabled;
        background-color: $ui-table-col;

        input {
          cursor: default;
        }
      }

      .Caret {
        width: 24px;
      }
    }

    &.ResultsOpen {
      .InputWrap {
        .Input {
          background-color: $color-gray-10;

          input {
            cursor: text;
          }
        }

        .Caret {
          display: none;
        }
      }
    }
  }

  .Label {
    @include LabelGreyLeft;

    transform: translate(0, -2px) scale(1);
    color: $color-gray-base-60 !important;

    span {
      color: $color-semantic-error-base-50 !important;
    }
  }

  .InputWrap {
    position: absolute;
    width: 100%;
    margin-top: 0;
    transition: all 0.15s ease-in-out;
    background-color: white;

    .Input {
      @include DefaultGreyLeft---Italic;

      width: 100%;
      border-radius: 8px;
      background-color: $color-gray-10;
      padding-left: 8px;
      z-index: 10;
      transition: all 0.15s ease-in-out;

      height: 28px;
    }
  }

  .ResultList {
    z-index: 1;
    margin-top: 0;
    position: absolute;
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eef2f5;
    border-radius: 8px;
    transition: all 0.15s ease-in-out;
    padding-top: 28px;
    background-color: white;

    .Body {
      max-height: 160px;
      overflow: scroll;
      margin: 16px 0 8px 0;
      box-shadow: inset 0 -1px 0 0 $color-gray-20;

      .List {
        list-style: none;
        margin: 0;
        padding: 0;

        .ListItem {
          @include MenuDefault-32;

          display: flex;
          align-items: center;

          cursor: pointer;
          padding: 4px 16px;

          &:hover {
            background-color: $color-primary-10;
          }

          .Img {
            height: 24px;
            width: 24px;

            margin-right: 8px;

            border-radius: 50%;
          }

          .HelperText {
            font-size: 10px;
            margin-left: 8px;

            color: $color-gray-70;
          }
        }
      }
    }

    .Footer {
      @include SmallDarkLeft-Bold;
    }
  }
}
