@import 'global-styles/colors';

.CompareBrandsContainer {
  display: flex;
  overflow-x: auto;

  .Add {
    position: absolute;
    right: 16px;
    cursor: pointer;
    background-color: $color-secondary-80;
    border: solid 1px $color-secondary-80;
    outline: none;
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 32px;
    width: 32px;

    svg {
      outline: none;
    }
  }

  .Card {
    margin: 0 10px;

    &:last-of-type {
      margin-right: 60px;
    }
  }

  .CardCarosel {
    display: flex;
    position: relative;
    padding-left: 22px;
  }

  .CloseButton {
    cursor: pointer;
    border: transparent;
    background-color: transparent;
    color: white;
    height: 36px;
    width: 36px;
  }

  .HelperBar {
    background-color: $color-secondary-80;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    position: absolute;
    height: 56px;
    top: 0;
    right: 0;
    left: 0;

    .BarTitle {
      color: white;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }

    .CompareFilters {
      display: flex;

      .Dropdown {
        padding: 0 8px;
        background-color: $color-secondary-80;
        color: white;
      }
    }
  }

  .Icon {
    color: white;
    transform: scale(1.5);
  }
}
