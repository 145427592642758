@import 'global-styles/colors';

.Card {
  width: 568px;
  height: fit-content;
  padding: 15px 20px;
  background-color: white;
  border-radius: 8px;

  .Header {
    display: flex;
    align-items: center;
    position: relative;
    height: 70px;
    margin-bottom: 12px;

    .Logo {
      margin-right: 16px;
    }

    .Icon {
      color: $color-gray-90;
      cursor: pointer;
      height: 28px;
      width: 28px;
      position: absolute;
      right: 20px;
    }
  }

  .Assets {
    margin-bottom: 8px;

    .Label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
    }
  }

  .AssetCard {
    aspect-ratio: 4/3;
    padding: 8px;
  }

  .Pager {
    margin: 8px 0;
  }

  .NoCreatives {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $color-gray-20;
    border: solid 1px $color-gray-20;
    border-radius: 8px;
    padding: 30px;
  }
}
