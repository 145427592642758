@import 'global-styles/colors';

.TablePin {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border-radius: 8px;

  &.Active,
  &:hover {
    background-color: $brand-color9;
  }
}
