@import 'global-styles/mixins/fonts';

.MarketIndexSubFactor {
  .Title {
    margin: 0 0 24px 0 !important;
  }

  .ScoreTileContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .ScoreRatingDisplayText {
      @include H3DarkLeft;
    }
  }

  .GraphContainer {
    height: 200px;
    width: 100%;
  }

  .GraphTools {
    padding-left: 40px;

    margin-bottom: 20px;
  }

  .LegendContainer {
    margin-bottom: 50px;
  }

  .ScreenshotFactorName {
    display: none;

    margin-bottom: 20px;
  }
}
