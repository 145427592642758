.BlueScoreWidget {
  background-color: white;
  display: grid;
  gap: 24px;

  .GraphWrapper {
    display: grid;
    gap: 9px;
    margin-top: -8px;
  }
}
