@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';
@import 'global-styles/breakpoints';

.HintOptionsDropdown {
  display: none;

  @media (min-width: $breakpoint-md) {
    display: inline-flex;
  }

  .Button {
    &.Open {
      background-color: #d1e2e6 !important;
    }
  }
}

.Icon {
  height: 24px;
  width: 24px;
}

.Popover {
  .OptionsList {
    list-style: none;
    margin: 0;
    padding: 0;

    button {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
    }

    .ListItem {
      @include DefaultDarkLeft---Italic;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #eef9fb;
      }

      .Icon {
        margin: 5px;
        path {
          &:nth-child(2) {
            fill: $color-gray-base-60;
          }
        }
      }
    }
  }
}
