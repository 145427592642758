@import 'global-styles/colors';

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .PercentNumber {
    font-family: 'Scansky';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 44px;
    margin: 0;

    color: $color-chart-black;

    margin-left: 4px !important;
  }

  .LabelSmall {
    font-size: 11px !important;
  }
}
