.LoadingWidget {
  display: flex;
  padding: 10px;
  height: 100%;
  width: 100%;

  /* **
    Generic Class components
  ** */
  .Header {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    height: 52px;
    width: 100%;
  }

  .ChartContainer {
    height: calc(100% - 52px);
    width: 100%;
    display: flex;
  }

  .MetricSquare {
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    border-radius: 8px;
    animation: loadingPulse 1.5s infinite alternate ease-in-out;
  }

  .Chart {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    animation: loadingPulse 1.5s infinite alternate ease-in-out;
  }

  .Row {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    border-radius: 8px;
    animation: loadingPulse 1.5s infinite alternate ease-in-out;
  }

  .ESOV,
  .GroupMetricWidget,
  .SingleMetricWidget,
  .SummaryTrendsWidget,
  .VisualMapWidget {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    border: solid 1px transparent;
    border-radius: 8px;
    background-color: white;
  }

  /* **
    Widget specific style
  ** */
  .GroupMetricWidget,
  .SummaryTrendsWidget .Header {
    justify-content: flex-end;

    .MetricSquare {
      flex: 0 1 140px;
    }
  }

  .GroupMetricWidget .ChartContainer {
    flex-direction: column;
    gap: 8px;
  }

  .ESOV .Header .MetricSquare {
    flex: 0 1 140px;
  }

  .ESOV .ChartContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    justify-content: center;
    align-items: center;

    .MetricSquare {
      height: 45%;
      width: 24%;
      flex: none;
    }
  }

  .VisualMapWidget .WidgetBody {
    display: flex;
    height: calc(100% - 60px);
    gap: 8px;

    .Column {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 66%;
    }

    .Column:first-of-type {
      width: 33%;
      .Row:first-of-type {
        animation: none;
      }
    }
  }
}

@keyframes loadingPulse {
  0% {
    background-color: #cfd0d2;
  }
  100% {
    background-color: #eaebed;
  }
}
