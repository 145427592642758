@import 'global-styles/colors';

.BarTooltip {
  background-color: white;
  color: $color-chart-black;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transition: all 0.1s ease;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  width: fit-content;
  z-index: 1;

  .TooltipContents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .Label,
  .Value {
    display: flex;
    align-items: center;
  }

  .ColorSquare {
    height: 14px;
    width: 14px;
    border-radius: 1px;
    background-color: white; // This will be overridden
    margin-right: 8px;
  }

  .Label {
    margin-right: 32px;
  }

  .Value {
    font-weight: bold;
  }

  .TooltipArrowLeft,
  .TooltipArrowRight {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
  }

  .TooltipArrowLeft {
    border-right: 10px solid white;
    left: -6px;
  }

  .TooltipArrowRight {
    border-left: 10px solid white;
    right: -6px;
  }
}
