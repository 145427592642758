@import 'global-styles/colors';

.Modal {
  width: 500px;
  height: 500px;
  background-color: #fff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .Group {
    width: 60%;
    font-family: Scansky;

    .Label {
      color: $color-gray-base-60;
      font-size: 13px;
      font-weight: 300;
      line-height: 20px;
    }

    .Dropdown {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      border-radius: 8px;

      box-shadow: 0px 1px 2px 0px $shadow-input;
    }
  }

  .ModalHeader {
    display: flex;
    height: 64px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #fff;

    background: var(--dark, $color-gray-90);

    p {
      color: var(--White, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Scansky;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .closeButton {
      background-color: transparent;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #fff;
    }
  }

  .Content {
    display: flex;
    padding: 24px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 42px;
    align-self: stretch;
  }

  .ModalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-self: stretch;

    .BackButton {
      color: $color-secondary-80;
      border-radius: 8px;
      border: 1px solid $color-secondary-80;
      background: #fff;
    }

    .NextButton {
      background-color: $color-secondary-80;
      color: #fff;
    }

    .NextButtonDisabled {
      background-color: $color-gray-base-60;
      color: #fff;
    }

    button {
      display: flex;
      width: 136px;
      height: 32px;
      justify-content: center;
      align-items: center;
    }
  }
}
