@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.SubfactorCalculationBreakdown {
  .Title {
    @include H2DarkLeft;

    margin: 0;
  }

  .Paragraph {
    @include Intro;
  }

  .ImpactExplanation {
    margin: 4px 0 32px 20px;
    padding: 10px 20px;
    gap: 32px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 8px;
    background: rgba(238, 250, 252, 0.50);

    .Indented {
      @include Intro;
      padding: 10px 20px;
      margin: 0 0 0;

      a {
        cursor: pointer;
        color: $color-primary-80-link;
      }
    }
  }
  .Link {
    cursor: pointer;

    color: $color-primary-80-link;
  }
}
