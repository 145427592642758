@import 'global-styles/colors';

.ModalFooter {
  box-sizing: border-box;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  display: flex;
  justify-content: flex-end;

  background-color: $color-secondary-10;
}
