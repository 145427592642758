@import 'global-styles/colors';
@import 'global-styles/fonts';

.SelectAccordion {
  border-radius: 8px;
  padding: 12px 24px;
  background-color: white;

  font-family: $brand-primary-font-family;

  .AccordionGroup {
    box-shadow: none;
    border-top: solid 1px rgba(0, 0, 0, 0.15);

    .AccordionSummaryContent {
      margin: 0;
    }

    .GroupLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      width: 100%;

      font-size: 1.125rem;

      .GroupLeft {
        padding: 12px 0;
        width: 100%;
      }
    }

    .AccordionDetails {
      padding: 0 0 4px 0;
    }

    .ItemsList {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .ItemLine {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      border-radius: 8px;
      box-sizing: border-box;

      padding: 3px 8px 3px 44px;

      font-size: 1rem;

      user-select: none;

      cursor: pointer;

      .ItemLeft {
        p {
          text-indent: 3em;
        }
      }

      &:hover {
        background-color: $color-primary-20;
      }
    }
  }

  .AccordionGroup:first-of-type {
    border-top: none;
  }

  // mui material handling of border is inconsistent. Hide it and handle it ourselves
  .ExpandedBorder::before {
    opacity: 0 !important;
  }

  .MuiAccordion-rounded::before {
    height: 0;
  }
}
