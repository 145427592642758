@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.BrandDatasetFilterItem {
  @include DefaultDarkLeft---Italic;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-sm) {
    @include Default-Dark-Center;
    width: 100%;
    justify-content: space-between;
  }

  &.hidden {
    @include Default-Dark-Center;
  }

  .BrandInfo {
    display: flex;
    align-items: center;
  }

  .IconDropdown {
    position: relative;

    .Dropdown {
      display: flex;
      align-items: center;
      position: absolute;
      top: 32px;
      right: 0;
      background-color: $ui-table-col;
      z-index: 100;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: solid 1px #eef2f5;
      white-space: nowrap;

      &.Closed {
        display: none;
      }
    }
  }

  .CaretDown {
    display: none;
    width: 24px;
    background-color: transparent;
    border: none;

    @media (min-width: $breakpoint-sm) {
      display: block;
    }

    &:hover > svg {
      color: $color-secondary-80;
    }
  }

  .TooltipContentsContainer {
    @include DefaultDarkLeft;

    display: flex;
    align-items: center;

    .TooltipContents {
      display: flex;
      align-items: center;

      gap: 4px;

      cursor: pointer;
    }
  }
}
