@import 'global-styles/colors';

.BrandSelector {
    // TODO: remove hackiness after compare button changes
    min-width: 269px;
    font-family: 'Scansky';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: $brand-color11;
}
