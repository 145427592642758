@import 'global-styles/mixins/fonts';

.ChartVolume {
    [class*="rv-xy-plot__axis__ticks"] {
        [class*="rv-xy-plot__axis__tick"]{ 
            display: none;
            &:first-of-type {
                display: block;
                text {
                    text-anchor: start;
                }
            }
            &:last-of-type {
                display: block;
                text {
                    text-anchor: end;
                }
            }
        }
    }
}