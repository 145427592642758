.ShareDashboardModal {
  position: relative;
  width: 564px;
  max-height: 564px;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 56px;

    color: white;
    background-color: #2b3a45;

    .Icon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      padding: 0 16px;
    }

    .Left {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        color: white;
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
      }

      .Icon {
        cursor: default;
      }
    }
  }

  .Section {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;

    .SectionHeader {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    .List {
      display: flex;
      padding: 0;
      gap: 16px;
      flex-direction: column;
      height: 300px;
      overflow-y: auto;

      .AccessItem {
        display: flex;
        justify-content: space-between;
        width: 100%;

        p,
        .RoleBadge {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          padding: 0 8px;
          font-family: Scansky;
        }

        .RoleBadge {
          color: #7d7d7d;
          margin-right: 8px;
        }
      }
    }
  }

  .Dropdown {
    height: 32px;
    border-radius: 8px;
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 64px;
    position: absolute;
    bottom: 0;

    background-color: #f1f6f7;

    .InviteButton {
      outline: none;
      border: none;
      border-radius: 5px;

      background-color: #335e66;
      cursor: pointer;

      height: 32px;
      width: 146px;
      margin: 0 16px;

      color: white;
      font-weight: 400px;
      font-size: 12px;
      line-height: 16px;

      &:disabled {
        background-color: #d8dde2;
        color: #5f6368;
        cursor: not-allowed;
      }
    }
  }

  .AccessRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin-bottom: 2px;

    .RemoveButton {
      color: #335e66;
      text-align: center;
      font-family: Scansky;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      width: 65px;
      height: 29px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-right: 10px;

      border-radius: 6px;
      border: 1px solid rgba(51, 94, 102, 0.5);
      background: rgba(255, 255, 255, 0);
    }

    .RemoveButton:hover {
      background: #d1e2e6;
    }
  }
}
