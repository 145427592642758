$tag-bg-color: #f3f5fd;
$tag-font-color: #3350b2;

.Tag {
  display: inline-flex;

  background-color: $tag-bg-color;

  font-weight: 400;
  font-size: 11px;
  color: $tag-font-color;

  padding: 5px 8px;

  border-radius: 14px;
}
