@import 'global-styles/colors';

.SettingStepIcon {
  color: $icon-default;

  svg {
    height: 24px;
    width: 24px;
  }
}
