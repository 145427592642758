@import 'global-styles/colors';

.BlueScoreWidgetControl {
  display: flex;
  gap: 9px;
  overflow-x: auto;

  .Brand {
    display: flex;
    gap: 11px;
    flex-direction: column;
    align-items: center;

    width: 69px;
    min-width: 69px;
    border: none;
    padding: 8px 1px;
    background-color: white;
    transition: background-color 250ms ease-in-out;

    font-size: 12px;
    line-height: 12px;
    font-weight: 300;

    &:hover {
      background-color: $color-primary-20;
    }

    &.ActiveBrand {
      background-color: $color-gray-10;

      .ScoreTile {
        width: 59px;
      }
      &:hover {
        background-color: $color-primary-20;
      }
    }

    .Label {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: 10px;
      line-height: 11.5px;
    }

    .ScoreTile {
      border: none;
    }

    .Delta {
      font-size: 10px;
      opacity: 1;
      top: 4px;
      right: 7px;
    }
  }
}
