@import 'global-styles/colors';

.BrandTag {
  display: flex;
  align-items: center;

  background-color: $color-gray-10;
  border: solid 1px $color-gray-20;
  border-radius: 8px;

  padding: 4px 8px;
  gap: 6px;
  width: fit-content;

  .Title {
    font-weight: 300;
    size: 11px;
    line-height: 13px;
    margin: 0;
  }
}
