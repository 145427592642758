.Wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .GraphContainer {
    min-height: 300px;
    width: 100%;
  }
}

.ChartLegend {
  padding-left: 15px;
}
