@import 'global-styles/mixins/fonts';

.PastReportsArchiveList {
  height: 100%;
  width: 100%;

  .ReportsListContainer {
    padding: 8px 12px;

    .ListItem {
      box-shadow: inset 0 -1px 0 0 #e9edf0;
    }
  }

  .NoReportsContainer,
  .LoadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    padding-bottom: 30px;
  }

  .NoReportsContainer {
    .SubIcon {
      margin-right: 16px;
    }

    .Message {
      @include DefaultGreyLeft;
    }
  }
}
