@import 'global-styles/breakpoints';

.ScorecardGrid {
  width: inherit;
  overflow: initial;

  .Table {
    width: inherit;
    transform-origin: 0 0;
    height: 100%;
  }

  .Head {
    .Cell {
      border: 0;

      &:nth-child(2) {
        border-right: 0;
      }
    }
  }

  .Body {
    .Row {
      &:first-child {
        .Cell {
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid black !important;
        }
      }

      &:nth-child(2) {
        .Cell {
          padding-top: 8px;
        }
      }

      &:last-child {
        .Cell {
          padding-bottom: 0;
        }
      }

      .Cell {
        &.Hidden {
          border: none;

          .Tile {
            visibility: hidden;
          }
        }
      }
    }
  }

  .LabelContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -4px;

    .Label {
      margin-left: 0;

      @media (min-width: $breakpoint-sm) {
        margin-left: 16px;
      }
    }
  }

  .ScoreTileCell {
    min-width: 72px;

    cursor: pointer;

    @media (min-width: $breakpoint-sm) {
      min-width: 112px;
    }
  }

  .Cell {
    border: 0;
    padding: 4px;

    &.Brand {
      padding: 8px 8px 8px 0;
    }

    &:nth-child(2) {
      padding-left: 8px;
      border-right: 1px solid black;
      padding-right: 8px;
    }

    &:nth-child(3) {
      padding-left: 8px;
    }
  }
}
