@import 'global-styles/mixins/fonts';

.ResourcesCTA {
  border-radius: 8px;

  .Header {
    display: flex;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
    background-color: $color-primary-20;

    h5 {
      margin: 0;
    }
  }

  .Icon {
    width: 24px;
    height: 24px;
    color: #20434a;
  }

  .Text {
    @include Intro;

    &:first-child {
      margin-top: 0;
    }
  }

  .Body {
    border-radius: 0px 0px 8px 8px;
    background-color: $color-primary-10;
  }

  .ButtonWrap {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
