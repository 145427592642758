@import 'global-styles/breakpoints';
@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ContainerWithMobileBorder {
  & + & {
    border-top: 1px solid $color-gray-20;
    @media (min-width: $breakpoint-md) {
      border-top: 0;
    }
  }
}

.ContainerInner {
  padding: 0 16px;

  @media (min-width: $breakpoint-md) {
    padding: 0;
  }
}

.WorkspaceContainer {
  padding: 0 16px;

  .TabNavContainer {
    margin-bottom: 24px;
  }

  .WorkspaceTitleContainer {
    width: 100%;

    h1,
    h2 {
      margin: 16px 0;

      @include H2DarkLeft;
    }
  }
}

.PageContainer {
  padding: 0px 16px;
  padding-bottom: 64px;

  .PageTitleContainer {
    width: 100%;

    margin-bottom: 16px;

    h1,
    h2 {
      @include H1DarkLeft;

      margin: 0;

      display: flex;
      align-items: flex-start;
    }

    ._pendo-badge {
      margin-top: 12px !important;
    }

    @media (min-width: $breakpoint-md) {
      border-bottom: 0;
    }
  }
}

.SectionContainer {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .SectionTitleContainer {
    margin-bottom: 16px;

    h4 {
      @include H4DarkLeft;

      margin: 0;
    }
  }
}

.DownloadButtonContainer {
  display: none;
  @media (min-width: $breakpoint-md) {
    display: block;
  }
}
