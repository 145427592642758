@import 'global-styles/colors';

.Container {
  max-width: 100%;
  .ContentContainer {
    margin-top: 12px;
    display: grid;
    column-gap: 24px;
    grid-template-columns: 3fr 1fr;
  }
}

.InsightCard {
  padding-right: 8px;
  .Button {
    background-color: $color-semantic-info-90 !important;
    box-sizing: border-box;
    width: 100%;
  }
}
