@import 'global-styles/colors';
@import 'global-styles/fonts';

.Full {
  $font-family: $brand-primary-font-family;

  .Container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .MetricNumber {
    font-size: 38px;
    color: $color-chart-black;

    font-style: normal;
    font-weight: 600;

    text-align: center;
  }

  .Chart {
    height: 40px;
    width: 136px;
  }

  .DisplayIconContainer {
    min-width: 100px;
    display: flex;
  }

  .Diff {
    .DiffValue {
      font-size: 18px;
      font-weight: 300;
    }
  }

  .IndexedOnlyMsg {
    font-size: 11px;
    font-weight: 300;
    color: #7d7d7d;
  }
}
