@import 'global-styles/colors';

.DateLabel,
.Label {
  display: flex;
  align-items: center;
  margin: 4px 2px;
}

.ColorSquare {
  height: 14px;
  width: 14px;
  border-radius: 3px;
  background-color: white;
  border: solid 1px $color-gray-70;
  margin-right: 8px;
}
