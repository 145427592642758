@import 'global-styles/colors';

.ImpactResultRadioSelector {
  cursor: pointer;

  .RadioItem {
    border: solid 1px $input-disabled;

    border-radius: 8px;

    height: 72px;

    display: flex;

    .ImpactResultContainer {
      display: flex;
      align-items: center;

      height: 100%;
      width: 100%;
    }
  }
}
