@import 'global-styles/mixins/fonts';

.TitleEditor {
  @include H1LightLeft;
  height: 40px;
  width: 100%;
  display: flex;

  .EditorTitle,
  .Input {
    @include H1LightLeft;
    color: white;
  }

  .EditorTitle {
    border: unset;
    background: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }

  .EditingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .Input {
      outline: none;
      border: none;
      border-bottom: solid 2px white;
      background: transparent;
      color: white;
      width: 95%;
    }

    .CharacterCount {
      font-weight: 300;
      font-size: 13px;
    }
  }
}
