@import 'global-styles/colors';

.TopList {
  display: flex;
  flex-direction: column;

  .ListItem {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    margin: 12px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .Header {
    color: $color-gray-70;
    margin-right: 4px;
  }
}
