@import 'global-styles/colors';
@import 'global-styles/fonts';

.Label {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  font-weight: 400;
  color: $color-chart-black;

  padding-left: 0;
}
