@import 'global-styles/fonts';
@import 'global-styles/colors';

.CalculationsAndSources {
  font-family: $brand-primary-font-family;
  background-color: white;

  .Tabs {
    border-bottom: 1px solid $color-gray-20;
    margin-right: 100px;
  }

  .Tab {
    font-family: $brand-primary-font-family;
    font-size: 11.5px;
    font-style: normal;
    font-weight: 400;

    width: 160px;
  }

  .SelectedTab {
    color: #2b3a45;
  }

  .TabIndicator {
    background-color: $color-primary-base-60;
    height: 3px;
  }

  .TabContent {
    padding-top: 24px;

    .Header {
      margin: 0;

      font-weight: 300;
      font-size: 21px;
      line-height: 24px;
    }

    .CalculationsTab {
      .HelperText {
        margin: 0;
        margin-top: 4px;

        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #7f8b95;
      }

      .Calculations {
        margin-top: 24px;

        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .CalculationRow {
        display: flex;
        align-items: center;
        gap: 24px;

        .CalculationScreenshot {
          min-width: 224px;
          max-width: 224px;
          max-height: 138px; // needed to constrain blank images
          // confirmed other screenshots are currently smaller than 138px tall
        }

        .CalculationDescription {
          p {
            margin: 0;
            font-weight: 300;
            font-size: 13px;
            line-height: 21px;
          }

          strong {
            font-weight: 600;
          }
        }
      }
    }

    .SourcesTab {
      .MetricCards {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .MetricCard {
          border: 8px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
          border: 0.665px solid rgba(0, 0, 0, 0.05);
        }
      }

      .BackButton {
        display: inline-flex;
        align-items: center;
        align-self: flex-start;
        gap: 4px;

        height: 30px;
        padding: 8px 12px;
        background-color: $color-primary-10;
        border-radius: 15px;
        box-sizing: border-box;

        cursor: pointer;

        .BackText {
          color: $color-primary-80-link;
          white-space: nowrap;
          font-weight: 300;
          font-size: 14px;
          margin: 2px 0 0 0;
        }
      }

      .ReadMoreContent {
        display: flex;
        flex-direction: column;
        gap: 24px;

        section {
          .SectionHeader {
            font-weight: 300;
            font-size: 13px;
            color: $color-gray-50;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            white-space: nowrap;
            margin: 0 0 4px 0;
          }

          .SectionTextContainer {
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              margin: 0;
            }

            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;

              display: flex;
              flex-direction: column;
              gap: 6px;

              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }

            ol {
              margin: 0;

              display: flex;
              flex-direction: column;
              gap: 6px;

              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }

          &.MetricDescriptionSection {
            display: flex;
            gap: 100px;

            .DetailsCol {
              display: flex;
              flex-direction: column;
              gap: 16px;

              min-width: 230px;
            }

            .DetailSection {
              display: flex;
              flex-direction: row;
              gap: 8px;

              .Icon {
                margin-top: 2px;
              }

              .SectionHeader {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
