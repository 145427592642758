@import 'global-styles/mixins/fonts';

.MarketIndexRelativePerformance {
  .Title {
    @include H2DarkLeft;

    margin: 16px 0;
  }

  .Paragraph {
    @include Intro;
  }
}
