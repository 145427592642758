@import 'global-styles/colors';

$asset-overflow-bg-color: #e5e5e5;
$asset-font-color: $font-color-light-bg;

.CreativeAssetCard {
  width: 100%;
  height: 100%;

  background-color: $asset-overflow-bg-color;

  border-radius: 8px;

  overflow: hidden;

  position: relative;

  cursor: pointer;

  &:hover,
  &.Hover {
    .AssetContent {
      display: none;
    }

    .AssetHoverContent {
      display: block;
    }
  }

  .FloatingIcon {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .AssetContent {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .AssetCardImg {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    .NoThumbnail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      font-weight: 300;
      font-size: 13px;
      color: $asset-font-color;
    }

    .MajorIcon {
      path {
        fill: $asset-font-color;
      }
    }
  }

  .AssetHoverContent {
    display: none;

    padding: 16px;

    color: $asset-font-color;

    .CreativeName {
      font-weight: 300;
      font-size: 13px;

      margin-bottom: 8px;
    }

    .PreviewList {
      .PreviewListItem {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: 300;
        font-size: 16px;

        padding: 2px 0 3px 0;

        border-bottom: 1px solid $asset-font-color;
      }
    }

    .TagList {
      display: flex;
      align-items: center;
      gap: 6px;

      margin-top: 8px;
    }
  }
}
