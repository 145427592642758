@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.AdminSettings {
  height: 100%;

  .BodyContainer {
    height: 100%;
  }

  .Header {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    h1 {
      padding-bottom: 16px;
    }
  }

  .Body {
    height: 100%;

    .NavTabs {
      height: 100%;
    }

    .Content {
      width: 100%;

      padding-top: 16px;
    }
  }
}
