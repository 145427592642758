@import 'global-styles/mixins/fonts';

.Tab {
  @include LabelDarkCenter;

  height: 52px;

  &.Disabled {
    @include LabelGreyCenter;

    opacity: 0.5;

    pointer-events: auto;
  }
}

.Indicator {
  background-color: #4ec4db;
  height: 3px;
}

.TabContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1986px;
}