.ChartLegendColorLabel {
  height: 12px;
  width: 12px;
  min-width: 12px;

  border-radius: 2px;
  border-width: 2px;
  border-style: solid;

  background-color: white;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover {
    &.Checked {
      .CheckIcon {
        path {
          fill: white;
        }
      }
    }

    &.Unchecked {
      .CheckIcon {
        path {
          fill: #7e9397;
        }
      }
    }

    .CheckIcon {
      opacity: 1;
    }
  }

  &.Unchecked {
    .DashedLine {
      display: none;
    }
  }

  .CheckIcon {
    position: absolute;
    opacity: 0;
    transition: all 0.1s ease-in;

    margin-bottom: -1px;

    path {
      fill: #7e9397;
    }
  }

  .DashedLine {
    width: 100%;
    height: 1px;

    border-top-width: 2px;
    border-top-style: dotted;
  }
}
