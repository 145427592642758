@import 'global-styles/colors';

.ModalContainer {
  padding: 0;
  margin: 20px;

  background: white;
  outline: none;
  border-radius: 8px;

  overflow: visible;

  min-width: 300px;
  width: 100%;

  z-index: 100;

  animation-name: modalAnimate;
  animation-duration: 0.5s;

  .Header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 20px;
    gap: 12px;

    .CloseIcon {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
}

.ModalContainerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba($color: $color-gray-90, $alpha: 0.6);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10000000;
}

@keyframes modalAnimate {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
