@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

$top-nav-height: 56px;
$header-height: 104px;
$footer-height: 164px;

.DashboardGridLayout {
  display: block;

  background: $dashboard-grid-background;
  min-width: 1200px;
  min-height: calc(
    100vh - #{$top-nav-height} - #{$header-height} - #{$footer-height}
  );

  .Notification {
    display: flex;
    align-self: stretch;
    padding: 72px 24px;
    gap: 24px;
    background: $dashboard-grid-background;

    .NoWidgetMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 51px 100px;
      gap: 12px;

      border-radius: 8px;
      background: $notification-message-background;
      margin: auto;

      span {
        font-size: 21px;
        font-weight: 300;
        padding-bottom: 24px;
      }

      .NoWidgetImg {
        height: 167px;
        flex-shrink: 0;
        padding: 12px 0;
      }

      .AddWidget {
        width: 75%;
        background: $brand-color8;
        border: 1px solid $brand-color8;
        color: white;
      }
    }
  }
}

.GridLayout {
  margin-bottom: 20px;
}

.WidgetItem {
  margin: 0;
}
