@import "global-styles/colors";
@import "global-styles/mixins/fonts";
@import "global-styles/breakpoints";

.ThemesWordTagLabel {
  --hoverBorderColor: #{$color-gray-20};
  input {
    display: none;
    &:checked {
      & + .ThemesWordTag {
        border-color: var(--hoverBorderColor) !important;
      }
    }
  }
}
.ThemesWordTag {
  position: relative;
  height: 32px !important;
  width: 100%;

  margin: 8px 0 !important;
  border: solid 2px $color-gray-20 !important;
  transition: border 0.2s ease;
  border-radius: 2px !important;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  .Term {
    position: relative;
    z-index: 2;
  }
  .GraphColor {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
  }
  p {
    @include Default-Dark-Center;
  }
  &:hover {
    cursor: pointer;
    border-color: var(--hoverBorderColor) !important;
  }
  &.Index0 {
    --hoverBorderColor: #{$chart-heatmap-volume-04};
    .GraphColor {
      border-left: 4px solid $chart-heatmap-volume-04;
      background-color: $chart-heatmap-volume-04--opacity85;
    }
    &:hover {
      // border-color: $chart-heatmap-volume-04 !important;
    }
  }
  &.Index1 {
    --hoverBorderColor: #{$chart-heatmap-volume-03};
    .GraphColor {
      border-left: 4px solid $chart-heatmap-volume-03;
      background-color: $chart-heatmap-volume-03--opacity85;
    }
    &:hover {
      // border-color: $chart-heatmap-volume-03 !important;
    }
  }
  &.Index2 {
    --hoverBorderColor: #{$chart-heatmap-volume-02};
    .GraphColor {
      border-left: 4px solid $chart-heatmap-volume-02;
      background-color: $chart-heatmap-volume-02--opacity85;
    }
    &:hover {
      // border-color: $chart-heatmap-volume-02 !important;
    }
  }
  &.Index3 {
    --hoverBorderColor: #{$chart-heatmap-volume-01};
    .GraphColor {
      border-left: 4px solid $chart-heatmap-volume-01;
      background-color: $chart-heatmap-volume-01--opacity85;
    }
    &:hover {
      // border-color: $chart-heatmap-volume-01 !important;
    }
  }
}
