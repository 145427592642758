@import 'global-styles/_colors.scss';

.CreativeContainerMain {
  width: 100%;
  background: $color-gray-10;

  border-radius: 8px;
  padding: 18px 0;

  .Legend {
    width: 100%;

    margin-left: 40px;
    margin-top: 12px;
  }
}

.CreativeAdvertisingCarousel {
  position: relative;

  width: 100%;
  height: 92%;

  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  background: $color-gray-10;
  border-radius: 8px;
  margin-bottom: 2px;

  .ButtonRight {
    right: 3%;
    color: white;
  }

  .ButtonLeft {
    left: 2%;
    color: white;
  }

  .ButtonLeftHidden {
    display: none;
  }

  .ButtonRightClicked {
    display: none;
  }

  button {
    position: absolute;
    top: 40%;
    background: $button-primary-hover;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 0;
    z-index: 1;
    transition: background 0.2s ease-in;

    &:hover {
      cursor: pointer;
      transition: all 0.5s ease-out;
      background: $color-secondary-80;
    }
  }

  ul {
    margin: 0;
    padding-left: 10px;
    display: flex;
    align-items: center;
    list-style: none;
    overflow-x: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      background: transparent; /* make scrollbar transparent */
      width: 0;
      height: 0;
    }
  }
}

.CardContainer {
  flex: 433px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -12px;
  padding: 12px;
  gap: 8px;

  .Header {
    display: flex;
    color: $brand-color8;

    svg path {
      height: 10px;
      width: 10px;
      fill: $color-primary-90;
    }

    .Caret {
      margin-left: 6px;
      color: white;
    }
  }

  .Title {
    margin: 0;
    cursor: pointer;
    font-weight: 400;
    font-size: 17px;
    color: $color-primary-80-link;

    &:hover {
      text-decoration: underline;
    }
  }
}
