@import "global-styles/mixins/fonts";
@import 'global-styles/breakpoints';

.ThemesAudienceChart {

    padding: 0 16px;

    box-shadow: inset 0 -1px 0 0 $color-gray-20;
    &:first-of-type {
        border-bottom: 1px solid #6d777f;
    }
    @media (min-width: $breakpoint-md) {
        height: 80px;
        overflow: hidden;
    }
    .ThemesCardItem {
        position: relative;
        height: 100%;
        flex-wrap: nowrap;
        padding: 0 10px;
    }
    @media (max-width: $breakpoint-md) {
        [class*="BrandLogo_Name"]{ 
            display: none
        }
    }
}