@import 'global-styles/_colors.scss';

.LeftNavItem {
  display: flex;
  background: $color-gray-90;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  color: $icon-default;

  &:hover,
  &:focus-within {
    color: white;
  }
}

.Subtitle {
  font-size: 10px;
  padding-top: 4px;
}

.NavItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Modal {
  width: 80%;
}

.ItemOpen {
  z-index: 99;
}

.LeftNavItemActive {
  composes: LeftNavItem;
  border-left: solid 4px $color-primary-30;
  color: white;
}
