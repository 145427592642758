@import "global-styles/mixins/layers";

.TopNavAlertDropdown {
  height: 100%;
  width: 100%;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .NavItemContainer {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Menu {
  @include LayerPopover;

  border-radius: 8px !important;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eef2f5;

  min-width: 240px;

  transform: translateY(44px) !important;
}

.Overlay {
  @include LayerPopover;
}
