@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ArchetypeCardBack {
  height: 100%;

  &.Divider {
    .Header {
      box-shadow: inset 0 -1px 0 0 $color-gray-20;

      .Name {
        margin: 0;
      }
    }
  }

  &.SM {
    .Header {
      svg {
        height: 25px;
        width: 25px;
      }

      .Name {
        font-weight: 400;
        font-size: 18px;

        margin: 0;
      }
    }
  }

  &.MD {
    .Header {
      svg {
        height: 40px;
        width: 40px;

        margin-top: 3px;
      }

      .Name {
        font-weight: 400;

        margin: 0;
      }
    }
  }

  .Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .Icon {
      color: white;
      margin-right: 6px;
    }

    .Name {
      @include ArchetypeCardTitle;
    }
  }

  .Body {
    .Section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-bottom: 20px;

      .Label {
        @include LabelGreyLeft;
        font-weight: normal;
        margin: 0;
        margin-bottom: 8px;
      }

      .Copy {
        @include DefaultDarkLeft---Italic;

        margin: 0;
      }

      .BrandLogos {
        display: flex;

        .Logo {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
