@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.SelectInput {
  &.Disabled {
    .Input {
      background-color: $input-disabled;
    }
  }

  &.Naked {
    .Input {
      border: none;
      background-color: inherit;

      padding: 0;

      div {
        padding: 0 !important;
        padding-right: 24px !important;
      }
    }
  }

  .Label {
    @include LabelGreyLeft;

    z-index: 10;
    transform: translate(0, -2px) scale(1);
    color: $color-gray-base-60 !important;

    span {
      color: $color-semantic-error-base-50 !important;
    }
  }

  .Input {
    @include DefaultGreyLeft---Italic;

    z-index: 0;
    width: 100%;
    border-radius: 8px;
    background-color: $ui-table-col;
    border: solid 1px $input-disabled;
    padding-left: 4px;
    transition: all 0.15s ease-in-out;
  }

  div[role='button']:focus {
    background-color: white !important;
  }
}
