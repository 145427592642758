@import 'global-styles/colors';

.MetricDetailCard {
  display: flex;
  flex-direction: column;
  padding: 24px;
  cursor: pointer;

  font-family: 'Scansky';
  color: $color-gray-90;
  background-color: white;

  border-radius: 8px;

  .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Title {
      font-weight: 400;
      font-size: 18px;
      margin-top: 4px;
      margin-bottom: 24px;
    }

    .SelectedStatus {
      margin-top: 4px;
    }

    .ReadMoreButton {
      display: flex;
      align-items: center;
      align-self: flex-start;
      gap: 4px;

      height: 30px;
      padding: 8px 12px;
      background-color: $color-primary-10;
      border-radius: 15px;
      box-sizing: border-box;

      cursor: pointer;

      .ReadMoreText {
        color: $color-primary-80-link;
        white-space: nowrap;
        font-weight: 300;
        font-size: 14px;
        margin: 2px 0 0 0;
      }
    }
  }

  .Details {
    display: flex;
    gap: 40px;

    .DetailsCol {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .DetailSection {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .Icon {
        margin-top: 2px;
      }
    }
  }

  .TextContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px;

    .SectionHeader,
    .SectionText {
      margin: 0;
    }

    .SectionHeader {
      font-weight: 300;
      font-size: 11px;
      color: $color-gray-50;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }

    .SectionTextContainer {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin: 0;
      }
    }

    &.Description {
      gap: 6px;
      margin-top: 20px;
    }
  }
}
