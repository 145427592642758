@import "global-styles/mixins/fonts";
@import "global-styles/breakpoints";

.MarketIndexBluescore {
  .TitleContainer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .PageTitle {
      display: flex;
      align-items: center;

      h2 {
        @include H2DarkLeft;
      }
    }
  }

  .Paragraph {
    @include Intro;
  }

  .SubfactorLineList {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .LineItem {
      margin-top: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #e9edf0;
    }

    .SubfactorLineListItem {
      &:first-child {
        border-bottom: 1px solid $dark-grey;

        @include H4DarkLeft;
        @media (min-width: $breakpoint-md) {
          border-bottom: 0;
        }
        > div {
          border-bottom-color: transparent;
          @media (min-width: $breakpoint-md) {
            border-bottom-color: $color-gray-20;
          }
          & > div:first-child {
            [class*="SubfactorLabel"] {
              font-weight: $semiBold;
              @include H4DarkLeft;
              @media (min-width: $breakpoint-md) {
                font-weight: inherit;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        .LineItem {
          @media (min-width: $breakpoint-md) {
            box-shadow: inset 2px 3px 5px 0px rgba(233, 237, 240, 0.6);
          }
        }
      }
    }
  }
}
