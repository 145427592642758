@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.BrandPersonality {
  &.Evolution {
    .Chart {
      height: 320px;
    }
  }

  &.Current {
    .Chart {
      height: 440px;
    }
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h1 {
      margin: 0;
    }

    .CtaContainer {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .Body {
    margin-top: 40px;
  }

  .Card {
    margin-bottom: 15px;
  }

  .Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 24px;

    padding: 0 8px;

    .Label {
      @include LabelGreyLeft;

      margin-bottom: 8px;
    }

    .Copy {
      @include DefaultDarkLeft---Italic;
    }

    .BrandLogos {
      display: flex;

      .Logo {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .GraphControlLvl0 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;

    padding-right: 16px;
  }

  .GraphControlLvl1 {
    display: flex;
    justify-content: flex-end;
  }

  .Icon {
    height: 22px;
    width: 22px;
  }

  .ChartChanger {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .Label {
      @include DefaultDarkLeft---Italic;
    }

    .IconWrap {
      padding: 4px 3px 1px 3px;

      border: 1px solid white;

      cursor: pointer;

      &:hover {
        border-radius: 8px;
        border-color: $color-gray-base-60;
      }
    }
  }

  .Chart {
    width: 100%;

    margin-top: 40px;
  }

  .YourBenchmarksInfo {
    display: flex;
    justify-content: flex-end;
  }
}
