@import 'global-styles/colors';

.ButtonsControl {
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 32px 0;

  .Button {
    padding: 8px !important;
  }
}
