@import 'global-styles/colors';

.SwitchRoot {
  height: 44px !important;
  width: 56px !important;
}

.Track {
  border-radius: 22px !important;
}

.Thumb {
  height: 12px !important;
  width: 12px !important;
}

.SwitchBase {
  top: 7px !important;
  left: 7px !important;
}

.Checked {
  transform: translateX(12px) !important;
  color: $color-secondary-80 !important;

  + .Track {
    background-color: $color-secondary-80 !important;
  }
}
