@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ButtonDropdownMenu {
  .Icon {
    width: 24px;
    height: 24px;
  }

  .Button {
    border-radius: 8px;

    min-width: fit-content;
  }

  &.Open {
    .Button {
      background-color: $button-tertiary-default;
    }
  }
}

.Popover {
  margin: 16px 0 0 0;

  border-radius: 8px;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eef2f5;
}

.OptionsList {
  list-style: none;
  margin: 0;
  padding: 8px 0;

  border-radius: 8px;

  .ListItem {
    @include DefaultDarkLeft---Italic;

    display: flex;
    align-items: center;

    cursor: pointer;

    padding: 8px 16px;

    &:hover {
      background-color: #eef9fb;
    }

    .Icon {
      color: $color-gray-base-60;
    }
  }
}
