@import 'global-styles/colors';

.VerbatimWide {
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 8px;
  padding: 16px 16px 16px 8px;

  border: solid 1px $color-gray-20;
  border-radius: 8px;

  .Image{

  }

  .Content {

  }
  & + .VerbatimWide {
    margin-top: 30px;
  }

  .Tag {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    justify-self: end;
  }
}
