@import 'global-styles/colors';

// TODO: alphabetize
@mixin informationCardContainer {
  border-left: solid 4px $color-semantic-info-base-50;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}

@mixin paragraphHeader {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: $color-gray-90;
}

@mixin informationHeader {
  font-size: 21px;
  font-weight: 300;
  line-height: normal;
  color: $color-gray-90;
}

@mixin informationHelperText {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: $font-color-disabled;
}

@mixin pill {
  background-color: $color-primary-20;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  transition: background-color 250ms ease-in-out;
  display: flex;
  width: fit-content;
  padding: 4px 8px;

  &:hover {
    background-color: $color-primary-40;
  }
}

@mixin requiredAstrik {
  color: $required-color;
  padding: 0 4px;
}

@mixin formInput {
  border: solid 1px $input-disabled;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-self: stretch;
  box-shadow: 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $font-color-light-bg;
  outline: none;
  font-family: 'Scansky';
}

@mixin label {
  color: $color-gray-base-60;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

@mixin CalculationsAndSourcesSettings {
  padding: 16px 110px 96px 36px;
  margin: 0 96px 96px 96px;
  border-left: 12px solid $color-semantic-info-base-50;
}
