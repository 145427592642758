@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

$score-tile-width: 56px;

.MetricCard {
  height: 100%;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .CardLoader {
    position: absolute;
    left: 8px;
    bottom: 48px;

    circle {
      color: $brand-color11;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;

    .Header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .Icon {
        cursor: pointer;
      }

      .Title {
        @include H4DarkLeft;
        font-weight: 300;
        margin: 0;
      }
    }

    .ScoreBoxesContainer {
      display: flex;
      flex-direction: column;

      @media (min-width: $breakpoint-md) {
        flex-direction: row;
      }

      .ScoreBoxes {
        width: 100%;

        .ScoreBox {
          box-sizing: border-box;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

          border: solid 1px rgba(0, 0, 0, 0.05);
          border-radius: 8px;

          display: flex;
          align-items: center;

          width: 100%;

          margin-bottom: 8px;

          .MetricScoreTile{

          }

          span {
            @include H4DarkLeft;
            font-weight: 300;
            .CatAvgText {

            }
          }
        }
      }
    }

    .GraphWrap {
      height: 90%;
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;

      .TimelineChartLegend {
        @include SmallGreyLeft;
        margin-top: 8px;
        margin-left: 8px;
      }
    }

    .ChannelContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .SocialMetricChannel {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 32px;

        .ScoreBox {
          box-sizing: border-box;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

          border: solid 1px rgba(0, 0, 0, 0.05);
          border-radius: 8px;

          display: flex;
          align-items: center;
          justify-content: flex-start;

          gap: 8px;
          padding: 8px;

          width: 100%;

          .ChannelName {
            margin: 0 0 6px 0;

            font-size: 14px;
            font-weight: 300;

            .MetricScoreTile {

            }
          }

          .ColRight {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            gap: 12px;

            svg {
              cursor: initial;
              height: 32px;
              width: 32px;
              flex-shrink: 0;
            }

            .ChannelLogo {
              flex-shrink: 0;
            }

            .ChannelName {
              @include H4DarkLeft;
              flex-shrink: 0;
              margin-bottom: 0;
            }
          }
        }
        .ChannelGraphWrap {
          margin-right: 20px;
          height: 94px;
          width: calc(100% - #{$score-tile-width});
        }
      }
    }
  }

  .Footer {
    margin-top:8px;
    width: 100%;
    box-shadow: inset 0 1px 0 0 $color-gray-20;
    @include SmallGreyLeft;
  }
}
