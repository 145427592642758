@import 'global-styles/breakpoints';
@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.Recommendations {
  .WorkspaceTitle {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    margin-bottom: 16px;
  }

  .ActionsContainer {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .Filters {
      display: flex;
      flex-wrap: wrap;

      .GroupByContainer {
        display: flex;
        align-items: center;

        .GroupSelect {
          margin-top: 5px;
        }

        .GroupByLabel {
          @include DefaultDarkLeft-Bold;
        }
      }

      .CompletedFilter {
        display: flex;
        align-items: center;

        .CompletedLabel {
          @include DefaultDarkLeft---Italic;
        }
      }
    }

    .Buttons {
      display: flex;
    }
  }

  .LeftSideBar {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;

      box-shadow: inset -1px 0 0 0 $color-gray-20;
    }
  }
}
