@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.SettingsUsers {
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 45px;

    h3 {
      margin: 0;
    }
  }

  .Body {
    padding-left: 8px;
  }

  .NoResultsContainer {
    padding: 20px 0;
  }

  .UserCounts {
    display: flex;
    margin-bottom: 45px;

    .UserCount {
      margin-right: 45px;
    }

    .ViewerCount {
      .Label {
        @include DefaultDarkLeft-Bold;
      }

      .Count {
        @include DefaultDarkLeft---Italic;
      }
    }
  }
}
