@import 'global-styles/colors';

.ExcessShareOfVoiceGrid {
  width: 100%;
  overflow: hidden;
  margin: 14px 0 0 0;
}

.Table {
  width: inherit;
  height: 100%;
}

.Head {
  .Cell {
    border: 0;
  }
}

.Body {
  .LabelCell {
    width: 200px;
    border: 0;
    padding: 4px;

    p {
      margin: 0;
    }
  }

  .Label {
    font-family: 'Scansky';
    color: $color-gray-90;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  .Subtitle {
    font-family: 'Scansky';
    color: $color-secondary-base-60;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
  }
}

.ScoreTileCell {
  border: 0;
  width: 80px;
  padding: 8px 8px 0 0;
  margin: 8px;
}

.HeaderCell {
  border: 0;
  margin-bottom: 0;
  padding: 0;

  div {
    flex-direction: column;
  }

  p {
    margin: 0;
    font-size: 12px;
    color: $color-gray-40;
  }
}

.Cell {
  border: 0;
  padding: 0 0;

  &.Brand {
    padding: 8px 8px 8px 0;
  }
}
