@import 'global-styles/mixins/fonts';

.ChartLegendItem {
  display: flex;
  align-items: center;

  .Color {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    background-color: gray;
  }

  .Label {
    @include SmallGreyLeft;

    white-space: nowrap;
  }
}
