@import 'global-styles/mixins/fonts';

.PastReportsArchiveListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 8px;

  cursor: pointer;

  &:hover,
  &.Hovered {
    background-color: $color-primary-10;

    .DownloadIcon {
      visibility: visible;
    }
  }

  .ReportDate {
    @include Paragraph;

    font-size: 13px;

    font-weight: 600;

    &.Read {
      font-weight: 300;
    }
  }

  .DownloadIcon {
    height: 26px;
    width: 26px;

    visibility: hidden;
  }
}
