@import 'global-styles/colors';

.ESOVtooltip {
  background-color: white;
  color: $color-chart-black;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transition: all 0.1s ease;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  width: fit-content;
  z-index: 1;

  color: $color-chart-black;
  font-family: Scansky;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .TooltipContents {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .BrandList {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .BrandLabel {
    text-align: left;
    font-weight: 500;
    margin-left: 6px;
  }

  .LabelContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 0 8px;
    margin: 2px;
  }

  .Label {
    font-weight: 300;
    line-height: 22px;
  }

  .Value {
    margin-left: 20px;
    font-weight: 600;
  }

  .Highlight {
    background-color: $color-semantic-success-30;
    border-radius: 8px;
  }

  .HighlightNegative {
    background-color: $color-semantic-error-30;
    border-radius: 8px;
  }

  .TooltipArrowLeft,
  .TooltipArrowRight {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
  }

  .TooltipArrowLeft {
    border-right: 10px solid white;
    left: -6px;
  }

  .TooltipArrowRight {
    border-left: 10px solid white;
    right: -6px;
  }
}
