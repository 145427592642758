@import '../sharedStyles.module.scss';

.SingleMetricSummary {
  display: flex;

  .MetricContainer {
    @include metricContainer;
    justify-content: flex-start;
  }

  .Label {
    @include label;
    text-transform: uppercase;
  }

  .MetricSection {
    @include metricSection;
  }

  .SubTitle {
    @include subTitle;
  }

  .BlueBorder,
  .YellowBorder {
    @include blueBorder;
  }

  .YellowBorder {
    @include yellowBorder;
  }

  .FlexRow {
    flex-direction: row;
  }

  .SubtitleSection {
    display: flex;
    flex-direction: column;
  }

  .Icon {
    height: 32px;
    width: 32px;
  }

  .CohortContainer {
    flex: 1 1 auto;
    justify-content: flex-start;
    gap: 8px;

    .MetricSection {
      flex-direction: column;
      flex: 1 1 auto;
    }
  }

  .MinimizePadding {
    padding: 4px;
  }
}
