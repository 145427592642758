@import 'global-styles/colors';

.MetricGroupWidget {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  .WidgetSubheaderContainer {
    margin-bottom: 16px;
  }

  .Cell {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .CellSmall {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    width: 140px;
  }

  .HeaderCell {
    padding: 0;
    border: none;
  }

  .Row {
    p {
      margin: 0;
      padding: 0;
    }
  }

  .LabelCell {
    width: 300px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.Table {
  font-family: 'Scansky';
}

.Chart {
  height: 70px;
  width: 136px;
}

.ChartCellContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.ChangeLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.RowTitle {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 14px;

  color: $color-chart-black;
}

.HeaderLabel {
  font-family: 'Scansky';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  color: #6d7a84;
}

.MetricCellContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 80px;

  p {
    margin: 0;
  }
}

.MetricGroupCell {
  margin-top: 8px;
  margin-left: 8px;

  p {
    font-size: 18px;
    font-weight: 300;
  }

  svg {
    height: 21px;
    width: 21px;
  }
}

.IndexEmpty {
  font-family: 'Scansky';
  font-weight: 300;
  font-size: 12px;
}
