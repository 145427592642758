@import 'global-styles/colors';

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
  width: 80%;
}

.DateLabel {
  font-size: 15px;
  font-weight: 300;
  color: $color-gray-90;
}

svg.PlayIcon {
  width: 27px;
  height: 27px;
  color: $icon-default;
}

.Slider {
  width: 100%;
  max-width: 274px;
  padding: 0 10px;
}

.Button {
  display: flex;
  align-items: center;
}
