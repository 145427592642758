@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.Metrics {
  @include H2DarkLeft;

  min-height: 300px;

  .TitleRowContainer {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    .HeaderRight {
      display: flex;
      align-items: center;
    }
  }

  .DropdownFilters {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Left {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 0;
        margin-top: -3px;
      }
    }
  }

  .TypeAhead {
    margin-right: 24px;
  }
}
