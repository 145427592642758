@import 'global-styles/mixins/fonts';

.react-calendar {
  border-radius: 4px;
  border: solid 1px #e9edf0;

  width: 240px;

  .react-calendar__navigation {
    padding: 8px;

    background-color: #f4f7fa;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      @include DefaultDarkLeft;

      letter-spacing: normal;

      border: none;
      background-color: inherit;

      padding: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      &.react-calendar__navigation__arrow {
        &:disabled {
          visibility: hidden;
        }
      }
    }
  }

  .react-calendar__viewContainer {
    padding: 0 8px 8px 8px;

    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        height: 32px;
        width: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        abbr[title] {
          text-decoration: none;

          @include TinyDarkLeft;

          color: #7f8b95;
        }
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__tile {
        height: 32px;
        width: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        background-color: inherit;

        @include SmallDarkCenter;

        &:disabled {
          color: #7f8b95;
        }

        &.highlighted-success {
          background-color: #bbdb9b !important;
        }

        &.highlighted-warning {
          background-color: #fecf72 !important;
        }

        &.react-calendar__tile--now {
          @include SmallLinkCenter;

          border: 1px solid #2dadc6;
          border-radius: 8px;

          abbr {
            color: #2b3a45;
          }
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          color: #7f8b95;
        }

        &.react-calendar__tile--range {
          background-color: #dbf3f8;

          &.highlighted {
            background-color: #bbdb9b;
          }

          &.react-calendar__tile--rangeStart {
            background-color: #2dadc6;

            border-radius: 8px 0 0 8px;

            abbr {
              color: white;
            }
          }

          &.react-calendar__tile--rangeEnd {
            background-color: #2dadc6;

            border-radius: 0 8px 8px 0;

            abbr {
              color: white;
            }
          }
        }
      }
    }
  }
}
