.CreativeGrid {
  .Card {
    padding: 6px;
    aspect-ratio: 4/3;
  }

  .Container {
    .Label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      padding-left: 8px;
    }
  }
}
