.ChartLegend {
  display: inline-flex;

  &.Horizontal {
    flex-direction: row;
    flex-wrap: wrap;

    .Item {
      margin-right: 10px;
      margin-bottom: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.Vertical {
    flex-direction: column;

    .Item {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
