@import 'global-styles/fonts';
@import 'global-styles/colors';

.MenuItem {
  font-family: $brand-primary-font-family;
  font-size: 13px !important;
  font-weight: 300 !important;

  &:hover,
  &.Active {
    background-color: $color-primary-10 !important;
  }

  &.HasSubOptions {
    font-weight: bold !important;
  }

  .OptionTitle {
    display: flex;
    align-items: center;

    .LabelImage {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
  }

  .LogoContainer {
    height: 24px;
    width: 24px;

    .LabelImage {
      height: 100%;
      width: auto;
    }

    .DefaultLogo {
      height: 24px;
      width: 24px;
    }
  }
}

.SubMenuList {
  padding: 0 0 0 8px !important;
}
