@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.AdvertisingCreativeSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;

  .Title {
    @include H1GreyLeft;
    line-height: 40px;
    color: $font-color-light-bg;
    margin: 0;
  }

  .FilterSection {
    display: flex;
    align-items: center;
  }

  .Dropdown {
    width: 180px;
    margin: 0 16px;
  }

  .Icon {
    background-color: $color-secondary-10;
    border-radius: 8px;
    color: $color-secondary-90;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 250ms ease-in-out;
    margin: 0 8px;
    cursor: pointer;

    &.Active {
      background-color: $color-primary-20;
    }

    &:hover {
      background-color: $color-gray-900;
      color: white;
    }
  }

  // TODO: this will be used for download CSV future
  // TODO: functionality
  .DownloadIcon {
    border-radius: 8px;
    height: 32px;
    width: 32px;
    display: flex;
    cursor: pointer;
    margin: 0 8px;

    &:hover {
      background-color: $color-primary-10;
    }
  }

  .CompareButton {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-secondary-90;
    border: solid 1px $color-secondary-90;
    border-radius: 8px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    width: 105px;
    height: 32px;
    background-color: white;
    margin: 0 8px;

    &:hover {
      background-color: $color-primary-10;
    }
  }
}
