@import 'global-styles/colors';

.GridAccordion {
  .AccordionGroup {
    box-shadow: none;
    margin: 10px 0;
    border-radius: 8px;

    &::before {
      height: 0;
    }
  }

  .AccordionContent {
    height: 96px;
    margin: 10px 0;
    cursor: default;
  }

  .Expanded {
    background-color: $color-gray-10;
  }

  .Summary {
    height: 100%;
    width: 100%;
    padding: 0 20px;

    .SummaryName {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;

      font-weight: 400;
      font-family: 'Scansky';
      font-size: 18px;
      line-height: 21px;

      cursor: pointer;
    }
  }

  .MetricList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px;
    margin-top: 0;
    background-color: $color-gray-10;
  }
}
