@import 'global-styles/mixins/fonts';

.RadioSelectorItem {
  @include Default-Dark-Center;

  display: inline-flex;
  border-radius: 8px;
  padding: 8px 10px;

  cursor: pointer;

  &.Active {
    background-color: $color-primary-20;
  }
}
