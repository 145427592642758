.DatePickerCalendar {
  border-radius: 4px;
  border: solid 1px #e9edf0;

  &.Disabled {
    opacity: 60%;

    * {
      cursor: not-allowed !important;
    }
  }

  .NavigationCaret {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    height: 24px;
    width: 24px;
  }

  .Caret {
    &.Disabled {
      visibility: hidden;
    }
  }

  .react-calendar__navigation {
    padding: 8px;

    background-color: #f4f7fa;
  }
}
