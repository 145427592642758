.InviteSentModal {
  display: flex;
  flex-direction: column;
  width: 564px;
  height: 500px;
  font-family: Scansky;
  justify-content: space-between;
  overflow: hidden;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 56px;

    color: white;
    background-color: #2b3a45;

    .Icon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      padding: 0 16px;
    }

    .ShareIcon {
      height: 20px;
      width: 20px;
      margin: 12px;
    }

    .Left {
      display: flex;
      align-items: center;

      p {
        color: #fff;
        margin-left: 16px;
        font-size: 18px;
        line-height: normal;
      }

      .Icon {
        cursor: default;
      }
    }
  }

  .TextSection {
    display: flex;
    color: #2b3a45;
    width: 95%;
    flex-wrap: wrap;
    margin: 12px 8px 8px 12px;
    padding-bottom: 60px;

    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
  }

  .Footer {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;

    p {
      font-size: 12px;
    }

    .FooterText {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px 10px 12px;
    }

    .LinkButton {
      .ButtonGroup {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      .ButtonGroup:hover {
        cursor: pointer;
      }

      .CopyLink {
        color: #20434a;
        margin: 8px;
      }

      .CopiedButton {
        display: flex;
        width: 144px;
        height: 40px;
        flex-shrink: 0;
        align-items: center;

        background: #2b3a45;
        border-radius: 8px;

        .CheckInCircleIcon {
          height: 24px;
          width: 24px;
          color: #00823f;
          flex-shrink: 0;
          margin: 0 12px;
        }

        p {
          color: #fff;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
}
