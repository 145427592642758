@import "global-styles/colors";
@import "global-styles/mixins/fonts";
@import "global-styles/breakpoints";

.BrandTopicContainer {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  [class*="BrandLogo_BrandLogo"] {
    margin-bottom: 8px;
  }
  .KeywordColumn {
    position: relative;
    flex: none;
    padding: 16px;
    background-color: white;
    text-align: center;
    @media (min-width: $breakpoint-mobile) {
      width: 50%;
    }
    .Header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      @media (min-width: $breakpoint-mobile) {
        p {
          height: 4em;
        }
      }
    }
  }
  .TopicColumnSelectedBrand {
    position: relative;
    box-shadow: 1px 0px 9px $color-gray-20;
  }

  .BrandTopicContainerInner {
    display: flex;
    width: 100%;
  }
}

.TrendCard {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px 16px 24px 40px;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: white;

  z-index: 4;

  .TrendCardControls {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .Close {
    width: 32px;
    height: 32px;
    padding: 0 !important;
    svg {
      height: 100%;
      width: 100%;
      path + path {
        fill: $color-secondary-90;
      }
    }
  }
  .TrendCardColumn {
    padding: 0 10px;
    .ColumnTitle {
      text-transform: uppercase;
      @include LabelGreyCenter;
      margin-bottom: 16px;
    }
  }
  .TrendCardLogoContainer {
    margin-bottom: 20px;
  }
}
