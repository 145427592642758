@import 'global-styles/colors';

.ScoreTypeSwitch {
  display: flex;
  align-items: center;

  .SwitchContainer {
    transform: scale(0.8);
  }

  p {
    margin: 0 0 0 -4px;
    font-size: 13px;
    font-weight: 300;
    color: $font-color-light-bg;
  }
}
