@import 'global-styles/mixins/fonts';

.MetricListItem {
  @include MenuLink-40;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .IconContainer {
    display: flex;

    .Icon {
      cursor: pointer;
    }
  }

  .NameContainer {
    display: flex;
    align-items: center;

  }
}
