@import 'global-styles/variables.scss';
@import 'global-styles/colors';

.SiteNavigationContainer {
  .Content {
    padding: $top-nav-height 0 164px $left-nav-width;
  }

  .Footer {
    width: 100%;

    position: absolute;
    bottom: 0;

    padding-left: $left-nav-width;

    box-sizing: border-box;
  }
}
