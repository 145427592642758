@import 'global-styles/colors';

.SelectMetricGuide {
  height: calc(100vh - 300px);
  overflow-y: auto;

  .Title {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }

  .MetricTitle {
    color: $color-gray-base-60;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;

    .Required {
      color: $required-color;
    }
  }

  .MetricTag {
    display: flex;
    align-items: center;
    padding: 12px;

    border-radius: 100px;
    outline: none;
    border: none;
    background: $color-primary-20;

    color: $color-primary-80-link;
    font-size: 12px;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
    }

    svg {
      padding-left: 6px;
      width: 20px;
      height: 20px;
    }
  }

  .EditIcon {
    color: $icon-default;
  }
}
