@import 'global-styles/colors';

.ProfileOverview {
  .Header {
    margin-bottom: 20px;

    h3 {
      margin: 0;
    }
  }

  .Body {
    padding-left: 8px;
  }

  .Section {
    margin-bottom: 56px;
  }

  .ProfileSection {
    display: flex;
    flex-wrap: wrap;

    .SectionItem {
      margin-right: 24px;
    }

    .Input {
      max-width: 340px;

      margin-top: 6px;
    }

    .Button {
      margin-top: 21px;
    }
  }

  .SubscriptionsSection,
  .BrandsSection {
    .SectionHeader {
      box-shadow: inset 0 1px 0 0 $color-gray-20;

      padding: 12px 0;
    }
  }
}
