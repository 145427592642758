@import 'global-styles/mixins/fonts.scss';

.BrandSelector {
  display: flex;
  align-items: center;

  margin-left: 24px;
}

.MenuItem {
  @include DefaultDarkLeft;
}

.brandLogo {
  width: 24px;
  height: 24px;
  border-radius: 99px;
}

.selectorBase {
  @include DefaultGreyCenter;
  &:before {
    border: none !important;
  }
}

.listItemIcon {
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
}
