.ChangeIconLabel {
  display: flex;
  align-items: center;

  .Label {
    color: #7d7d7d;
    margin: 0;
    font-size: 22px;
    line-height: 20px;
    padding: 0 6px;
    white-space: nowrap;

    &.Up {
      color: #00823f;
    }

    &.Down {
      color: #820000;
    }
  }
}
