@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.CreativeScreenshotCardContent {
  width: 100%;
  // TODO: vars
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8.2811px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  .ImageContainer {
    // TODO: vars
    width: -webkit-fill-available;
    > img {
      border-radius: 8.2811px 8.2811px 0px 0px;
      width: 100%;
    }
    .EmptyState {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
    }
  }

  .ImgOnly {
    display: flex;
    justify-content: center;
    margin: 32px 24px;
    margin-top: 0;
  }

  .EmbeddedCompare {
    margin: 0;
    > img {
      border-radius: 0;
    }

    .EmptyState {
      height: -webkit-fill-available;
      border-radius: 0;
    }
  }

  .EmptyState {
    height: -webkit-fill-available;
    background: #E5E5E5;
    border-radius: 8.28438px 8.28438px 0px 0px;
    font-family: 'Scansky';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5688px;
    line-height: 19px;
    text-align: center;
    color: #2B3A45;
  }

  .Pointer {
    cursor: pointer;
  }

  .NoPointer {
    cursor: default;
  }

  // TODO: vars
  .Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12.4216px;
    gap: 32.09px;
    width: 100%;
    height: 93.16px;
    min-height: 93.16px;
    border: 1.03514px solid rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8.2811px 8.2811px;

    .DownloadIcon {
      border-radius: 8px;
      height: 32px;
      width: 32px;
      display: flex;
      cursor: pointer;
      margin: 0 8px;  
      &:hover {
        background-color: $color-primary-10;
      }
    }

    .Right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0px;
      gap: 10.36px;
    }

    .Left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 10px;

      .Logo {
        display: flex;
        width: 45.55px;
        height: 45.55px;
      }

      .Text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 2.07px;

        > p {
          margin: 0;
        }

        .ConfigUrl {
          max-width: 320px;
          > a {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Scansky';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #99A4AD;;
          }
        }
      }

    }

    .DatePill {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8.28438px 18.6398px;
      gap: 10.36px;
      background: #F1F6F7;
      border-radius: 24.1008px;
      font-family: 'Scansky';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #335E66;
    }
  }
}

.EmbeddedContent {
  background: transparent;
  padding-top: 24px;
  border-radius: 0;
}

.EmbeddedImgOnly {
  padding-top: 0;
}
