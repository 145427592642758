@import 'global-styles/colors';

.AuthSelector {
  .AuthButton {
    display: flex;
    align-items: center;

    .ProfilePicture {
      height: 32px;
      width: 32px;

      border-radius: 50%;

      margin-left: 8px;
      margin-right: 4px;
    }
  }
}
