@import 'global-styles/colors';

.BrandDatasetFilter {
  width: 100%;
  height: 100%;

  hr {
    margin: 12px 0;
    background-color: #ebeef0;
    border: 0;
    height: 1px;
  }

  .BrandItem {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .ScoreTile {
    flex-shrink: 0;
  }

  .CompetitorBrands {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
