@import 'global-styles/mixins/fonts';

.InsightsCTA {
  border-radius: 8px;

  .Header {
    display: flex;
    align-items: center;

    border-radius: 8px 8px 0px 0px;

    background-color: $button-tertiary-default;

    h5 {
      margin: 0;
    }
  }

  .Icon {
    width: 24px;
    height: 24px;
  }

  .InsightText {
    @include Intro;

    &:first-child {
      margin-top: 0;
    }
  }

  .Body {
    border-radius: 0px 0px 8px 8px;
    background-color: $color-secondary-10;
  }

  .ButtonWrap {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
