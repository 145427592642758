@import "global-styles/colors";
@import 'global-styles/breakpoints';

.ThemesSemiPieChart {
    
    [class*="rv-xy-plot__series--label-text"] { 
        font-size: 18px;
        color: $font-color-light-bg;
        @media (min-width: $breakpoint-md) {
            font-size: 15px;
        }
    }
}