@import 'global-styles/mixins/fonts';

$circle-spacing: 2px;

.ImpactRating {
    display: flex;
    align-items: center;

    .Impact {
        @include LabelDarkLeft;
        line-height: 0;
    }

    .None {
        @include SmallGreyLeft;
    }

    .Rating {
        display: flex;
        align-items: center;
    }

    .Circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #f4f7fa;
        margin-right: $circle-spacing;

        &:last-child {
            margin-right: 0;
        }

        &.Left {
            &.Filled {
                background-color: #d83133;
            }
        }

        &.Right {
            &.Filled {
                background-color: #20c660;
            }
        }
    }

    .Divider {
        height: 16px;
        width: 1px;
        background-color: black;
        margin-right: $circle-spacing;
    }
}

.ImpactRating.NotVisible{
    visibility: hidden;
}