@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ArchetypeCard {
  display: inline-flex;

  height: 335px;
  width: 257px;

  border-radius: 8px;

  .Content {
    position: relative;

    border-radius: 8px;

    width: 100%;
    height: 100%;

    &.Flipped {
      .Front {
        display: none;
      }

      .Back {
        display: block;
      }
    }
  }

  .Front,
  .Back {
    height: 100%;
    width: 100%;
  }

  .Front {
    border-radius: 8px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .Back {
    border-radius: 8px;

    background-color: white;
  }
}
