@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.PastReportsDropdown {
  position: relative;

  .IconContainer {
    height: 52px;
    width: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    .Icon {
      height: 24px;
      width: 24px;
      color: $color-gray-80;
    }
  }

  .Dropdown {
    position: absolute;
    top: 54px;
    right: -58px;

    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eef2f5;

    max-width: 352px;
    min-width: 320px;

    background-color: white;

    .Header {
      background-color: $color-gray-10;

      padding: 20px 16px;

      cursor: default !important;

      p {
        @include H5DarkCenter;

        font-weight: 500;

        text-align: left;

        margin: 0;
      }
    }

    .Body {
      background-color: white;

      height: 300px;
      overflow-y: scroll;

      border-radius: 0 0 8px 8px;
    }
  }
}
