@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ChartTypeSelector {
  display: flex;
  align-items: center;

  .Label {
    margin-right: 12px;
    @include DefaultDarkLeft---Italic;
  }

  .Icon {
    padding: 2px;
    border-radius: 8px;
    border: solid 1px transparent;
    margin-right: 8px;

    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;
    }

    &.Active,
    &:hover {
      border: solid 1px $color-gray-base-60;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
