@import 'global-styles/colors';

.PagerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: $color-gray-90;

  .Icon {
    color: $icon-default;
  }

  .Page {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px transparent;

    &:hover:not(.Disabled) {
      border: solid 1px $brand-color9;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }

    &.Disabled {
      cursor: not-allowed;

      button,
      svg {
        cursor: not-allowed;
      }
    }
  }

  .ActivePage {
    background-color: $brand-color9;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }

  .ShowLabel {
    color: #979797;
    margin: 0 8px;
  }
}
