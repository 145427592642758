.ChartLegendIcon {
  .Square {
    height: 12px;
    width: 12px;
    border-radius: 2px;
  }

  .DotLine {
    position: relative;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .Line {
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
    }

    .Oval {
        position: absolute;
        height: 6px;
        width: 6px;
        background-color: #D5E8EE;
        border: 2px solid;
        border-radius: 50%;
    }
  }

  .DashedLine {
    display: flex;
    align-items: center;

    .Line {
        height: 1px;
        width: 3px;
        margin-right: 1px;

        &:last-child {
          margin-right: 0;
        }
    }
  }

  .Area {
    height: 12px;
    width: 12px;
    border-radius: 1px;
    border: solid 1.5px;
  }

  .Bubble {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
}