@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.ThemesTopic {
  margin: 8px 0;
  padding: 8px 19px 8px 20px;
  border-radius: 4px;
  border: solid 1px $color-gray-20;
  width: 100%;

  box-sizing: border-box;

  p {
    @include DefaultDarkLeft;
    margin: 0;
  }

  text-align: center;

  &:hover {
    border-color: $inputActiveBorder;
  }
}

.ThemesTopicNew {
  margin: 8px 0;
  padding: 8px 19px 8px 20px;
  border-radius: 4px;
  border: solid 1px $color-gray-20;
  width: 100%;

  box-sizing: border-box;

  p {
    @include DefaultDarkLeft;
    margin: 0;
  }

  text-align: center;

  &:hover {
    border-color: $inputActiveBorder;
  }

  background-color: #c1ecf5;
  border-radius: 5px;
  border: solid 1px #e9edf0;
}

.ThemesTopicRemoved {
  margin: 8px 0;
  padding: 8px 19px 8px 20px;
  border-radius: 4px;
  border: solid 1px $color-gray-20;
  width: 100%;

  text-decoration: line-through;

  p {
    @include DefaultDarkLeft;
    margin: 0;
  }

  text-align: center;

  &:hover {
    border-color: $inputActiveBorder;
  }

  box-sizing: border-box;

  background-color: #e9edf0;
  border: solid 1px #e9edf0;
}
