@import 'global-styles/mixins/fonts';

.AddRecommendation {
  .Helper {
    @include SmallGreyLeft;
  }

  .Label {
    @include LabelGreyLeft;

    margin-bottom: 6px;
  }

  .KeepPrivateSwitch {
    margin-left: -13px;
  }

  .SwitchHelper {
    @include DefaultDarkLeft---Italic;
  }
}
