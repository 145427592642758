@import 'global-styles/colors';

.CreativeChannelsOverview {
  background-color: $color-gray-10;

  padding: 40px 60px;
  margin: 0 -16px;

  display: flex;
  flex-direction: column;
  gap: 30px;
}
