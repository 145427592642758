@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';
@import 'global-styles/colors';

$score-tile-width: 64px;

.SubfactorCard {
  border-radius: 8px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px rgba(0, 0, 0, 0.05) !important;
  position: relative;

  box-sizing: border-box;

  .CardLoader {
    position: absolute;
    left: 8px;
    bottom: 8px;

    circle {
      color: $brand-color11;
    }
  }

  .Header {
    margin-bottom: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .Title {
      @include H5DarkLeft;

      margin: 0;
    }
  }

  .AggregateContainer {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
    }
  }

  .Insight {
    @include DefaultDarkLeft---Italic;

    &:first-child {
      margin-top: 0;
    }
  }

  .GraphWrap {
    height: 94px;
    width: 100%;
  }

  .Icon {
    width: 24px;
    height: 24px;
  }

  .LeftJustfiedContainer {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .MetricLinkContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ExploreMetricLink {
    @include DefaultLinkLeft;
    cursor: pointer;
  }

  .ActionButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ChannelContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .SocialMetricChannel {
    .ChannelName {
      margin: 0 0 6px 0;

      font-size: 14px;
      font-weight: 300;
    }

    .ColLeft {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      .ScoreTile {
        flex-shrink: 0;
      }

      .ChannelGraphWrap {
        height: 94px;

        width: calc(100% - #{$score-tile-width});
      }
    }

    .ColRight {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      svg {
        cursor: initial;
        flex-shrink: 0;
      }

      .ChannelLogo {
        flex-shrink: 0;
      }

      .ChannelDescription {
        margin: 0;

        font-size: 13px;
        font-weight: 300;
      }
    }
  }
}
