.SettingsGlobalMarkerEvents {
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 45px;

    h3 {
      margin: 0;
    }
  }

  .Body {
    padding-left: 8px;
  }
}
