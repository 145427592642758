@import 'global-styles/colors';

.WidgetContextualHelpDrawer {
  .Content {
    width: 800px;

    .Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #2b3a45;
      padding: 12px 16px;

      .HeaderRight {
        display: flex;
        align-items: center;
        gap: 8px;

        .HeaderIconContainer {
          height: 32px;
          width: 32px;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: $color-gray-80;

          border-radius: 16px;

          svg {
            path {
              fill: white;
            }
          }
        }

        .HeaderText {
          color: white;
          font-size: 18px;
          line-height: 21px;
          font-weight: 300;
        }
      }

      .HeaderLeft {
        .CloseIcon {
          height: 24px;
          width: 24px;
          cursor: pointer;

          path:last-child {
            fill: white;
          }
        }
      }
    }

    .Body {
      padding: 16px 32px;
    }
  }
}
