@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.MarketIndexScorecard {
  .TitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0px !important;
  }

  .Paragraph {
    @include Intro;
  }

  .Subtitle {
    @include H2DarkLeft;
  }
}
