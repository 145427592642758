@import 'global-styles/colors';

$top-nav-height: 56px;
$left-nav-width: 59px;

.CreativeChannelsAdvertising {
  padding: 0 24px 60px 24px;

  .CompareOverlay {
    position: fixed;
    top: $top-nav-height;
    left: $left-nav-width;
    bottom: 0;
    right: 0;
    background: #56626b;
    z-index: 100;
  }

  .CompareContainer {
    height: calc(100% - 56px);
    margin-top: 56px;
    padding-top: 16px;
    .CreativeAssetPager {
      margin-top: 40px;
    }
  }

  .CreativeFooter {
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
  }
}
