@import 'global-styles/colors';

.Checkbox {
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }

  svg {
    fill: $color-secondary-80 !important;
  }
}