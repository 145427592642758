@import 'global-styles/mixins/fonts';

.DatePickerPresets {
  padding: 22px 0;
  max-width: fit-content;
  height: 100%;

  .Label {
    @include LabelGreyLeft;

    padding: 4px 12px;
  }

  hr {
    height: 1px;
    border: none;
    background-color: #ebe9e9;
  }
}
