@import 'global-styles/colors';

.DropdownSelect {
  display: flex;
  align-items: center;

  .Label {
    color: $icon-default;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-right: 12px;
  }

  .DropdownMenu {
    display: flex;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    border-radius: 8px;
  }
}
