@import 'global-styles/colors', '../sharedStyles';

.TextStepGuide {
  background: white;

  .Metric {
    @include pill;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }

    .Icon {
      height: 20px;
      width: 20px;
      margin-left: 4px;
    }
  }

  .StepText,
  .SubTitle {
    @include informationHelperText;
  }

  .Title {
    color: $color-gray-base-60;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }

  .SubTitle {
    color: $color-gray-40;
  }

  .Required {
    @include requiredAstrik;
  }
}
