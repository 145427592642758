@import 'global-styles/colors';

.Card {
  display: flex;
  width: 376px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 384px;

  border-radius: 8px;

  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.12);
}

.CardImage {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 160px;
  }
}

.CardContent {
  display: flex;
  width: 376px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;

  border-radius: 8px;

  .Title {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 12px;
  }

  .Description {
    padding-top: 8px;
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    height: 64px;
    overflow-y: auto;
  }
}

.CardButton {
  color: #fff;
  background-color: $color-secondary-80;
  border-radius: 8px;
  border: 1px solid $color-secondary-80;
  font-size: 13px;
  font-family: Scansky;
}
