@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.Table {
  .Header {
    .HeaderCell {
      @include SmallDarkLeft-Bold;

      border-bottom: 1px solid $color-gray-70 !important;

      padding: 8px 0 !important;
    }
  }

  .RowCell {
    @include DefaultDarkLeft---Italic;

    border-bottom: 1px solid transparent !important;

    padding: 8px 0 !important;

    vertical-align: middle;

    &.RowCellGroupEnd {
      border-bottom: 1px solid $color-gray-20 !important;
    }
  }

  .SubRow {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .TableRow {
    &:hover {
      background-color: $color-primary-10;

      .Actions {
        .Action {
          visibility: visible;
        }
      }
    }
  }

  .Actions {
    display: flex;

    .Action {
      visibility: hidden;

      display: inline-flex;
      align-items: center;

      margin-right: 8px;

      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      .Icon {
        height: 24px;
        width: 24px;

        margin-right: 4px;
        color: $brand-color11;
      }

      span {
        @include DefaultLinkLeft;
      }
    }
  }
}
