@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';
@import 'global-styles/mixins/buttons';
@import 'global-styles/breakpoints';

.ContentAnalysisTopics {
  .BrandTopicContainer {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 64px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 83%
      );

      z-index: 3;
    }
  }

  .BrandTopicContainerInner {
    // defining max-image for compatability
    mask-image: linear-gradient(black 20%, black 80%, transparent 100%);
    -webkit-mask-image: linear-gradient(
      to right,
      black 7%,
      black 93%,
      transparent 100%
    );

    display: flex;
    overflow-x: auto;
    width: auto;
  }

  .TopicColumn:last-of-type {
    margin-right: 20px;
  }

  .TopicColumn {
    margin: 0 5px;
    flex: none;
    padding: 8px;
    background-color: white;
    text-align: center;
    @media (min-width: $breakpoint-mobile) {
      width: 272px;
    }

    h6 {
      margin-top: 103px;
    }

    .Header {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 30px;
      [class*='BrandLogo_BrandLogo'] {
        margin-bottom: 8px;
      }

      .LogoContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .HistoryIconContainer {
        display: flex;
        justify-content: flex-end;
        button {
          background-color: transparent;
          border: none;
        }
      }

      .HistoryIcon {
        height: 32px;
        width: 32px;
        @include IconButton;
      }
    }
  }

  .TopicColumnSelectedBrand {
    position: relative;
  }

  .DateHeader {
    display: flex;
    margin-left: auto;
    float: right;
  }

  .CloseIcon {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  .HistoryViewNoTopics {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 600px;
  }

  .LegendContainer {
    margin-top: 40px;
  }

  .Legend {
    padding: 0 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: auto;
    position: absolute;
    bottom: 0;

    p {
      margin-left: 8px;
      font-size: 12px;
    }

    .LegendIcons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .NewTopicsIcon {
      padding: 10px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
      background-color: #c1ecf5;
    }

    .RemovedTopicsIcon {
      padding: 10px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
      background-color: #e9edf0;
    }
  }

  b {
    margin: 0 3px;
  }
}
