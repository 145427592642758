@import 'global-styles/_colors.scss';
@import 'global-styles/variables.scss';

.LeftNavItemSubNav {
  width: 0px;
  height: 100%;
  transition: width 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  color: $ui-table-col;
  position: absolute;
  left: $left-nav-width;
  top: 0;
  overflow: hidden;
  z-index: 2;
  li {
    margin-left: 2em;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

/* this class is for transitioning between open nav states. since there's a 0.8s close and an 0.8s open,
   there will be a gap in the animation due to "crossover" - so we negate that by delaying close of the bg
   on all subnavs when any other subnav is open */
.LeftNavItemSubNavTransitional {
  composes: LeftNavItemSubNav;
  transition: width 0s cubic-bezier(0.16, 1, 0.3, 1) 0.8s;
}
.groupHeaderLink {
  text-decoration: none;
}
.groupHeader {
  font-family: 'Scansky';
  font-weight: 200;
  font-size: 14px;
  padding: 0;
  margin-left: 1px;
  color: $ui-table-col;
  text-decoration: none;
}
.inner {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: $color-gray-90;
  padding: 16px 16px;
  width: 280px;
  > ul {
    line-height: 3.07em;
    list-style-type: none;
    padding: 0;
    margin-top: 16px;
    li {
      font-family: 'Scansky';
      font-weight: 200;
      font-size: 13px;
      padding: 0;
      margin-left: 1px;
    }
  }
  h5 {
    color: $ui-table-col;
    margin: 0;
    font-family: 'Scansky';
    font-weight: 400;
    font-size: 16px;
  }
}

.LeftNavItemSubNavOpen {
  composes: LeftNavItemSubNav;
  z-index: 99;
  width: 280px;
}

.LeftNavItemSubNavLocked {
  composes: LeftNavItemSubNavOpen;
  z-index: 98;
}

.LeftNavItemSubNavTransitional {
  composes: LeftNavItemSubNav;
  background: #f00;
  transition: width 0s cubic-bezier(0.16, 1, 0.3, 1) 0.8s;
}

.subNavNestedList {
  margin: -0.5em 0 0 39px;
  padding: 0;
  list-style-type: none;
}

.subNavList {
  position: relative;
  // left: -25px;
  // transition: left 0.9s cubic-bezier(0.16, 1, 0.3, 1);
}

.subNavListOpen {
  composes: subNavList;
  // left: 0px;
}

.subNavLink {
  display: block;
  transition: all 0.2s;
  outline: 0;
  &:hover,
  &:focus {
    font-size: 1.1em;
  }
  &:hover:focus {
    font-size: 1.1em;
  }
  &:focus {
    background: rgba(255, 255, 255, 0.1);
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
  }
}

.storyWrapper {
  width: $left-nav-width;
  background: $color-gray-90;
}
