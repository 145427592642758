@import 'global-styles/mixins/fonts';

.AccordionList {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include MenuLink-40;
    line-height: normal;
    margin-top: 12px;

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 0;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
