@import '../../WidgetSettingsShared/sharedStyles';
@import 'global-styles/colors';

.GenericSettingsFlow {
  padding: 0 3em;

  .CalculationsAndSourcesSettings {
    @include CalculationsAndSourcesSettings;
  }

  .ExampleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    margin: 0 140px;

    img {
      border-radius: 8px;
      width: 100%;
    }

    .HelperText {
      @include informationHelperText;
    }
  }

  .HelperContainer {
    @include informationCardContainer;

    margin: 32px 200px 0 200px;

    .Header {
      display: flex;
      align-items: center;
    }

    .Icon {
      margin-right: 8px;
    }

    .HeaderText {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .InfoContainer {
    padding-left: 32px;

    p:first-child {
      margin-top: 0;
    }

    p {
      font-size: 13px;
      font-weight: 400;

      margin: 8px 0;
    }

    strong {
      font-weight: 600;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0;

      li {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
