@import 'global-styles/colors';

$actions-width: 574px;

.DashboardListItem {
  color: $color-secondary-90;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-radius: 8px;
  background: white;
  width: $actions-width;

  .NameDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8px;
    gap: 8px;

    .Name {
      color: $color-secondary-90;
      font-weight: 400;
      font-size: 18px;
      display: flex;
    }

    .Description {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      opacity: 0.5;
      height: 48px;
      line-height: unset;
      overflow: auto;
    }

    .CreatorDetails {
      display: flex;

      .Owner,
      .LastViewed {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        margin: 0;
      }

      .LastViewed {
        color: #99a4ad;
      }
    }
  }

  .Actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;

    .TopRow {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .ActionItem {
      height: 30px;
      background-color: #e3eef024;
      border-radius: 8px;
      border: none;
      outline: none;
      color: $icon-default;
      transition: all ease-in-out 250ms;

      &:hover {
        color: #20434a;
        background-color: #dbf3f8;
        border-radius: 8px;
        height: 30px;
      }

      svg {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .ShareIcon svg {
      height: 16px;
      width: 16px;
    }

    .SharedAction {
      color: #20434a;
      background-color: #d1e2e6;
      height: 30px;
    }

    .ViewOnly {
      font-size: 12px;
      color: #436bee;
      text-wrap: nowrap;
      background-color: #f3f5fd;
      border-radius: 16px;
      padding: 2px 8px;
    }
  }
}
