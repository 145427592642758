@import 'global-styles/mixins/fonts';

.ScoreTile {
  @include H5DarkCenter;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 1px solid transparent;

  transition: all 0.2s ease-in-out;
  animation: score-tile-enter 1s ease-in;

  user-select: none;

  .Value {
    line-height: 1;
  }

  .Delta {
    @include TinyDarkRight;

    position: absolute;

    opacity: 0.6;
  }

  &.DisableAnimation {
    transition: none;
    animation: none;
  }

  &.FullWidth {
    width: 100% !important;
  }

  &.Small {
    width: 40px;
    height: 40px;

    font-size: 16px;

    &.MaxLength {
      font-size: 14px;
    }

    .Delta {
      top: 2px;
      right: 2px;

      font-size: 8px;
    }
  }

  &.Medium {
    height: 56px;
    width: 56px;

    font-size: 18px;

    &.MaxLength {
      font-size: 16px;
    }

    .Delta {
      top: 4px;
      right: 4px;

      font-size: 10px;
    }
  }

  &.Large {
    width: 72px;
    height: 72px;

    font-size: 21px;

    &.MaxLength {
      font-size: 18px;
    }

    .Delta {
      top: 6px;
      right: 6px;

      font-size: 12px;
    }
  }
}

@keyframes score-tile-enter {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
