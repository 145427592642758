.AddWidgetCard {
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  width: 100%;
  padding: 24px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  h2,
  p {
    margin: 0;
  }

  .ContentContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .Preview {
    width: 100%;
    height: 180px;

    border-radius: 8px;
    background-color: #e7e9ec;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .TitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
