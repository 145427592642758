@import 'global-styles/mixins/fonts';

.RecommendationsSection {
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    .Title {
      @include H5DarkLeft;
    }

    .LinkWrap {
      display: flex;
      align-items: center;

      .Icon {
        width: 24px;
        height: 24px;

        g {
          path:nth-child(2) {
            fill: black;
          }
        }
      }

      .Link {
        @include DefaultLinkLeft;

        cursor: pointer;
      }
    }
  }

  .Filters {
    display: flex;
    flex-wrap: wrap;

    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    .Filter {
      min-width: 240px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      .Label {
        @include LabelGreyLeft;
      }
    }
  }
}
