@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.RecommendationsList {
  width: 100%;

  .Recommendation {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    &:last-child {
      box-shadow: none;
    }
  }

  .NoRecommendations {
    @include DefaultDarkLeft---Italic;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0.8;

    border-radius: 8px;
    border: dashed 1px $color-gray-base-60;

    background-color: $color-gray-20;
  }
}
