.slide-enter {
  transform: translateX(-15px);
  opacity: 0;
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-15px);
}

.slide-enter,
.slide-enter-active,
.slide-exit,
.slide-exit-active {
  transition: all 150ms ease-in;
}
