@import 'global-styles/colors';
@import 'global-styles/fonts';

.AdvertisingTableContainer {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.TableHeader {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-left: 12px;
  padding-right: 12px;

  p {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }

  span {
    p {
      margin-right: 10px;
    }
    display: flex;
    flex-direction: row;
  }
}

.Heading {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.HeaderRow {
  font-size: 13px;
  line-height: 16px;
  color: $color-gray-900;
  border-bottom: 1px solid $shadow-gray;

  .ECPM {
    font-size: 18px;
  }
}

.PinIcon {
  margin-left: 10px;
  margin-right: auto;
  cursor: pointer;
}

.HeaderCell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;

  p {
    margin-left: 10px;
    margin-right: auto;
  }
}

.DownPercent {
  color: $color-red-500;
}

.UpPercent {
  color: $color-semantic-success-70;
}

.Label {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 6px;
}

.Row {
  height: 44px;

  p {
    color: $color-gray-700;
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    padding: 0 5px;
  }

  td {
    padding: 0 10px;

    text-align: right;
    justify-content: flex-end;
  }
}
