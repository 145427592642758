@import "global-styles/mixins/fonts";
@import 'global-styles/breakpoints';

.ThemesBrandChart {
    display: flex;
    align-items: center;
    padding: 0;
    @media (min-width: $breakpoint-md) {
        padding: 0 16px;
    }

    @media (max-width: $breakpoint-md) {
        [class*="BrandLogo_Name"] { 
            display: none;
        }
    }

    box-shadow: inset 0 -1px 0 0 $color-gray-20;
    &:first-of-type {
        border-bottom: 1px solid #6d777f;
        .ThemesCardItemLast {
            display: none !important;
        }
    }
    height: 80px;
    overflow: hidden;
    
    @media (min-width: $breakpoint-md) {
        height: 80px;
        overflow: hidden;
    }
    .ThemesCardItem {
        position: relative;
        height: 100%;
        flex-wrap: nowrap;

        align-items: center;
        justify-content: center;

        display: flex;
        width: calc(100% - 50px);
        @media (min-width: $breakpoint-md) {
            width: 50%;
        }
        .ThemesCardItemVolumeChart {
            width: calc(100% - 130px);
            height: 70%;
            margin-top: auto;
            @media (min-width: $breakpoint-md) {
                // width: 70%;
                height: 100%;
                margin-top: 0;
            }
        }
        .ThemesCardItemSemiPieChart {
            width: 120px;
            @media (min-width: $breakpoint-md) {
                width: auto;
            }
        }
    }
    &:hover {
        .ThemesCardItemLast {
            visibility: visible;
        }    
    }

    .ThemesCardHeaderItem {
        justify-content: flex-start;
        align-items: center;
        width: 50px;
        @media (min-width: $breakpoint-md) {
            width: 25%;
        }
    }
    .ThemesCardItemLast {
        display: none;
        @media (min-width: $breakpoint-md) {
            display: flex;
            visibility: hidden;
            margin-left: auto;
            width: 170px;
        }
    }
}