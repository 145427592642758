@import 'global-styles/mixins/fonts';

.MarketIndexSubfactorTrend {
  margin-bottom: 36px;

  .TileAndDateContainer {
    margin-bottom: 16px;
    justify-content: space-between;
  }

  .ScoreTileContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .ScoreRatingDisplayText {
      @include H4DarkLeft;
    }
  }

  .SectionDescription {
    margin-bottom: 40px;
  }

  .GraphContainer {
    padding-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    height: 200px;
  }

  .GraphLegend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .GraphSupport {
    padding-left: 48px;
  }
}
