.DateRangeSelectorDropdown {
  position: relative;

  .DropdownBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 12px 10px 8px;
    border-radius: 8px;

    max-width: fit-content;
    background-color: white;
    border: 1px solid #e9edf0;
    font-weight: 300;

    cursor: pointer;

    &.FullWidth {
      max-width: 100%;
      justify-content: space-between;
    }

    &.Open {
      background-color: white;
      border-color: #2eacc6;
    }

    .Label {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.Popover {
  border-radius: 12px !important;
}
