@import 'global-styles/mixins/fonts';

.PageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  .Title {
    display: flex;
    align-items: center;
  }

  .Star {
    margin-right: 4px;
  }

  .Actions {
    display: flex;
  }
}
