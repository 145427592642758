@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';
@import 'global-styles/mixins/layers';

.TopNavDropdownMenu {
  .Menu {
    @include LayerPopover;

    border-radius: 8px !important;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eef2f5;

    min-width: 240px;

    transform: translateY(12px) !important;
  }

  .Overlay {
    @include LayerOverlay;
  }

  .Icon {
    margin-right: 8px;

    height: 24px;
    width: 24px;

    border-radius: 50%;

    img,
    svg {
      height: 100%;
      width: 100%;

      border-radius: 50%;
    }
  }

  .MenuItem {
    padding-top: 12px;
    padding-bottom: 12px;

    width: 100%;

    @include MenuDefault-24;

    line-height: 1.75;

    &:hover {
      background-color: $color-primary-10;
    }

    .LabelContainer {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .Caret {
        height: 24px;
        width: 24px;

        transform: rotate(270deg);
      }
    }
  }

  .SelectedMenuItem {
    background-color: $color-primary-10 !important;
  }
}
