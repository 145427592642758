@import-normalize;
@import '../node_modules/react-vis/dist/style.css';
@import 'global-styles/mixins/fonts';
@import 'global-styles/fonts';
@import 'global-styles/colors';
@import 'global-styles/containers';
@import 'global-styles/animations';

body {
  margin: 0;
}

h1 {
  @include H1DarkLeft;
}

h2 {
  @include H2DarkLeft;
}

h3 {
  @include H3DarkLeft;
}

h4 {
  @include H4DarkLeft;
}

h5 {
  @include H5DarkLeft;
}

a {
  text-decoration: none;
}

p {
  @include Paragraph;
}

p + p {
  margin-top: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  font-family: $brand-primary-font-family;
}

.pac-container {
  z-index: 1000000 !important;
}

._pendo-badge,
._pendo-badge_ {
  z-index: 0 !important;
}

.add-to-export {
  display: none;
}

.screenshotting,
.screenshotting * {
  box-shadow: none !important;
  transform: scale(1) !important;
  opacity: 1 !important;

  ._pendo-badge,
  ._pendo-badge_ {
    visibility: hidden;
  }
}

.screenshotting-share {
  .hide-from-share {
    visibility: hidden;
  }

  .remove-from-share {
    display: none;
  }

  .add-to-share {
    display: block !important;
  }
}

.screenshotting-export {
  .hide-from-export {
    visibility: hidden;
  }

  .remove-from-export {
    display: none;
  }

  .add-to-export {
    display: block !important;
  }
}
