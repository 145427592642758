@import 'global-styles/mixins/fonts';

.DashboardPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #f5f9fa;

  .Header {
    color: white;
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(255.22deg, #1d627f -37.93%, #42c3cf 113.62%);
    border: solid 1px black;

    .HeaderText {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 2px;
      max-width: calc(100% - 350px);
      width: 100%;
      min-width: 250px;

      .ReturnToDashboardList {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: unset;
        border: unset;
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding-left: 10px;
        text-decoration-line: underline;
        cursor: pointer;

        .CaretIcon {
          width: 8px;
          height: 8px;
        }
        .ReturnToDashboardText {
          padding-left: 8px;
        }
      }

      .DashboardTitle {
        @include H1LightLeft;
        line-height: 40px;
      }
    }

    .Actions {
      display: flex;
      align-items: center;
      gap: 10px;
      color: white;
      min-width: 250px;

      .AddWidget {
        cursor: pointer;
        border: unset;
        display: flex;
        @include DefaultLightCenter;
        border-radius: 8px;
        gap: 8px;
        margin-right: 12px;
        padding: 8px 42px;
        background: rgba(227, 238, 240, 0.14);
        border: 1px solid rgba(227, 238, 240, 0.14);

        &:hover {
          border: 1px solid $color-primary-20;
        }
      }

      .Badge {
        font-size: 12px;
        color: #436bee;
        text-wrap: nowrap;
        background-color: #f3f5fd;
        border-radius: 16px;
        padding: 2px 8px;
      }

      .OwnerText {
        color: white;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }
  }

  .GlobalFiltersContainer {
    padding: 20px 20px 0 20px;
    background-color: #f5f9fa;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #e3eef0;
    padding: 12px;
    border-radius: 8px;

    margin: 20px 20px 0 20px;

    .GlobalFilters {
      display: flex;
      align-items: center;
      gap: 16px;

      .FilterHeader {
        font-size: 16px;
        color: #467078;
      }

      .DateRangeDropdownButton {
        background-color: #f5f9fa;
      }
    }

    .FilterActions {
      display: flex;
      align-items: center;
      gap: 8px;

      .FilterActionButton {
        font-family: $brand-primary-font-family;
        font-size: 14px;
        font-weight: 300;
        text-wrap: nowrap;

        background-color: #f5f9fa;
        border: unset;
        border-radius: 8px;
        padding: 6px 16px 6px 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;

        .ActionIcon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;

          path:last-child {
            fill: #7e9397;
          }
        }
      }
    }
  }

  .Loader {
    transform: scale(2);
    margin-top: 32px;
  }
}
