@import 'global-styles/colors';

.DeselectInput {
  display: flex;
  flex-wrap: wrap;

  border: solid 1px $input-disabled;
  border-radius: 8px;

  width: 100%;
}
