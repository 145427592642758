@import 'global-styles/colors';

.CreativeIntelligenceModal {
  display: flex;
  flex-direction: column;

  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;

  .Heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px 60px 20px;

    a {
      color: $color-primary-80-link;
      text-decoration: underline;
    }

    p {
      margin-top: 0;
      font-size: 21px;
      line-height: 40px;

      color: $color-chart-black;
    }
  }

  .MainBody {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 100px;

    .Text {
      width: 450px;

      margin: 0px 20px;

      .Heading {
        font-size: 24px;
        line-height: 28px;
        margin: 0;
      }

      .Body {
        font-size: 16px;
        line-height: 24px;
      }
    }

    button {
      width: 146px;
      height: 32px;
      color: $button-primary-hover;

      background: #5c838a;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
  }

  .VideoContainer {
    max-width: 485px;
    width: 100%;
    height: 295px;
    background: #ffffff;

    margin: 0px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .Video {
      margin-top: 10px;
      width: 100%;
      height: 100%;
    }
  }
}
