@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.MarketIndexOverview {
  .MarketIndexTrendContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .BlueScoreLegend {
    @media (min-width: $breakpoint-md) {
      display: none;
    }
  }
}
