@import 'global-styles/colors';

.ChartContainer {
  width: 100%;

  box-shadow: 0px 4px 14px $shadow-gray;
  border-radius: 8px;

  background-color: #ffffff;

  .Inner {
    margin-left: 12px;
  }

  .Heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Title {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: $color-chart-black;
  }

  .Totals {
    margin-right: 50px;
    display: flex;
    flex-direction: row;
  }

  .Total {
    margin-right: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    text-align: right;
    color: $color-chart-black;
  }

  .ChartSelector {
    margin-bottom: 20px;
  }
}
