@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.NavTabsVertical {
  background-color: $color-gray-10 !important;
}

.Indicator {
  right: auto !important;

  margin-top: 6px;

  width: 4px !important;
  height: 32px !important;

  background-color: $color-primary-base-60 !important;
}

.NavTab {
  max-width: 100% !important;
  height: 32px !important;

  justify-content: flex-start !important;

  padding-left: 16px !important;
}

.Label {
  @include MenuDefault-40;

  margin: 0;

  text-transform: capitalize;

  width: fit-content !important;
}
