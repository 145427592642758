@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.VerbatimCustomer {
  position: relative;
  display: grid;

  grid-template-columns: max-content 1fr;
  gap: 16px 8px;
  padding: 16px;

  border: solid 1px $color-gray-20;
  border-radius: 8px;

  & + .VerbatimCustomer {
    margin-top: 30px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 20px;
  }

  &:before {
    top: 100%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-top: 15px solid $color-gray-20;

    z-index: 3;
  }

  &:after {
    top: calc(100% - 1.5px);

    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-top: 15px solid white;
    z-index: 4;
  }

  .Image {
    .Positive {
      padding: 2px;
      border-radius: 50%;
      background-color: $color-semantic-success-10;
    }
    .Negative {
      padding: 2px;
      border-radius: 50%;
      background-color: $color-semantic-error-10;
    }
  }

  .Date {
    @include DefaultGreyLeft;
  }

  .Footer {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;

    display: flex;
    justify-content: flex-end;
  }

  .Tag {
    justify-self: end;
  }
}
