@import 'global-styles/mixins/fonts';
@import 'global-styles/mixins/layers';
@import 'global-styles/colors';

.TimeRangeSelector {
  @include LayerPopover;

  position: relative;

  .DropdownBtn {
    @include MenuDefault-24;

    text-transform: unset;

    &:hover {
      background-color: initial;
    }
  }

  .DatePickerMenu {
    position: absolute;

    background-color: white;

    transform-origin: 0 0;
    transform: translateY(12px);

    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eef2f5;
  }

  .Icon {
    margin-left: 8px;
  }
}
