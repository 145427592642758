@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.Dropdown {
  position: relative;
  width: auto;
  display: inline-flex;

  & + .Dropdown {
    margin-left: 20px;
  }

  &.FullWidth {
    width: 100%;

    .Menu {
      width: 100%;
    }
  }

  &.Naked {
    position: relative;

    .ToggleButton {
      border: none !important;
      @include H5DarkLeft;

      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .ToggleButton {
    @include H5DarkLeft;

    border: solid 1px $input-disabled !important;
    border-radius: 8px;

    width: 100%;

    text-transform: none;

    display: flex;
    justify-content: space-between;

    white-space: nowrap;
  }

  .LogoContainer {
    .LabelImage {
      height: 100%;
      width: auto;
    }

    .DefaultLogo {
      height: 24px;
      width: 24px;
    }
  }

  .XS {
    .LogoContainer {
      height: 24px;
      width: 24px;
    }
  }
  .SM {
    .LogoContainer {
      height: 32px;
      width: 32px;
    }
    span {
      font-size: 13px;
      font-weight: 300;
    }
  }

  .CaretIcon {
    transition: transform 150ms ease;
    width: 24px;
  }

  .OptionTitle {
    display: flex;
    align-items: center;

    .LabelImage {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
  }
}

.PopoverPaper {
  transform: translateY(44px) !important;
  min-width: 100px;
}
