@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.VerbatimTag {
  @include TinyDarkLeft-Bold;

  max-width: fit-content;

  text-transform: uppercase;

  border-radius: 4px;
  background-color: $color-gray-20;
  padding: 2px 7px;
}
