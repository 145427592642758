@import 'global-styles/fonts';
@import 'global-styles/colors';

.CreativeAssetModalContent {
  font-family: $brand-primary-font-family;

  padding: 30px 24px;

  .Col {
    padding-top: 0;
  }

  .LeftColContent {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    .AssetCardImg {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;

      border-radius: 8px;

      position: absolute;
    }

    video {
      height: 100%;
      max-width: 100%;
    }

    audio {
      max-height: 100%;
      width: 100%;
    }
  }

  .CreativeName {
    font-size: 24px;

    margin-bottom: 8px;
  }

  .DetailsList {
    display: flex;
    flex-direction: column;

    .DetailsListItem {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 8px 0;

      .Label {
        font-weight: 300;
        font-size: 16px;
      }

      .Value {
        font-weight: 600;
        font-size: 16px;
      }

      .TagList {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }

  .ChartContainer {
    width: 100%;

    display: flex;
    flex-direction: column;

    .NoBoxShadow {
      box-shadow: none;
    }

    .ChartLabel {
      display: flex;
      align-items: center;
      color: $color-gray-base-60;
      font-weight: 300;
    }

    .ColorSquare {
      height: 12px;
      width: 12px;
      background-color: $color-primary-70;
      margin-right: 6px;
      border-radius: 2px;
    }
  }
}
