.WidgetSubheader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .BrandTagDateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  .TimeRangeDropdown {
    display: flex;
    gap: 6px;
  }
}

.Wrapper{
  display: flex;
  align-items: center;
  gap: 12px;
}
