@import 'global-styles/colors';

@mixin FactorColors {
  .poor {
    background-color: $brand-color1;
    color: $font-color-light-bg !important;
  }

  .weak {
    background-color: $brand-color2;
    color: $font-color-light-bg !important;
  }

  .fair {
    background-color: $brand-color3;
    color: $font-color-light-bg !important;
  }

  .moderate {
    background-color: $brand-color4;
    color: $font-color-light-bg !important;
  }

  .good {
    background-color: $brand-color5;
    color: $font-color-dark-bg !important;
  }

  .great {
    background-color: $brand-color6;
    color: $font-color-dark-bg !important;
  }

  .best {
    background-color: $brand-color7;
    color: $font-color-dark-bg !important;
  }
  .below {
    background-color: #f6c9ce;
    color: #820000 !important;
  }
  .equal {
    background-color: #eaecf0;
    color: #303a42 !important;
  }
  .above {
    background-color: #ceeed0;
    color: #2d641d !important;
  }
}
