@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.RecommendationImpactResult {
  position: relative;

  height: 8px;
  width: 100%;

  border-radius: 8px;

  background: linear-gradient(
    90deg,
    rgba(233, 51, 156, 1) 0%,
    rgba(255, 204, 64, 1) 100%
  );

  z-index: 0;

  &.ActNow {
    .NegativeFill {
      width: 75%;
    }

    .Marker {
      left: 25%;
    }
  }

  &.ActSoon {
    .NegativeFill {
      width: 50%;
    }

    .Marker {
      left: 50%;
    }
  }

  &.ActLater {
    .NegativeFill {
      width: 25%;
    }

    .Marker {
      left: 75%;
    }
  }

  .NegativeFill {
    z-index: 1;

    position: absolute;
    right: 0;

    height: 100%;

    border-radius: 0 8px 8px 0;

    background: $color-gray-20;
  }

  .Marker {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    left: 0;

    margin-left: -23px;
    margin-top: 12px;

    .Icon {
      height: 9px;
      width: 9px;
    }

    span {
      @include SmallDarkLeft;
    }
  }
}
