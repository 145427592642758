@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.CheckboxList {
  .Header {
    .Title {
      @include DefaultDarkLeft-Bold;

      margin: 0;
    }
  }

  .Options {
    .OptionItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .Label {
        @include MenuDefault-32;
      }
    }
  }
}
