@import "global-styles/colors";
@import "global-styles/mixins/fonts";

.AlertIcon {
  position: relative;

  overflow: visible;

  cursor: pointer;

  .Icon {
    height: 24px;
    width: 24px;
  }

  .AlertCount {
    @include TinyLightCenter;

    position: absolute;
    top: -7px;
    left: -7px;

    padding: 1px 4px;

    border-radius: 4px;
    min-width: 14px;
    min-height: 14px;

    background-color: $color-semantic-warning-base-50;
  }
}
