@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

$top-nav-height: 56px;
$left-nav-width: 59px;

.CreativeChannelsScreenshots {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  .TitleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 42px;

    .ActionRow {
      display: flex;
      align-items: flex-end;
    }
  }

  .ScreenshotsOutlet {
    display: flex;
    margin-bottom: 65px;
    padding: 8px;
  }

  .CompareOverlay {
    position: fixed;
    top: $top-nav-height;
    left: $left-nav-width;
    bottom: 0;
    right: 0;
    background: #56626B;
    z-index: 100;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .Title {
    @include H1GreyLeft;
    line-height: 40px;
    color: $font-color-light-bg;
    margin: 0;
  }

  .CompareButton {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-secondary-90;
    border: solid 1px $color-secondary-90;
    border-radius: 8px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    width: 105px;
    height: 32px;
    background-color: white;
    margin: 0 8px;

    &:hover {
      background-color: $color-primary-10;
    }
  }

  .DownloadIcon {
    border-radius: 8px;
    height: 32px;
    width: 32px;
    display: flex;
    cursor: pointer;
    margin: 0 8px;

    &:hover {
      background-color: $color-primary-10;
    }
  }
}
