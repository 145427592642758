@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.ModalDrawer {
  height: 100%;

  .Header {
    background-color: $color-gray-90;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .Left {
      display: flex;
      align-items: center;
    }

    h1 {
      @include H4LightLeft;

      margin: 0;

      font-weight: 200;
    }

    .Icon {
      height: 24px;
      width: 24px;

      cursor: pointer;

      g {
        path:nth-child(2) {
          fill: $color-gray-40;
        }
      }
    }
  }

  .Body {
    height: 100%;
  }
}
