@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';
@import 'global-styles/colors';

.ThemesCard {
  padding: 16px;

  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);

  .CardTitle {
    cursor: pointer;

    color: $color-primary-80-link;
  }

  & + .ThemesCard {
    margin-top: 16px;
    @media (min-width: $breakpoint-md) {
      margin-top: 8px;
    }
  }
  @media (min-width: $breakpoint-md) {
    padding: 0 16px;
    height: 80px;
    overflow: hidden;
  }
  .ThemesCardItem {
    position: relative;
    height: 100%;
  }
  .ThemesCardItemLast {
    margin-left: auto;
  }
  .ThemesCardHeaderItem {
    .ThemesCardHeader {
      display: flex;
      align-items: center;
      .Icon {
        height: 24px;
        width: 24px;
      }
      svg {
        margin-right: 7px;
      }
      a {
        @include H5LinkLeft;
        font-weight: $normal;
      }
    }
  }
  .PieChartTitle {
    padding: 20px 10px 10px;
    text-transform: uppercase;
    width: 100%;
    @include TagDarkCenter;
    @media (min-width: $breakpoint-md) {
      display: none;
    }
  }
}
