@import 'global-styles/colors';

$score-column-width: 60px;
$row-height: 30px;
$title-column: 220px;
$other-brands-container-width: calc(#{$score-column-width} * 8);

.Container {
  width: 100%;
}

.CompetitorsContainer {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
}

.BodyContainer {
  display: grid;
  grid-template-columns: $title-column $score-column-width fit-content(
      $other-brands-container-width
    );
  padding-right: 11px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  .ColumnContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: $row-height;
  }

  .FirstColumn {
    color: $color-secondary-90;
    .TitleContainer {
      display: flex;
      gap: 3px;
      align-items: center;
      padding: 0 13px 0 3px;
      overflow: hidden;
    }

    .InfoIcon {
      opacity: 0.5;
      width: 12px;
      height: 12px;
      &:hover {
        cursor: default;
      }
    }

    p {
      font-weight: 400;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Factor {
      font-size: 18px;
    }
    .SubFactor {
      font-size: 15px;
    }
    .ScoreDrivers {
      font-size: 12px;
    }
  }

  .CompetitorsContainer {
    grid-template-columns: repeat(auto-fill, $score-column-width);
    grid-auto-columns: $score-column-width;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
}

.HeaderContainer {
  display: grid;
  grid-template-columns: $title-column $score-column-width fit-content(
      $other-brands-container-width
    );
  grid-template-rows: 65.8px;
  margin-bottom: 11px;

  &.HeaderWithScroll {
    grid-template-rows: 75px;
  }

  > :first-child {
    grid-column-start: 2;
  }

  .CompetitorsContainer {
    grid-template-columns: repeat(auto-fill, $score-column-width);
    grid-auto-columns: $score-column-width;
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2.397px;
  background-color: #7e9397;
}
::-webkit-scrollbar-track {
  border-radius: 2.397px;
  background-color: #7e93978c;
}
