@import 'global-styles/colors';

.CreativeAssetTypeTag {
  display: inline-flex;

  padding: 4px 7px 4px 4px;

  border-radius: 15px;

  background-color: white;

  .TagContent {
    display: flex;
    align-items: center;
    gap: 3px;

    .Icon {
      path {
        fill: $font-color-light-bg;
      }
    }

    .Description {
      font-size: 11px;
    }
  }
}
