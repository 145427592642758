@import 'global-styles/colors';

.PillContainer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  background: $color-gray-20;
  color: $color-gray-80;
  border-radius: 25px;

  &.DownTrend {
    background-color: $color-semantic-error-10;
    color: $color-semantic-error-70;
  }

  &.UpTrend {
    background-color: $color-semantic-success-10;
    color: $color-semantic-success-70;
  }

  .PillText {
    font-size: 16px;
  }
}

.PillText {
  font-size: 16px;

  margin: 0;
}

.PillContainerSmall {
  display: flex;
  flex-direction: row;
  align-items: center;

  .PercentSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 55px;
    height: 25px;
    border-radius: 25px;

    &.DownTrend {
      background-color: $color-semantic-error-10;
    }

    &.UpTrend {
      background-color: $color-semantic-success-10;
    }
  }

  .Percent {
    margin-left: -3px;
    font-size: 11px;
    font-weight: 400;
    line-height: 130%;
  }

  svg {
    margin-top: 3px;
    width: 20px;
    height: 20px;
  }

  .PillTextSmall {
    font-size: 11px;
    font-weight: 400;
    line-height: 130%;
    margin-left: 10px;
    color: $color-secondary-90;
  }

  &.DownTrend {
    color: $color-semantic-error-70;
  }

  &.UpTrend {
    color: $color-semantic-success-70;
  }
}

.PillContainerXL {
  display: flex;
  flex-direction: row;
  align-items: center;

  .PercentSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 38px;
    border-radius: 25px;

    &.DownTrend {
      background-color: $color-semantic-error-10;
    }

    &.UpTrend {
      background-color: $color-semantic-success-10;
    }
  }

  .Percent {
    margin-left: -3px;
    font-size: 17px;
    font-weight: 400;
    line-height: 130%;
    margin-right: 5px;
  }

  svg {
    margin-top: 3px;
    width: 25px;
    height: 25px;
  }

  .PillTextXL {
    font-size: 16px;
    margin-left: 10px;
    color: $color-secondary-90;
  }

  &.DownTrend {
    color: $color-semantic-error-70;
  }

  &.UpTrend {
    color: $color-semantic-success-70;
  }
}
