@import 'global-styles/colors';

.BrandCompareTooltip {
  background-color: white;
  border-radius: 8px;
  color: black;
  opacity: 1;
  pointer-events: none;
  position: absolute;

  transform: translate(-50%, 0);
  transition: all 0.1s ease;

  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(0, 0, 0, 0.05);

  width: fit-content;

  .TooltipContents {
    position: relative;

    padding: 12px;

    width: 100%;

    .TooltipArrowLeft {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-right: 10px solid white;

      position: absolute;
      left: -6px;
    }

    .TooltipArrowRight {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-left: 10px solid white;

      position: absolute;
      right: -6px;
    }

    .BrandList {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .Brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      font-size: 14px;

      .BrandLeft {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .BrandColor {
        height: 14px;
        width: 14px;
        min-width: 14px;

        background-color: red;
      }

      .BrandName {
        font-weight: lighter;

        white-space: nowrap;
      }

      .BrandValue {
        font-weight: bold;
      }
    }
  }
}
