@import '../../WidgetSettingsShared/sharedStyles';

.GroupMetricSettingsGuide {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .FieldGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .SubTitle {
    color: $color-gray-base-60;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    margin: 0;
  }

  .Dropdown {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    border-radius: 8px;

    box-shadow: 0 1px 2px 0 $shadow-input;
  }

  .Text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #1a8aa0; // This will be it's own component, but for now it's here
  }

  .Required {
    @include requiredAstrik;
  }

  .Field {
    @include formInput;
  }
}
