@import 'global-styles/colors.scss';

.TopNavRightSet {
  height: 100%;

  .Icon {
    height: 24px;
    width: 24px;
    color: $color-gray-80;
  }

  .TopNavRightSetList {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;

    list-style: none;

    margin: 0;
    padding: 0;

    .TopNavRightSetListItem {
      min-height: 52px;
      min-width: 56px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      border-bottom: solid 4px transparent;

      svg {
        display: block;

        width: 24px;
        height: 24px;
      }

      &.ReportsItem {
        cursor: default;
      }

      &:hover {
        border-bottom: solid 4px $color-primary-30;
      }

      &.Active {
        border-bottom: solid 4px $color-primary-50;
      }

      .ItemContent {
        margin-bottom: -4px;
      }
    }
  }
}
