@import 'global-styles/_colors.scss';
@import 'global-styles/variables.scss';
@import 'global-styles/mixins/layers.scss';

.LeftNav {
  @include LayerNavigation;

  position: fixed;
  left: 0;
  top: 0;

  background: $color-gray-90;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;

  width: $left-nav-width;

  transform: translate3d(0, 0, 0);
  > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
  }
}
