@import 'global-styles/colors';

.InsightsCardWrapper {
  display: flex;
  padding: 24px 0px;
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
}

.InsightsCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  p {
    color: $color-gray-90;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
  }
}

.InsightsCardTitle {
  color: $color-gray-90;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}
