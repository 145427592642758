.ExcessShareOfVoiceBarChart {
  padding-top: 30px;

  height: 100%;
  width: 100%;

  svg {
    height: 25px;
    width: 25px;
  }
}
