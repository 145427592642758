.CreativeScreenshotCardWrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.ScreenshotCardModal {
  position: absolute;
  height: fit-content;
  max-width: 50%;
}
