@import 'global-styles/colors';

.Tooltip {
  font-size: 11.5px !important;
  font-weight: 300 !important;

  background-color: $brand-color11 !important;
  border-radius: 8px !important;
  max-width: 160px !important;

  padding: 8px !important;
  margin-left: 8px !important;
}

.Arrow {
  color: $brand-color11 !important;
}
