.CreativeChannelsScreenshotsMultiBrand {
  display: flex;

  .Container {
    display: flex;
    margin: 0;
    row-gap: 12px;

    .BrandCard {
      min-height: 462.89px;
    }
  }
}
