@import 'global-styles/mixins/fonts';

.Tooltip {
  @include SmallLightLeft;

  background-color: #2b3a45;
  border-radius: 8px;
  max-width: 160px;
  padding: 8px;
}

.Arrow {
  color: #2b3a45;
}
