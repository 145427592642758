@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

$top-nav-height: 56px;
$header-height: 104px;
$footer-height: 164px;

.DashboardManagement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .DashboardHeader {
    color: white;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    align-self: stretch;
    background: linear-gradient(255.22deg, #1d627f -37.93%, #42c3cf 113.62%);
    border: solid 1px black;

    .HeaderTitle {
      @include H1LightLeft;
      flex-direction: column;
      display: flex;
      padding: 4px 6px 4px 12px;
      gap: 6px;
      border-radius: 8px;
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
    }

    .AddDashboard {
      cursor: pointer;
      border: unset;
      display: flex;
      @include DefaultLightCenter;
      font-weight: 300;
      border-radius: 8px;
      gap: 18px;
      padding: 8px 24px;
      background-color: rgba(227, 238, 240, 0.14);
    }

    .BreadcrumbContainer {
      height: 18px;
    }
  }

  .DashboardGridLayout {
    background: $dashboard-grid-background;
    width: 100%;
    padding: 48px 96px;
    min-height: calc(
      100vh - #{$top-nav-height} - #{$header-height} - #{$footer-height}
    );

    .DashboardGridTitle {
      @include H4DarkLeft;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 20px;

      .DashboardsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 12px 0;
        gap: 12px;
      }
    }
  }

  .ReturnToDashboardList {
    display: flex;
    align-items: center;
    background-color: unset;
    border: unset;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 10px;
    text-decoration-line: underline;
    cursor: pointer;

    .CaretIcon {
      width: 8px;
      height: 8px;
    }
    .ReturnToDashboardText {
      padding-left: 8px;
    }
  }

  .NoSharedDashboards {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;

    font-style: normal;
    font-size: 13px;
    line-height: 21px;

    .Title {
      font-weight: 600;
    }

    .SubTitle {
      font-weight: 300;
    }

    .InfoIcon {
      color: white;
      fill: #7e9af7;
      height: 25px;
      width: 25px;
      border-radius: 100%;
    }
  }
}
