@import 'global-styles/colors';
@import 'global-styles/fonts';

$button-width: 68px;
$button-height: 32px;
$step-header-height: 72px;

.WidgetSettingsModal {
  font-family: $brand-primary-font-family;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px;
    background-color: $brand-color11;

    .Title {
      color: white;
      font-size: 1.125rem;
      font-weight: 300;
    }

    .CloseIcon {
      color: $button-tertiary-default;
      height: 24px;
      width: 24px;
    }
  }

  .Body {
    display: flex;
    height: calc(100vh - 56px);
    overflow: auto;

    .FlowStagesContainer {
      width: 27.5rem;

      display: flex;
      flex-direction: column;

      .FlowStageContent {
        background: white;
        position: relative;

        .FlowAccordionSummary {
          background-color: #f4f7fa;
          border-top: 2px solid #d7dde2;
          height: $step-header-height;
          padding: 0 20px;

          .HeaderContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .FlowAccordionDetails {
          border-top: 2px solid #4ec3db;
          padding: 20px;
        }
      }
    }

    .FlowContentContainer {
      background-color: $button-tertiary-default;
      flex: 1;
      overflow: auto;
    }

    .FlowStagesFooter {
      height: 50px;
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0;

      background-color: white;

      .ActionButton {
        margin: 0 8px;
      }
    }
  }
}
