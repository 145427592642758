@import 'global-styles/_colors.scss';
@import 'global-styles/variables.scss';
@import 'global-styles/mixins/fonts.scss';
@import 'global-styles/mixins/layers.scss';

.CEMXSalute {
    @include LayerNavigation;

    background: #ff6122;
    padding:1em 0;
    display: flex;
    justify-content: center;
    div {
        background-color: #ffffff;
        border-radius: 20px;
        margin: auto 100px;
        display: flex;
        padding: .25em 30px;
        justify-content: center;

        p {
            line-height: 2em;
            margin:0 10px;
        }
    }
    a {
        margin:auto;    
    }
    svg {
        min-width: 32px;
        min-height: 32px;
        margin:auto;    
    }
}
