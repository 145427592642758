@import "global-styles/colors";
@import "global-styles/mixins/fonts";

.Recommendation {
  padding-right: 16px;
  padding-bottom: 30px;
  .Title {
    @include H5DarkLeft;
  }

  .HeaderActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .CompletedBtn {
    width: 174px;
    cursor: initial;
  }

  .Icon {
    height: 24px;
    width: 24px;
  }

  .Text {
    @include DefaultDarkLeft---Italic;
  }

  .Label {
    @include LabelGreyLeft;
  }

  .Tags {
    display: flex;
    flex-wrap: wrap;
  }

  .Date {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;

    .Label {
      // width: 100px;
      @include DefaultGreyRight-Bold;
    }

    .FormattedDate {
      @include DefaultGreyRight;
    }
  }
}

.EditDeleteDropdownPopover {
  .EditDeleteDropdownPopoverInner {
    display: flex;
    flex-direction: column;
    .DropdownAction {
      text-align: left !important;
      padding: 7px 65px 7px 7px !important;
      display: block !important;
    }
  }
}
