@import 'global-styles/colors';
@import 'global-styles/fonts';

.AssetTableContainer {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.CreativeName {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration-line: underline;
  color: $color-gray-700;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  padding: 0;
}

.CellCapitalize {
  text-transform: capitalize;
}

.CursorPointer {
  cursor: pointer;
}

.HeaderRow {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $color-gray-900;
  background-color: $creative-table-header;
}

.HeaderCell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 10px;
}

.DownPercent {
  color: $color-red-500;
}

.UpPercent {
  color: $color-semantic-success-70;
}

.RightAlign {
  text-align: right;
  justify-content: flex-end;
}

.Row {
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: $color-gray-700;
  height: 44px;
  background-color: $creative-table-row;
  border-top: solid 1px $creative-table-border;

  &:nth-of-type(even) {
    background-color: $creative-table-header;
  }

  td {
    padding: 0 10px;
  }

  td:not(.RightAlign) {
    justify-content: center;
    text-align: center;
  }
}
