.WidgetLibrary {
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;

  .Library {
    margin: 0;
    padding: 10px 20px;

    .Widget {
      display: flex;
    }
  }

  .WidgetCard {
    margin: 4px 8px;
  }
}
