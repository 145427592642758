@import 'global-styles/colors';
@import 'global-styles/fonts';

$select-button-height: 42px;

.Select {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: $brand-primary-font-family;

  height: $select-button-height;

  border: 2px solid $input-disabled;
  border-radius: 8px;
  box-shadow: 1px 2px 0 rgba(16, 24, 40, 0.05);

  .MenuLabel {
    display: flex;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;

    .SelectedLabel {
      font-weight: 300;
      font-size: 16px;
      margin: 0 16px 0 0;

      color: $color-gray-90;
    }

   .CaretIcon {
     color: $color-gray-90;
   }
  }

  &:hover {
    border: 2px solid $color-primary-20;
  }

  li {
    list-style: none;
  }
}

.DropdownMenuSelected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  p {
    margin: 0;
  }
}

.DropdownList {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-self: stretch;

  padding: 8px 4px;
  margin: 0;
  background-color: white;

  border: 1px solid $input-disabled;
  border-top: none;
  border-radius: 8px;
  list-style: none;
  box-shadow: 0 12px 12px 0 rgba(16, 24, 40, 0.05);

  .DropdownItem {
    font-family: $brand-primary-font-family;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: $select-button-height;
    min-width: 142px;
    background-color: white;
    color: $color-gray-90;

    padding: 0 16px;
    cursor: pointer;
    border: none;
    border-bottom: solid 1px $button-tertiary-default;
    outline: none;

    font-size: 16px;
    font-weight: 300;

    &:hover,
    &.DropdownItemActive {
      background-color: $color-primary-20;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}