@import 'global-styles/colors';

.Container {
  display: flex;
  margin-top: 2px;
  justify-content: space-between;
}

.ChartLegend {
  display: flex;
  height: 280px;
  flex-direction: column;
  justify-content: space-evenly;
}

.Row {
  width: 135px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SquareMarket {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: $color-secondary-30;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.SquareVoice {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: $chart-bar-ocean-02;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.ChartSection {
  width: 75%;
  display: flex;
  flex-direction: row;
}

.ChartSectionLarge {
  width: 80%;
  display: flex;
  flex-direction: row;
}

.Chart {
  width: 99%;
}

.LineChart {
  height: 96%;
}

.SOVTitle {
  color: $color-gray-90;
  margin: 2px 0;
}

.SOMTitle {
  color: $color-gray-90;
  text-align: center;
  font-size: 13px;
}

.TitleContainer {
  padding: 0;
  margin: 0;
}

.SOMSubtitle {
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
}

.SOVSubtitle {
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}

.SOVrow {
  display: flex;
  font-size: 13px;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  p {
    margin: 0;
  }
}

.EQrow {
  color: $color-gray-90;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    fill: $color-gray-50;
    height: 16px;
    width: 16px;
  }
}

.InsightsSection {
  padding-top: 6px;
  width: 25%;

  .MarketShare {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 24px;
    gap: 14px;

    width: 100%;

    background: $color-semantic-info-10;
    border-radius: 8px;

    margin: 0 0 10px 0;

    .Text {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 14px;
      margin: 0;

      color: $color-chart-black;
    }

    .TextSmall {
      font-size: 11px;
    }
  }

  .BlurbText {
    margin-top: 0;
    font-size: 14px;
  }

  .InsightInfoTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 4px 4px 6px 0px;
  }

  .InfoIcon {
    color: $color-gray-50;
    fill: white;
    height: 20px;
    width: 20px;

    border: 1px solid $color-gray-50;
    border-radius: 100%;
  }

  .MarketShareImpactTitle {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  svg {
    margin: 0 5px 0 5px;
  }
}

.InsightsSectionSmall {
  padding-top: 8px;
  width: 20%;

  .MarketShare {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 22px;
    gap: 14px;

    width: 100%;

    background: $color-semantic-info-10;
    border-radius: 8px;

    margin: 0 0 10px 0;

    .Text {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 12px;
      margin: 0;

      color: $color-chart-black;
    }

    .TextSmall {
      font-size: 11px;
    }
  }

  .BlurbText {
    margin-top: 0;
    font-size: 14px;
  }

  .InsightInfoTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 4px 4px 6px 0px;
  }

  .InfoIcon {
    color: $color-gray-50;
    fill: white;
    height: 20px;
    width: 20px;

    border: 1px solid $color-gray-50;
    border-radius: 100%;
  }

  .MarketShareImpactTitle {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  svg {
    margin: 0 5px 0 5px;
  }
}
