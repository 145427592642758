@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.ModalPaper {
  max-width: 616px;
  min-width: 300px;
  width: 100%;
  border-radius: 8px !important;
  
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: $color-gray-90;
    
    .Title {
      @include H4LightLeft;
      margin: 0;
    }

    .Icon {
      height: 24px;
      width: 24px;
      cursor: pointer;
      color: #fff;
    }
  }
  
  .ShutdownContent {
    display: flex;
    padding: 18px 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    font-family: Scansky;
    
    svg {
      margin-top: 5px;
    }

    .ColLeft {
      margin-right: 8px;
    }

    .ColRight {
      h5 {
        @include H5DarkLeft;

        margin-top: 4px;
        margin-bottom: 0;
      }

      p {
        @include DefaultDarkLeft---Italic;
        margin: 6px 0 6px 0;
      }
    }

    .Icon {
      height: 24px;
      width: 24px;
    }
  }

  .Footer {
    background-color: $color-secondary-10;
    padding: 16px;
    margin-top: 50px;
    
    button {
      float:right;
    }
  }
}
