@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.TextInput {
  // disable native html5 calendar for type=date
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  &.Error {
    .HelperText {
      @include LabelRedLeft;
    }
  }

  &.Disabled {
    .Input {
      background-color: $input-disabled;
    }
  }

  .Label {
    @include LabelGreyLeft;

    z-index: 10;
    transform: translate(0, -2px) scale(1);
    color: $color-gray-base-60 !important;

    span {
      color: $color-semantic-error-base-50 !important;
    }
  }

  .Input {
    @include DefaultGreyLeft---Italic;

    z-index: 0;
    width: 100%;
    border-radius: 8px;
    background-color: $ui-table-col;
    border: solid 1px $input-disabled;
    padding: 0 4px;
    transition: all 0.15s ease-in-out;
    color: $color-gray-80;

    min-height: 31px;

    input {
      padding: 0;
    }
  }

  .Icon {
    height: 24px;
    width: 24px;

    cursor: pointer;
  }

  .HelperText {
    @include SmallGreyLeft;

    margin-top: 4px;

    font-weight: 300;
  }

  .CharCount {
    @include SmallGreyLeft;
  }
}
