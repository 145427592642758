@import 'global-styles/mixins/fonts';

.TopNavDropdown {
  .DropdownBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    @include MenuDefault-24;

    text-transform: unset;

    &:hover {
      background-color: initial;
    }

    .StartIcon {
      margin-right: 8px;

      height: 24px;
      width: 24px;

      border-radius: 50%;

      img,
      svg {
        border-radius: 50%;

        height: 100%;
        width: 100%;
      }
    }

    .Icon {
      height: 24px;
      width: 24px;
    }

    .Label {
      margin-right: 4px;
      line-height: 1.75;
    }
  }
}
