.CreativeChannelsScreenshotsSingleBrand {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;

  .Container {
    display: flex;
    margin: 0;
    row-gap: 12px;

    .BrandCard {
      width: -webkit-fill-available;
      min-height: 462.89px;
    }
  }

  .EmbeddedContainer {
    margin-left: -4px;
    .BrandCard {
      padding-top: 0;
      width: 536px;
      height: 337px;
      min-height: 337px;
      aspect-ratio: 4/3;
      padding: 0;
      border-radius: 6.52px;
    }
    .CardBackground {
      background: #2B3A45;
    }
  }
}

.Pager {
  padding-top: 24px;
  padding-bottom: 16px;
}