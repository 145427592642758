@import 'global-styles/_colors.scss';

.CreativeChannelRowItemContainer {
  padding: 8px 4px;
}

.CreativeChannelRowItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 6px;
  margin: 0;
  box-shadow: inset 0px -1px 0px $color-gray-20;
  h3 {
    font-size: 26px;
  }
}

.Title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  font-size: 11.25px;
  line-height: 130%;
  color: $color-gray-400;
  text-transform: uppercase;
}

.Total {
  color: #111827;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

.Numbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  width: 40%;
}

.ChartContainer {
  width: 60%;
  height: 42px;
}
