@import 'global-styles/mixins/fonts';

.AccordionControl {

  .AccordionButton {
    @include H5DarkLeft;

    display: flex;
    min-width: fit-content;

    .CaretIcon {
      transition: transform 150ms ease;
    }
  }
}
