@import 'global-styles/mixins/fonts';

.ShareDropdownMenu {
  width: 328px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: white;
  z-index: 10;

  .Header {
    display: flex;
    justify-content: space-between;
    flex-basis: auto;

    .Title {
      @include H5DarkLeft;
    }

    .CloseIcon {
      cursor: pointer;
      height: 24px;
      width: 24px;
      color: $color-secondary-90;
    }
  }

  .LoaderContainer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ShareMenuItem {
    max-width: 50%;
  }

  .CopyBtn {
    @include SmallLightLeft;

    display: flex;
    align-items: center;

    border-radius: 8px;
    background-color: $color-gray-90;

    padding: 8px;

    .Icon {
      margin-right: 16px;

      g path:first-child {
        fill: $color-semantic-success-70;
      }

      g path:last-child {
        fill: white;
      }
    }
  }

  .Icon {
    height: 24px;
    width: 24px;
  }
}

.CopyTooltip {
  margin: 8px 0 !important;

  @include SmallDarkLeft;
  color: white;

  white-space: nowrap;

  padding: 8px 12px;

  cursor: pointer;
}
