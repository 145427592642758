@import 'global-styles/colors';

.DateLabel {
  border-radius: 7px;
  border: 1px solid $color-gray-20;
  background-color: $color-gray-10;

  display: flex;
  height: 34px;
  align-items: center;

  padding: 3px 6px;
  gap: 3px;

  p {
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
  }
}
