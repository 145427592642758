.CreativeChannels {
  width: 100%;
  max-width: 2000px;

  .Header {
    padding: 0 20px 20px 20px;

    h1 {
      margin-top: 0;
    }
  }
}
