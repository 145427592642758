@import 'global-styles/colors', '../../WidgetSettingsShared/sharedStyles';

.EditGroupMetricsGuide {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .Field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .GroupName {
    @include formInput;
  }

  .GroupNameSection {
    display: flex;
    flex-direction: column;
  }

  .Title,
  .SubTitle {
    color: $color-gray-base-60;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }

  .SubTitle {
    font-weight: 300;
    line-height: 20px;
  }

  .Required {
    @include requiredAstrik;
  }

  .Pill {
    @include pill;
    align-items: center;
    cursor: pointer;
    margin: 8px 0;

    p {
      margin: 0 8px;
    }

    .Icon {
      height: 18px;
      width: 18px;
    }
  }

  .MetricsSection {
    .MetricsList {
      min-height: 180px;
      max-height: 360px;
      overflow: auto;
    }
  }
}
