.PercentageDiff {
  width: 150px;

  .Container {
    display: flex;
    justify-content: flex-start;

    min-width: 100px;
    margin-left: 30px;
  }

  .Diff {
    .DiffValue {
      font-size: 18px;
      font-weight: 300;
    }
  }
}
