.DatePickerDualCalendar {
  display: flex;
  align-items: flex-start;

  .Section {
    margin-right: 16px;

    &:last-child {
      margin-right: 0px;
    }

    .DateInput {
      margin-bottom: 8px;
    }
  }
}
