@import "global-styles/colors";
@import "global-styles/breakpoints";
@import "global-styles/mixins/fonts";

.ThemesLegend {
  width: 100%;

  border-radius: 6px;

  background-color: $color-primary-10;
  padding: 6px 20px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
  .ThemesLegendItem {
    display: flex;
    align-items: center;
    font-family: $brand-primary-font-family;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.2em;
    color: $color-gray-90;
    width: 100%;
    svg {
      height: 20px;
      width: 20px;
    }
    span {
      margin-left: 12px;
    }
    @media (min-width: $breakpoint-md) {
      width: auto;
    }
    & + .ThemesLegendItem {
      @media (min-width: $breakpoint-md) {
        padding-left: 20px;
      }
    }
  }
}
