.GroupCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  padding: 24px;
  margin: 8px;
  cursor: pointer;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: white;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .Title {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .ItemsContainer {
    display: flex;
    gap: 8px;
  }

  .ItemsList {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ItemsTitle {
    margin: 0;
    font-size: 11px;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    color: #99a4ad;
  }

  .IconCircle {
    background-color: #5574db;
    border-radius: 100%;
    display: inline-flex;
    height: 20px;
    width: 20px;
    padding: 6px;
    margin-right: 8px;
  }

  .Icon {
    color: white;
  }

  .Description {
    font-size: 13px;
    font-weight: 300;
    line-height: 21px;
  }
}
