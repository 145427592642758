@import 'global-styles/colors';
@import 'global-styles/breakpoints';

.Footer {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden;

  @media (min-width: $breakpoint-sm) {
    overflow: unset;
    bottom: -267px;
  }

  a {
    color: inherit;
  }

  .FooterImage {
    width: 100%;
    @media (max-width: $breakpoint-sm) {
      width: 194%;
      position: relative;
      left: -3%;
      top: 3px;
    }
    // .fish-1
    [class*='fish-1'] {
      animation-name: fishAnimation;
      animation-iteration-count: infinite;
      animation-duration: 20.5s;
    }
    // .fish-2
    [class*='fish-2'] {
      animation-name: fishAnimation2;
      animation-iteration-count: infinite;
      animation-delay: 1s;
      animation-duration: 10.5s;
    }

    // .fish-3
    [class*='fish-3'] {
      animation-name: fishAnimation1;
      animation-iteration-count: infinite;
      animation-delay: 1s;
      animation-duration: 10.5s;
    }
    // .fish-4
    [class*='fish-4'] {
      animation-name: fishAnimation2;
      animation-iteration-count: infinite;
      animation-delay: 1s;
      animation-duration: 10.5s;
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 48px 15px;

    background-color: $brand-color10;
    color: white;

    font-size: 13px;
    line-height: 1.23em;

    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 25px;
      > span {
        padding-top: 10px;
      }
    }
    .navLeft {
      display: flex;
      align-items: center;

      a {
        display: block;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > a {
          text-align: center;
          width: 100%;
        }
      }
    }
    .logo {
      height: 28px;
      width: auto;
      @media (max-width: $breakpoint-sm) {
        margin-top: 10px;
      }
    }
    ul {
      list-style-type: none;
      @media (max-width: $breakpoint-sm) {
        text-align: center;
        padding-left: 0;
      }
      li {
        display: inline-block;
        & + li {
          margin-left: 15px;
          @media (max-width: $breakpoint-sm) {
            margin-left: 0;
          }
        }
        @media (max-width: $breakpoint-sm) {
          display: block;
          line-height: 2.5em;
        }
      }
    }
  }
}

@keyframes fishAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(8px, -3px, 0);
  }
  50% {
    transform: translate3d(15px, -5px, 0);
  }
  75% {
    transform: translate3d(8px, -3px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fishAnimation2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(-8px, -3px, 0);
  }
  50% {
    transform: translate3d(-15px, -15px, 0);
  }
  75% {
    transform: translate3d(-8px, -3px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
