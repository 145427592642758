@import 'global-styles/_colors.scss';
@import 'global-styles/variables.scss';
@import 'global-styles/mixins/fonts.scss';
@import 'global-styles/mixins/layers.scss';

.TopNav {
  @include LayerNavigation;


  background: $color-primary-10;

  width: 100%;
  height: $top-nav-height;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: calc(#{$left-nav-width} + 16px);

  .VerticalDivider {
    height: 24px;
    width: 1px;

    background-color: $icon-medium;
  }

  .Left {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    h5 {
      @include H5DarkLeft;

      margin: 0;
      margin-left: 9px;
      padding-right: 28px;
    }

    .HeaderMainLogo {
      cursor: pointer;

      display: flex;
      align-items: center;
    }
  }

  .Right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    .AuthSelector {
      margin-left: 12px;
    }
  }
}

.MainLogo {
  display: block;
  width: 112px;
  height: 28px;

  path:first-of-type {
    fill: $color-gray-90;
  }
}
