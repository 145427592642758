@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.BetaTag {
  height: max-content;

  padding: 4px 16px 6px 16px;

  border-radius: 12px;

  background-color: $button-tertiary-default;

  margin-left: 20px;

  span {
    @include SmallDarkCenter;

    line-height: 1;
  }
}
