@import "global-styles/mixins/fonts";

.DownloadMenuItem {
  @include SmallDarkLeft;
  display: flex;
  align-items: center;
  line-height: 1;
  border-radius: 8px;
  cursor: pointer;
  width: 144px;
  height: 40px;

  .Name {
    line-height: 1;
  }

  .Logo {
    height: 24px;
    width: 24px;
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
}
