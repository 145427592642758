@import 'global-styles/colors';

p {
  margin: 0;
}

.TitleContainer {
  display: flex;
  gap: 3px;
  align-items: center;

  .BrandLogo {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  .BrandName {
    color: $brand-color11;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
}

.ScoreContainer {
  display: flex;
  gap: 12px;
  justify-content: center;

  .Score {
    min-width: 120px;
    text-align: end;
    color: $color-chart-black;
    font-size: 48px;
    line-height: 72px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .ChangeDisplayIcon {
    margin-bottom: 6px;

    div,
    span {
      font-weight: 300;
      font-size: 28px;
      line-height: 28px;
      letter-spacing: -0.2px;
    }
    span {
      padding-left: 3px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .Smaller {
    .ChangeDisplayIcon {
      div,
      span {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .LabelDescription {
    margin-bottom: 3px;
    p:hover {
      color: $color-chart-black;
    }
  }
}
