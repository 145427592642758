@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  .DownloadDropdown {
    button {
      background-color: rgba(227, 238, 240, 0.14);
    }
  }

  .BrandName {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 0 12px;
  }

  .HeaderSection {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .HeaderText {
    font-size: 20px;
    margin: 0;
  }

  .LightText {
    font-weight: 200;
  }
}

.Header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(107deg, #1d627f 29%, #42c3cf 90%);
  opacity: 0;
  z-index: -1;
  transition: all 1s ease-in-out;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Header:hover::before {
  opacity: 1;
}

.Header:hover {
  transition: color 750ms;
  .BrandName,
  .HeaderText {
    color: white;
    transition: color 750ms;
  }

  .DownloadDropdown {
    button {
      background-color: $brand-color9;
    }
  }
}
