@import 'global-styles/colors';

.CreativeAdvertisingOverviewContainer {
  width: 100%;
  height: 100%;
  background-color: $color-gray-10;
  border-radius: 8px;

  padding: 24px 16px;

  .Heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-weight: 400;
    font-size: 24px;

    width: 100%;

    margin-bottom: 24px;

    .Title {
      color: $brand-color11;

      display: flex;
      flex-direction: row;
      font-size: 18px;
      align-items: center;

      .CaretIcon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .Link {
      color: $brand-color8;
      font-size: 18px;
    }

    .Breadcrumb {
      color: $brand-color8;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .CloseIcon {
      height: 32px;
      width: 32px;
      color: $color-secondary-90;
    }
  }

  .CloseIcon {
    :hover {
      cursor: pointer;
    }
  }
}

.TableCard {
  background-color: #ffffff;
  box-shadow: 0px 4px 14px $shadow-gray;
  border-radius: 8px;
}

.TableCardFooter {
  color: $color-gray-700;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  padding: 16px 33px;
  border-top: 1px solid $color-gray-20;
}

.CarouselContainer {
  display: flex;
  flex-wrap: wrap;
}
