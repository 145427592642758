@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.RecommendationsAccordion {
  .Accordion {
    box-shadow: none !important;
  }

  .Summary {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    .AccordionTitle {
      display: flex;
      align-items: center;

      h1 {
        @include H4DarkLeft;
      }

      .Icon {
        width: 24px;
        display: flex;
      }
    }
  }

  .AccordionContent {
    width: 100%;
  }
}
