@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.MarketIndexCompare {
  .CompareHeader {
    display: flex;
    align-items: center;

    .ComparisonLabel {
      @include DefaultDarkLeft---Italic;
    }

    .DropdownContainer {
      width: 100%;

      .Dropdown {
        min-width: 272px;
      }
    }
  }

  .CompareOptions {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    margin-top: -24px;
    margin-bottom: 24px;

    padding: 16px 0;
  }
}
