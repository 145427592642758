/* BRAND COLORS */

$brand-color1: #f6f8ec;
$brand-color2: #d6f0e1;
$brand-color3: #b4ebdc;
$brand-color4: #84d1d2;
$brand-color5: #3ab4cd;
$brand-color6: #257696;
$brand-color7: #093b61;
$brand-color8: #1a8aa0;
$brand-color9: #dbf3f8;
$brand-color10: #467078;
$brand-color11: #2b3a45;

/* FONT COLORS */

$font-color-disabled: #7f8b95;
$font-color-light-bg: #2b3a45;
$font-color-dark-bg: white;

/* NAMED COLOR IMPORTS FROM ZEPLIN */
$ice-blue: #eff5f9;
$pale-blue: #dee9ee;
$dark-grey-blue: #304b62;
$charcoal-grey: #2e3338;
$ice-blue-two: #eef3f6;
$white-50: rgba(255, 255, 255, 0.5);
$tealish: #24b4d2;
$aqua-marine: #3bd4ae;
$aqua-blue: #00d0e2;
$dodger-blue: #4c8df0;
$styleguide-fill: #d74fee;
$bluish-green: #13b58c;
$very-light-blue: #dbf8ff;
$buff: #fffb9d;
$pale-aqua: #b9ffee;
$ice: #e2f9f3;
$blue-with-a-hint-of-purple: #5c43bd;
$cerulean: #007dcd;
$peach: #ffbf7d;
$peach-two: #ffa67d;
$white-10: rgba(255, 255, 255, 0.1);
$color-primary-10: #eef9fb;
$chart-heatmap-divergent-sunburst-06: #51c3a9;
$chart-bar-sunset-08: #5a478b;
$chart-bar-ocean-08: #606d66;
$chart-bar-sunset-06: #cd4374;
$chart-bar-ocean-06: #5a5a83;
$color-semantic-alert-10: #fff9e2;
$color-semantic-warning-10: #fff2ec;
$chart-heatmap-index-02: #d6f0e1;
$color-semantic-alert-base-50: #f4cf3d;
$color-primary-70: #2dadc6;
$input-disabled: #d7dde2;
$color-semantic-warning-90: #7f3f21;
$color-semantic-success-30: #aeecc6;
$color-semantic-error-base-50: #d83133;
$chart-heatmap-index-03: #b4ebdc;
$chart-heatmap-divergent-04: #d55a81;
$chart-heatmap-divergent-sunburst-04: #ea9173;
$color-semantic-error-70: #a81618;
$color-semantic-info-30: #d8e0f9;
$color-semantic-warning-70: #bf5f32;
$ui-table-col: #ffffff;
$chart-bar-sunset-09: #c1b3e6;
$chart-bar-ocean-09: #a4b9ae;
$color-primary-20: #dbf3f8;
$chart-heatmap-index-01: #f6f8ec;
$color-semantic-error-90: #6a0102;
$color-chart-black: #303a42;
$chart-bar-sunset-07: #d990c8;
$chart-bar-ocean-07: #a7b9d8;
$chart-bar-sunset-01: #1d627f;
$chart-bar-ocean-01: #1097cf;
$chart-bar-sunset-02: #ffcf82;
$chart-bar-ocean-02: #1d566d;
$color-semantic-info-90: #3350b2;
$chart-bar-sunset-10: #54abbb;
$chart-bar-ocean-10: #836a98;
$chart-heatmap-divergent-sunburst-08: #1fb1ce;
$color-semantic-info-70: #5574db;
$color-gray-10: #f4f7fa;
$color-semantic-error-30: #f5b7b7;
$chart-bar-sunset-04: #9a4e89;
$chart-bar-ocean-04: #16988c;
$chart-heatmap-divergent-0: #f6f6f6;
$chart-heatmap-divergent-sunburst-0: #f8f0ba;
$color-semantic-info-10: #f3f5fd;
$chart-bar-sunset-03: #f4955e;
$chart-bar-ocean-03: #88ced8;
$color-primary-30: #c1ecf5;
$color-primary-80-link: #1a8aa0;
$color-semantic-alert-30: #fff0b3;
$color-gray-70: #6d7a84;
$color-gray-80: #49545c;
$color-gray-90: #2b3a45;
$color-gray-400: #9ca3af;
$color-gray-700: #374151;
$color-gray-900: #111827;
$color-semantic-info-base-50: #7e9af7;
$color-semantic-success-base-50: #20c660;
$color-primary-90: #0a5f70;
$color-semantic-warning-30: #ffbfa1;
$chart-heatmap-divergent-02: #f5c8ae;
$chart-heatmap-divergent-sunburst-02: #fadb9f;
$color-gray-base-60: #7f8b95;
$color-primary-50: #77d5e7;
$color-semantic-alert-70: #d0a600;
$color-gray-20: #e9edf0;
$color-semantic-success-70: #0a923f;
$chart-bar-sunset-05: #e38885;
$chart-bar-ocean-05: #a5d4b4;
$nav-active: rgba(255, 255, 255, 0.15);
$color-gray-40: #b3bdc4;
$color-gray-50: #99a4ad;
$button-tertiary-default: #e3eef0;
$color-semantic-alert-90: #907300;
$color-primary-40: #9be2f0;
$color-primary-base-60: #4ec3db;
$color-semantic-success-10: #e8fdf0;
$color-semantic-error-10: #fff2f2;
$color-black: #000000;
$color-red-500: #ef4444;
$ui-modal-overlay: rgba(10, 18, 45, 0.8);
$chart-heatmap-index-04: #84d1d2;
$chart-heatmap-index-04--opacity-20: rgba(132, 209, 210, 0.2);
$color-semantic-warning-base-50: #ff7f43;
$color-semantic-success-90: #005421;
$color-secondary-base-60: #5f8890;
$color-secondary-50: #81a5ac;
$color-secondary-30: #d1e2e6;
$color-secondary-10: #f1f6f7;
$color-secondary-80: #335e66;
$color-secondary-90: #20434a;
$color-secondary-70: #467078;
$color-secondary-40: #a6c4ca;
$icon-default: #7e9397;
$icon-medium: #ced9db;
$chart-heatmap-divergent-sunburst-07: #36babd;
$chart-heatmap-divergent-sunburst-05: #68c69a;
$chart-heatmap-divergent-03: #7cd695;
$chart-heatmap-divergent-sunburst-03: #9bd08e;
$chart-heatmap-divergent-01: #e0eeb9;
$chart-heatmap-divergent-sunburst-01: #d6e6a7;
$chart-bar-sunset-index: #2f95ae;
$chart-heatmap-index-05: #3ab4cd;
$chart-heatmap-index-07: #093b61;
$chart-heatmap-index-06: #257696;
$chart-heatmap-volume-04: #6f50ab;
$chart-heatmap-volume-04--opacity85: rgba(111, 80, 171, 0.2);
$chart-heatmap-volume-03: #4d8ce6;
$chart-heatmap-volume-03--opacity85: rgba(77, 140, 230, 0.2);
$chart-heatmap-volume-02: #6ccfe2;
$chart-heatmap-volume-02--opacity85: rgba(108, 207, 226, 0.2);
$chart-heatmap-volume-01: #74c4b1;
$chart-heatmap-volume-01--opacity85: rgba(116, 196, 177, 0.2);
$opacity-60: rgba(255, 255, 255, 0.6);
$opacity-80: rgba(255, 255, 255, 0.9);
$chart-platform-bar-facebook: #3a5a9a;
$chart-platform-bar-twitter: #44b5fa;
$chart-platform-bar-instagram: #f77737;
$chart-platform-bar-yelp: #ae1b1c;
$chart-platform-bar-snap: #f1e612;
$chart-platform-bar-tik-tok: #02f7ef;
$chart-platform-bar-google: #0f9d57;
$chart-platform-bar-linked-in: #0078b5;
$chart-platform-bar-you-tube: #ff0000;
$mask-no-trend: rgba(233, 237, 240, 0.9);

/* SHADOWS */
$shadow-hover-gray: rgba(127, 139, 149, 0.25);
$shadow-gray: rgba(0, 0, 0, 0.05);

/* BUTTONS */
$button-primary-hover: #5c838a;

$competitor-average-dash: rgb(116, 133, 144);
$dark-grey: rgb(109, 119, 127);

$inputActiveBorder: #76d2fd;
$creative-table-header: #f8fbfb;
$creative-table-row: #fcfdfd;
$creative-table-border: #f3f4f6;
$dashboard-grid-background: #F5F9FA;
$notification-message-background: #FEFEFD;

/* CUSTOMIZABLE DASHBOARDS */
$sand: #f4d1ae;
$settings-background: $button-tertiary-default;
$shadow-input: rgba(16, 24, 40, 0.05);
$required-color: #d83133;
