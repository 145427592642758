@import 'global-styles/colors';

$top-nav-height: 56px;
$left-nav-width: 59px;

.CompareContainer {
  width: -webkit-fill-available;
  height: calc(100% - 56px);
  margin-top: 56px;
  padding-top: 16px;
  .ScreenshotPager {
    margin-top: 40px;
  }
}

.CreativeChannelsScreenshotsCompare {
  display: flex;
  overflow-x: auto;

  .Add {
    position: absolute;
    right: 16px;
    cursor: pointer;
    background-color: $color-secondary-80;
    border: solid 1px $color-secondary-80;
    outline: none;
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 32px;
    width: 32px;

    svg {
      outline: none;
    }
  }

  .Panel {
    margin: 0 10px;
    width: 536px;
    height: fit-content;
    min-height: 462.89px;
    padding: 15px 20px;
    background-color: white;
    border-radius: 8px;

    &:last-of-type {
      margin-right: 60px;
    }

    .Header {
      display: flex;
      align-items: center;
      position: relative;
      height: 70px;
      margin-bottom: 12px;

      .Logo {
        margin-right: 16px;
      }

      .Icon {
        color: $color-gray-80;
        cursor: pointer;
        height: 24px;
        width: 24px;
        position: absolute;
        right: 20px;
        transform: scale(1);
      }
    }

    .PanelCards {
      margin-bottom: 8px;

      .Label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }

  .PanelCarousel {
    display: flex;
    position: relative;
    padding-left: 22px;
  }

  .CloseButton {
    cursor: pointer;
    border: transparent;
    background-color: transparent;
    color: white;
    height: 36px;
    width: 36px;
    z-index: 1000;
  }

  .Controls {
    background-color: $color-secondary-80;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    position: absolute;
    height: 56px;
    top: 0;
    right: 0;
    left: 0;

    .BarTitle {
      color: white;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }

    .ActionRow {
      display: flex;

      .Dropdown {
        padding: 0 8px;
        background-color: $color-secondary-80;
        color: white;
      }
    }
  }

  .Icon {
    color: white;
    transform: scale(1.5);
  }
}
