@import 'global-styles/mixins/fonts';

$max-chart-width: 2400px;

.MetricDefaultChartView {
  .PreviewOptions {
    width: 100%;

    .LabelWrap {
      margin-bottom: 6px;
    }

    .Label {
      @include LabelGreyLeft;
    }
  }

  .MetricDescription {
    p {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  .ChartOptions {
    width: 100%;
    max-width: $max-chart-width;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .BrandFilterContainer {
    margin-right: 40px;
    margin-top: 30px;

    .ScoreTypeSwitch {
      margin-left: -14px;
      margin-top: 8px;
    }
  }

  .MetricComparisonChartContainer {
    height: 320px;
    max-width: $max-chart-width;
    width: 100%;
  }

  .PieChartContainer {
    height: 100%;
    width: 320px;
  }

  .ChartLegendContainer {
    margin: 20px 0 0 40px;
    max-width: $max-chart-width;
  }

  .BrandComparePieChartContainer {
    height: 100px;
    width: 100px;
  }
}
