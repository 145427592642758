@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.Insight {
  .Header {
    background-color: $color-primary-20;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-radius: 8px 8px 0px 0px;

    .IconContainer,
    svg {
      height: 24px;
      width: 24px;
    }

    h5 {
      margin: 0;
    }
  }

  .Body {
    background-color: $color-primary-10;

    border-radius: 0px 0px 8px 8px;

    .CTA {
      box-shadow: inset 0 1px 0 0 $color-gray-20;

      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;

      box-sizing: border-box;

      text-align: center;

      .CtaButton {
        width: 100%;
      }
    }
  }
}
