.Container {
  max-width: 100%;

  .OptionsRow {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  .ContentContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    hr {
      margin: 12px 0;
      background-color: #c0bdbd;
      border: 0;
      height: 1px;
    }

    .HeatmapLegend {
      width: 408px;
      margin-right: 34px;
      align-self: flex-end;
    }

    .WatermapLegend {
      width: 598px;
      align-self: center;
    }
  }

  .Loading {
    width: 100%;
    height: 300px;
  }
}
