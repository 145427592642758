@import 'global-styles/colors';

.PaceWidgetTooltip {
  background-color: white;
  margin: -12px;
  padding: 12px;
}

.Label {
  display: flex;
  align-items: center;
  margin: 4px 2px;
  font-size: 10px;
  font-weight: 300;
  width: 185px;
}

.DateLabel {
  font-size: 10px;
  font-weight: 300;
  color: #303a42;

  margin-bottom: 12px;
}

.DatePrefix {
  width: auto;
  font-weight: 400;
}

.DescriptionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #303a42;
}

.Value {
  font-weight: 600;
}

.ColorSquare {
  height: 14px;
  width: 14px;
  min-width: 14px;
  border-radius: 3px;
  background-color: white;
  margin-right: 4px;
}
