@import 'global-styles/colors';

.CreativeOverviewAdCardContainer {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  background-color: white;

  .CardContent {
    padding: 0 10px 20px 10px;
  }

  .CreativeCard {
    padding: 6px;
    aspect-ratio: 4/3;
  }

  .CardSection {
    padding: 0 20px;
  }

  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .SectionHeader {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    color: $color-primary-90;
  }

  .SubHeader {
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    color: $color-gray-400;
  }

  .TopLists {
    margin-top: 16px;
  }
}
