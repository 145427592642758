@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.DateRangeSelector {
  display: flex;
  border: solid 1px #ebe9e9;
  border-radius: 12px;
  max-width: fit-content;

  .Header {
    font-size: 13px;
    font-family: $brand-primary-font-family;
    color: $color-gray-base-60;
  }

  .PresetOptions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;

    .PresetOption {
      padding: 4px 16px;
      border-radius: 8px;

      font-family: $brand-primary-font-family;
      font-weight: 300;
      size: 14px;
      color: $brand-color11;

      &:hover,
      &.Active {
        cursor: pointer;
        background-color: #e0f2f7;
      }
    }
  }

  .ColLeft,
  .ColRight {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ColLeft {
    padding: 20px 16px;
    border-right: solid 1px #ebe9e9;
  }

  .ColRight {
    padding: 20px 24px;

    .Footer {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
