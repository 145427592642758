@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.ContentAnalysisThemesOverview {
  padding: 0 20px;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .DropdownBreadcrumb {
    cursor: pointer;

    color: $color-primary-80-link;
  }

  .HeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .TitleContainer {
    display: flex;
    align-items: center;

    .TitleContainerTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ChangeThemeTitle {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;

      a {
        @include H5LinkLeft;
      }

      > span {
        margin-left: 5px;
      }
    }
  }
  .RadioContainer {
    padding-bottom: 25px;
    @media (min-width: $breakpoint-md) {
      padding-bottom: 10px;
    }
  }
  .Overview {
    .ChartHeader {
      display: none;
      @media (min-width: $breakpoint-md) {
        display: flex;
      }
    }
  }
  .BrandContent {
    @media (min-width: $breakpoint-md) {
      .ChartHeaderItem {
        &:nth-of-type(2) {
          width: 50%;
        }
      }
    }
    @media (max-width: $breakpoint-md) {
      .ChartHeaderItem {
        display: none;
        &:nth-of-type(2) {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .ChartHeader {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    .ChartHeaderItem {
      text-align: center;
      width: 25%;
      @media (min-width: $breakpoint-md) {
        padding: 0 12px;
      }
      .HeaderLabel {
        @include LabelDarkLeft;
        text-transform: uppercase;
        margin-bottom: 11px;
      }
      .HeaderText {
        @include DefaultDarkLeft;
      }
    }
  }
  .Title {
    @include H4DarkLeft;
    @media (min-width: $breakpoint-md) {
      @include H1DarkLeft;
    }
  }
  .BrandContentCompare {
    width: 100%;
    padding-top: 25px;
    box-shadow: inset 0 1px 0 0 $color-gray-20;

    .Title {
      @include H2DarkLeft;
    }

    p {
      margin-top: 0;
    }

    .ColumnHeader {
      padding: 16px 16px 16px 8px;
      width: 100%;
      &.ColumnHeaderWithDropdown {
        padding-left: 0;
      }
      @media (min-width: $breakpoint-md) {
        height: 80px;
      }
    }
  }
}

:global {
  [class*='ContentAnalysisThemesOverview'] {
    .carousel {
      position: relative;
      width: 100%;
      box-shadow: inset 0 -1px 0 0 $color-gray-20;
    }

    .slide {
      display: flex;
      width: 100%;
      min-width: 100%;
      margin: 0;
      position: relative;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    .control-dots {
      list-style: none;
      text-align: center;
      padding: 0;

      .dot {
        display: inline-block;
        background-color: #2aa7dd;
        width: 8px;

        height: 8px;

        border-radius: 50%;
        opacity: 0.45;
        margin: 0 2px;

        &.is-selected,
        &.selected {
          opacity: 1;
        }

        &:only-of-type {
          visibility: hidden;
        }
      }
    }

    .carousel .control-arrow,
    .carousel.carousel-slider .control-arrow {
      display: none;
    }

    .carousel-root {
      outline: none;
    }

    .carousel img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }

    .carousel .carousel {
      position: relative;
    }

    .carousel .control-arrow {
      outline: 0;
      border: 0;
      background: none;
      top: 50%;
      margin-top: -13px;
      font-size: 18px;
    }

    .carousel.carousel-slider {
      position: relative;
      margin: 0;
      overflow: hidden;
    }

    .carousel.carousel-slider .control-arrow {
      top: 0;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px;
    }

    .carousel.carousel-slider .control-arrow:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    .carousel .slider-wrapper {
      overflow: hidden;
      margin: auto;
      width: 100%;
      transition: height 0.15s ease-in;
    }

    .carousel .slider-wrapper.axis-horizontal .slider {
      display: flex;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide {
      flex-direction: column;
      flex-flow: column;
    }

    .carousel .slider-wrapper.axis-vertical {
      display: flex;
    }

    .carousel .slider-wrapper.axis-vertical .slider {
      -webkit-flex-direction: column;
      flex-direction: column;
    }

    .carousel .slider {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      width: 100%;
    }

    .carousel .slider.animated {
      transition: all 0.35s ease-in-out;
    }

    .carousel .slide {
      // text-align: center;
      //   background: #000;
    }

    .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0;
    }

    .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0;
    }

    .carousel .slide .legend {
      display: none;
    }

    // .carousel .control-dots {
    //     position: absolute;
    //     bottom: 0;
    //     margin: 10px 0;
    //     text-align: center;
    //     width: 100%;
    // }

    // @media (min-width: 960px) {
    //     .carousel .control-dots {
    //         bottom: 0;
    //     }
    // }

    // .carousel .control-dots .dot {
    //     transition: opacity 0.25s ease-in;
    //     opacity: 0.3;
    //     filter: alpha(opacity=30);
    //     box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    //     background: #fff;
    //     border-radius: 50%;
    //     width: 8px;
    //     height: 8px;
    //     cursor: pointer;
    //     display: inline-block;
    //     margin: 0 8px;
    // }

    // .carousel .control-dots .dot.selected,
    // .carousel .control-dots .dot:hover {
    //     opacity: 1;
    //     filter: alpha(opacity=100);
    // }

    .carousel .carousel-status {
      display: none;
    }

    .carousel:hover .slide .legend {
      opacity: 1;
    }
  }
}
