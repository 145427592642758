@import 'global-styles/colors';

.WidgetTooltipContainer {
  width: max-content;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  background-color: $color-gray-90;
  color: white;
  transition: all 0.1s ease;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  padding: 8px;
  border-radius: 4px;

  .TooltipContents {
    display: flex;
  }
}
