@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.DownloadDropdownMenu {
  width: 328px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: white;
  z-index: 10;

  .Header {
    display: flex;
    justify-content: space-between;
    flex-basis: auto;

    .Title {
      @include H5DarkLeft;
    }

    .CloseIcon {
      cursor: pointer;
      height: 24px;
      width: 24px;
      color: $color-secondary-90;
    }
  }
}
