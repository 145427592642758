@import '../sharedStyles.module.scss';

.SingleMetricNoData {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0;
  height: 100%;

  .MetricContainer {
    @include metricContainer;
  }

  .MetricSection {
    @include metricSection;
    align-items: center;

    .Label {
      @include label;
      text-transform: uppercase;
    }

    .IconContainer {
      display: flex;
      align-items: center;
      color: #7d7d7d;

      p {
        margin: 0;
        padding: 0 6px;
      }

      .Icon {
        height: 32px;
        width: 32px;
        background-color: #eeeeee;
        color: #7d7d7d;
        border-radius: 100%;
      }
    }
  }

  .BlueBorder,
  .YellowBorder {
    @include blueBorder;
  }

  .GraphSection {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #faf8f8;
    height: 100%;
    width: 100%;
    margin: 4px 0;

    .SubTitle {
      @include subTitle;
      font-size: 11px;
    }
  }

  .SubTitle {
    @include subTitle;
  }

  .SubTitleContainer {
    display: flex;
  }

  .SummarySection {
    display: flex;
    flex-direction: row;
  }

  .YellowBorder {
    @include yellowBorder;
  }
}
