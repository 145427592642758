@import 'global-styles/mixins/fonts';

.DatePickerPresetItem {
  @include DefaultDarkLeft;

  padding: 12px 18px;

  cursor: pointer;

  white-space: nowrap;

  &:hover {
    background-color: #eef9fb;
  }

  &.Active {
    background-color: #dbf3f8;
  }

  &.Disabled {
    background-color: white;
    color: #99A4AD;
    cursor: not-allowed;
    pointer-events: none;
  }
}
