@import 'global-styles/colors';
@import 'global-styles/fonts';
@import 'global-styles/mixins/factor-colors';

@include FactorColors;

.Cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $font-color-light-bg;
  box-sizing: border-box;
  &:hover {
    border: 2px solid $font-color-light-bg;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    cursor: default;
  }
}

.below {
  &:hover {
    border-color: $color-semantic-error-90;
  }
}
.equal {
  &:hover {
    border-color: $color-chart-black;
  }
}
.above {
  &:hover {
    border-color: $color-semantic-success-90;
  }
}
.good,
.great,
.best {
  &:hover {
    border-color: $font-color-dark-bg;
  }

  p {
    color: white;
  }
}

div.Tooltip {
  padding: 12px;
  background-color: #fff;
  opacity: 1;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  font-family: $brand-primary-font-family;
  border-radius: 8px;
  top: 12px;
  .TooltipArrow {
    color: #fff;
    opacity: 1;
  }
}

.TooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .LabelDescription {
    p {
      color: #a9aaaa;
    }
    span {
      display: inline-block;
      min-width: 36px;
      padding-right: 2px;
    }
  }
}
