@import 'global-styles/mixins/fonts';

.ModalConfirm {
  .Content {
    display: flex;

    padding: 18px 0px;

    min-height: 300px;

    .ColLeft {
      margin-right: 8px;
    }

    .ColRight {
      h5 {
        @include H5DarkLeft;

        margin-top: 4px;
        margin-bottom: 0;
      }

      p {
        @include DefaultDarkLeft---Italic;
        margin: 6px 0 6px 0;
      }
    }

    .Icon {
      height: 24px;
      width: 24px;
    }
  }
}
