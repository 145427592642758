@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.AlertListItem {
  list-style: none;

  padding: 8px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .ContentLeft {
    @include DefaultDarkLeft---Italic;

    display: flex;
    align-items: center;
    justify-content: center;

    .IconContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 32px;
      width: 32px;

      border-radius: 50%;

      background-color: $color-gray-50;

      margin-right: 12px;

      .Icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  .ContentRight {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .ReadIndicator {
      width: 8px;
      height: 8px;

      border-radius: 3px;

      background-color: $color-semantic-warning-base-50;

      margin: 8px;
    }
  }

  &.MetricUp {
    .ContentLeft {
      .IconContainer {
        background-color: $color-semantic-success-10;

        .Icon {
          g path:nth-child(2) {
            fill: $color-semantic-success-90;
          }
        }
      }
    }
  }

  &.MetricDown {
    .ContentLeft {
      .IconContainer {
        background-color: $color-semantic-error-10;

        .Icon {
          g path:nth-child(2) {
            fill: $color-semantic-error-90;
          }
        }
      }
    }
  }

  &.Info {
    .ContentLeft {
      .IconContainer {
        background-color: $color-primary-20;

        .Icon {
          g path:nth-child(2) {
            fill: $color-primary-90;
          }
        }
      }
    }
  }
}
