@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.CompareBrandInfo {
  .CompareCardsContainer,
  .CompareFactorsContainer {
    width: 100%;
  }

  .ScoreTileContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .ScoreRatingDisplayText {
      @include H3DarkLeft;
    }
  }

  .SubfactorLineList {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    .SubfactorLineListItem {
      border-bottom: 1px solid #e9edf0;

      .LineItem {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .CustomerBrandLogo {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .GraphInfo {
    padding: 0;

    @media (min-width: $breakpoint-sm) {
      padding: 0px 32px 0 40px;
    }
  }

  .GraphWrap {
    height: 200px;
    width: 100%;
  }

  .SubsectionTitle {
    @include H5DarkLeft;

    margin: 0 0 20px 0;
  }

  .OptionTitle {
    display: flex;
    align-items: center;

    .LabelImage {
      height: 32px;
      width: 32px;
    }
  }
}
