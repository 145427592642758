.RevokeAccessModal {
  position: relative;
  width: 564px;
  min-height: 400px;
  font-family: Scansky;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 56px;

    color: white;
    background-color: #2b3a45;

    .Icon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      padding: 0 16px;
    }

    .Left {
      display: flex;
      align-items: center;
      // gap: 12px;

      p {
        color: #fff;
        margin-left: 16px;
        font-size: 18px;
        line-height: normal;
      }

      .Icon {
        cursor: default;
      }
    }
  }

  .TextSection {
    margin-top: 12px;
    display: flex;

    .BoldText {
      margin: 12px 8px 8px 12px;
      color: #2b3a45;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .Text {
      margin: 12px 8px 8px 12px;
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
    }

    .AlertIcon {
      margin: 8px 0 8px 12px;
      height: 24px;
      width: 24px;
    }
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 64px;
    position: absolute;
    bottom: 0;

    background-color: #f1f6f7;

    Button {
      margin: 8px;
    }
  }
}
