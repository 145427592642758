@import 'global-styles/fonts';

$connector-color: #7f8b95;
$logo-dimension-small: 31px;
$logo-dimension-large: 57px;

.PinWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $logo-dimension-small;
  margin-left: calc(#{$logo-dimension-small}/ -2);

  &.PositionTop {
    flex-direction: column-reverse;

    .Logos {
      border-top: none;

      .FirstLastConnectorHide {
        margin-top: 0;
        margin-bottom: -1px;

        .Left,
        .Right {
          border-bottom: 1px solid white;
          border-top: none;
        }
      }
    }

    .Logo {
      flex-direction: column-reverse;
    }
  }

  &.SizeLarge {
    width: $logo-dimension-large;
    margin-left: calc(#{$logo-dimension-large}/ -2);

    .Logos {
      .FirstLastConnectorHide {
        .Left,
        .Right {
          width: calc((#{$logo-dimension-large} - 1px) / 2);
        }
      }
    }

    .Logo {
      .BrandLogo {
        height: $logo-dimension-large;
        width: $logo-dimension-large;
      }
    }
  }

  .Diamond {
    height: 7px;
    width: 7px;
    transform: rotate(45deg);
    border-radius: 0.5px;
    background-color: $connector-color;
  }

  .Connector {
    height: 16px;
    width: 1px;
    background-color: $connector-color;
  }

  .Logos {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    border-top: 1px solid $connector-color;
    position: relative;

    .FirstLastConnectorHide {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: -1px;
      z-index: 1;

      .Left,
      .Right {
        border-top: 1px solid white;
        width: calc((#{$logo-dimension-small} - 1px) / 2);

        height: 100%;
      }
    }
  }

  .Logo {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Connector {
      width: 1px;
      height: 16px;
      background-color: $connector-color;
    }

    .BrandLogo {
      height: $logo-dimension-small;
      width: $logo-dimension-small;
      z-index: 999;
    }

    .LogoImg {
      height: 100%;
      width: 100%;
    }

    .DefaultLogo {
      height: 100%;
      width: 100%;
      color: bisque;
    }
  }

  .Score {
    font-size: 17px;
  }
}

div.Tooltip {
  border-radius: 5px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  font-weight: 300;
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 24px;

  .TooltipArrow {
    color: #fff;
  }
}

.TooltipContainer {
  display: flex;
  gap: 5px;

  .BrandName {
    color: #303a42;
    font-size: 11.5px;
  }

  .IconLabel {
    font-size: 9px !important;
    padding-left: 3px !important;
  }
}
