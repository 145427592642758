@import 'global-styles/mixins/fonts';

.FactorCard {
  border-radius: 8px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px rgba(0, 0, 0, 0.05) !important;

  height: 100%;

  box-sizing: border-box;

  .Header {
    h4 {
      @include H4LinkLeft;

      margin: 0;

      cursor: pointer;
      font-weight: normal !important;
    }

    p {
      @include Intro;

      margin-top: 0;
    }
  }

  .Body {
    display: flex;

    .Right {
      width: 100%;
      height: 100%;

      .GraphWrap {
        height: 94px;
        width: 100%;
      }

      p {
        @include DefaultDarkLeft---Italic;
        margin: 0 0 0 8px;

        padding-right: 60px;
      }
    }
  }
}
