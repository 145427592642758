@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.Tag {
  display: inline-flex;
  align-items: center;

  background-color: $color-primary-20;

  border-radius: 6px;

  @include SmallDarkLeft;

  &.Deletable {
    cursor: pointer;

    .IconContainer {
      display: inline-flex;
      align-items: center;
    }

    .Icon {
      height: 16px;
      width: 16px;

      g path:nth-child(2) {
        fill: $color-gray-70;
      }
    }
  }
}
