@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.ContentAnalysisThemes {
  .TitleContainer {
    align-items: center;

    .Title {
      @include H1DarkLeft;
      margin: 0;
    }
  }

  .ChartContainer {
    padding: 10px 0;
    width: 100%;
    @media (min-width: $breakpoint-md) {
      padding: 0;
    }
  }

  .ChartRow {
    display: flex;
    align-items: flex-start;
    .ChartRowItem {
      @media (min-width: $breakpoint-md) {
        width: 25%;
      }
    }
  }

  .ChartHeader {
    display: none;
    align-items: flex-start;
    padding: 28px 16px 8px 16px;

    @media (min-width: $breakpoint-md) {
      display: flex;
    }
    .ChartHeaderItem {
      text-align: center;
      @media (min-width: $breakpoint-md) {
        padding: 0 10px;
        width: 25%;
      }
      .HeaderLabel {
        @include LabelDarkLeft;
        text-transform: uppercase;
        margin-bottom: 11px;
      }
      .HeaderText {
        @include DefaultDarkLeft;
      }
    }
  }

  .ChartBody {
    .ChartRowItem {
      display: flex;
      justify-content: center;
      [class*='rv-xy-plot__series--label-text'] {
        font-size: 17px;
      }
    }
  }

  .Paragraph {
    @include Intro;
  }

  .Subtitle {
    @include H2DarkLeft;
  }
}
