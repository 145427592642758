@import 'global-styles/colors';
@import 'global-styles/fonts';

$select-button-height: 42px;

.ActionsDropdown {
  position: relative;
  z-index: 7;
  font-family: $brand-primary-font-family;

  .Button {
    background-color: #428ea3 !important;
    cursor: pointer;
    border: 1px solid $input-disabled;

    & svg {
      color: white;
    }

    &:hover {
      border: 1px solid $color-primary-20;
    }

  }

  .DropdownMenu {
    position: absolute;
    top: -1px;
    right: -2px;

    display: flex;
    flex-direction: column;

    padding: 8px 4px;
    margin: 0;
    background-color: white;

    border: 1px solid $input-disabled;
    border-radius: 8px;
    list-style: none;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.25);
    width: max-content;
    max-width: 300px;

    .ItemContainer {
      font-family: $brand-primary-font-family;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: $select-button-height;
      background-color: white;

      cursor: pointer;
      border: none;
      border-bottom: 1px solid $button-tertiary-default;
      outline: none;

      &:hover,
      &.ActionItem {
        background-color: $color-primary-20;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .ActionItem {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0 16px;
        text-align: start;

        height: 100%;
        width: 100%;
        color: $color-gray-90;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
        font-style: normal;
      }
    }
  }
}
