@import 'global-styles/colors';
$widget-container-action-background-color: #e3eef024;
$widget-container-action-icon-color: #7e9397;

.WidgetContainer {
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .WidgetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    border-bottom: 1px solid $color-gray-20;
    padding-bottom: 12px;
  }

  .ActionsContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .DownloadDropdown {
      .DownloadDropdownButton {
        background-color: $widget-container-action-background-color;

        svg {
          g {
            path:last-child {
              fill: $widget-container-action-icon-color;
            }
          }
        }
      }
    }

    .ContextualHelpButton {
      background-color: $widget-container-action-background-color;
    }

    .ActionsDropdown {
      .ActionsDropdownButton {
        background-color: $widget-container-action-background-color !important;

        svg {
          path {
            color: $widget-container-action-icon-color;
            fill: $widget-container-action-icon-color;
          }
        }
      }
    }
  }

  .WidgetContent {
    width: 100%;
    height: 100%;

    overflow-y: auto;
  }

  .DragIcon {
    cursor: grab;
    color: $widget-container-action-icon-color;
    height: 20px;
    width: 20px;
  }
}
