@import 'global-styles/colors';
@import 'global-styles/fonts';

p + p {
  margin-top: 0 !important;
}

p {
  margin: 0;
}

colgroup {
  col {
    width: 18.5%;

    &:last-child,
    &:nth-last-child(2) {
      width: 16.5%;
    }

    &:first-child {
      width: 30%;
      min-width: 150px;
    }
  }
}

.TableBody {
  box-shadow: inset 0 0 0 1px #eaecf0;
  border-radius: 4px;

  .TableCell {
    border-bottom: 1px solid #eaecf0;
    height: 40px;
    padding: 16px 24px;
    font-family: $brand-primary-font-family;

    .LabelIcon {
      font-size: 18px;
      line-height: 18px;
      font-weight: 300;
      white-space: nowrap;
    }

    .Label {
      font-size: 28px;
      line-height: 30px;
      color: $color-secondary-90;
      text-transform: capitalize;
    }

    .IndexedOnlyLabel {
      font-size: 14px;
      line-height: 30px;
      color: $color-secondary-90;
    }

    .Name {
      max-width: 298px;
      min-width: 110px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #101828;
    }

    .Description {
      max-width: 298px;
      min-width: 110px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      color: $color-gray-50;
      white-space: nowrap;
    }
  }

  .TableRow:last-child .TableCell {
    border-bottom: none;
  }
}

.TableHeader {
  p {
    font-size: 12px;
    white-space: pre;
  }

  .TableCell {
    max-height: 36px;
    padding: 6px 24px;
    font-family: $brand-primary-font-family;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $color-gray-70;
    border-bottom: none;
    vertical-align: middle;

    &:first-child {
      font-weight: 700;
      padding: 12px 24px 12px 0;
      color: #475467;
    }
  }
}
