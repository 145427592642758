@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.AlertList {
  min-width: 350px;
  width: 100%;

  .Header {
    box-shadow: inset 0 -1px 0 0 $color-gray-20;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 16px;

    background-color: $color-gray-10;

    h5 {
      @include H5DarkLeft;

      margin: 0;
    }
  }

  .Body {
    max-height: 450px;
    overflow-y: scroll;

    .Alerts {
      list-style: none;
      margin: 8px 0;
      padding: 0 16px;

      .AlertItem {
        box-shadow: inset 0 -1px 0 0 $color-gray-20;

        &:last-child {
          box-shadow: none;
        }
      }
    }
  }
}
