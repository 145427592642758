@import 'global-styles/mixins/fonts';

.SettingsMarkerEventForm {
  padding: 28px 16px;

  .PrivateHeader {
    @include Intro;

    margin-top: 16px;
  }

  .PrivateSwitch {
    display: flex;
    align-items: center;

    margin-left: -10px;
  }

  .SwitchHelper {
    @include DefaultDarkLeft---Italic;
  }
}
