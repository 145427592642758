@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.Avatar {
  .Label {
    margin-bottom: 6px;

    span {
      @include LabelGreyLeft;
    }
  }

  .AvatarThumbnail {
    height: 80px;
    width: 80px;

    border-radius: 50%;

    background-color: #777676;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: white;

      font-size: 32px;
    }
  }
}
