.PaceAnalysisWidget {
  max-width: 100%;

  .ContentContainer {
    margin-top: 12px;

    .PaceContent {
      display: flex;
      gap: 42px;

      .PaceGraph {
        width: 100%;
      }
    }

    .InsightsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;

      max-width: 262px;
    }
  }

  .OptionsRow {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}
