@import 'global-styles/colors';
@import '../../WidgetSettingsShared/sharedStyles';

.VisualMapsSettingsGuide {
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // TODO: remove this for a longer term fix to ENG-1340
  height: calc(100vh - 232px);
  overflow-y: auto;
  padding-bottom: 56px;

  .Required {
    @include requiredAstrik;
  }

  .Dropdown {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px $shadow-input;
  }

  .Field {
    @include formInput;
  }

  .Label {
    @include label;
  }

  .Group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
