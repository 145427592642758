@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.Label {
  display: flex;
  align-items: center;
  cursor: pointer;

  .Title {
    @include TinyDarkCenter;

    @media (min-width: $breakpoint-sm) {
      @include LabelDarkCenter;
    }
  }

  .Icon {
    width: 24px;
  }

  .SortIcon {
    display: none;
    margin: 0;

    @media (min-width: $breakpoint-sm) {
      margin-left: -4px;
      margin-bottom: -6px;
      display: block;
    }
  }
}
