@mixin LayerPage {
  z-index: 100;
}

@mixin LayerNavigation {
  z-index: 1000;
}

@mixin LayerOverlay {
  z-index: 10000;
}

@mixin LayerPopover {
  z-index: 100000;
}

@mixin LayerModal {
  z-index: 1000000;
}

@mixin LayerAlert {
  z-index: 10000000;
}
