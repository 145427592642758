.DatePickerDualCalendarWithPresets {
  display: flex;
  align-items: stretch;

  .ColLeft {
    .Presets {
      border-right: solid 1px #ebe9e9;
    }
  }

  .ColRight {
    padding: 16px;

    .Alert {
      margin-bottom: 10px;
    }

    .ActionContainer {
      display: flex;
      justify-content: flex-end;

      margin-top: 16px;
    }
  }
}
