@import 'global-styles/colors';

@mixin blueBorder {
  border-left: solid 7px $color-primary-70;
  border-radius: 2px;
  flex-direction: column;
  margin-top: 8px;
}

@mixin label {
  font-size: 48px;
  text-transform: lowercase;
  line-height: 44px;
  color: $color-chart-black;
  padding-right: 12px;
  margin: 0;
}

@mixin metricContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 8px;
}

@mixin metricSection {
  display: flex;
}

@mixin subTitle {
  display: flex;
  align-items: center;

  margin: 0;
  padding-left: 8px;

  color: $color-gray-70;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
}

@mixin yellowBorder {
  border-color: $sand;
}
