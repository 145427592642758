@import 'global-styles/mixins/fonts';

.MarketIndexFactors {
  .Header {
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    h5 {
      display: flex;
      align-items: center;

      @include H5DarkLeft;

      margin: 0;

      &.Link {
        @include H5LinkLeft;

        height: 27.78px;

        cursor: pointer;
      }
    }

    .Star {
      margin-right: 4px;
    }

    .BreadCrumbItem {
      margin-right: 4px;

      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
