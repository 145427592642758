@import "global-styles/mixins/fonts";

.SubfactorLineItem {
  overflow: hidden;

  .SubfactorLabel {
    text-transform: capitalize;

    @include H5DarkLeft;

    &.Highlight {
      @include H5LinkLeft;
    }

    &.Clickable {
      cursor: pointer;
    }
  }

  .ScoreSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ScoreWrap {
      width: initial;
    }
  }

  .GraphContainer {
    height: 56px;
    width: 100%;
    overflow: hidden;
  }
}
