@import 'global-styles/colors';

.MetricsOverview {
  .MetricOptionsDropdown {
    border: 0;
  }

  .MetricsListSection {
    box-shadow: inset 0 1px 0 0 $color-gray-20;
  }
}