@import 'global-styles/colors';

.SubfactorBarChartTooltip {
  background-color: white;
  border-radius: 6px;
  color: black;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(0, 0, 0, 0.05);

  .TooltipContents {
    position: relative;

    min-width: 140px;

    padding: 12px;

    .TooltipArrowLeft {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-right: 10px solid white;

      position: absolute;
      left: -6px;
    }

    .Delta {
      font-weight: bolder;
      font-size: 24px;

      margin-bottom: 8px;
    }

    .ScoreHistoryList {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .ScoreHistoryItem {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .HistoryItemDate {
          font-size: 13px;

          color: $color-gray-50;
        }

        .HistoryItemScore {
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
  }
}
