@import 'global-styles/colors';
@import 'global-styles/fonts';

.FunctionalAreasHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .Label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .Header {
    font-size: 18px;
    line-height: 16px;
    font-weight: 400;
  }

  .MenuItem {
    font-family: $brand-primary-font-family;
    font-size: 16px;
    font-weight: 300;
    color: $color-gray-80;

    &:hover {
      background-color: $ui-table-col;
    }
  }

  .Selected {
    background-color: $ui-table-col;
  }

  .DropdownBtn {
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: $brand-primary-font-family;
    background-color: transparent;
    border: none;
    font-size: 18px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;

    padding: 0;
    margin-bottom: 24px;
  }

  .RightSection {
    display: flex;

    .SubTitle {
      color: $color-gray-90;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin: 0 8px;
      display: flex;
      align-items: center;
    }

    .ViewButton {
      border-radius: 8px;
      background-color: $settings-background;
      transition: all 250ms ease-in-out;
      font-size: 13px;
      line-height: 16px;
      font-weight: 300;
      padding: 0 12px;
      margin: 0 4px;
      height: 32px;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        background-color: #c8d8dc;
        color: $color-gray-90;
      }
    }

    .Active {
      background-color: $color-secondary-80;
      color: white;
    }

    .Disabled,
    .Disabled:hover {
      background-color: $input-disabled;
      color: #7e8b95;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
