@import 'global-styles/colors';
@import 'global-styles/fonts';

div.Tooltip {
  max-width: 270px;
  background-color: $brand-color11;
  opacity: 1;
  border-radius: 8px;

  .TooltipArrow {
    color: $brand-color11;
    opacity: 1;
  }
  .TooltipDescription {
    margin: 8px;
    color: white;
    font-size: 11.5px;
    font-weight: 400;
    line-height: 16px;
    font-family: $brand-primary-font-family;

    .TooltipTitleBaseline {
      font-weight: 700;
    }
    .TooltipTitle {
      display: block;
      font-weight: 600;
    }
  }
}
