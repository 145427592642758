@import 'global-styles/colors';

.Tooltip {
  position: relative;

  .Popup {
    position: absolute;
    background-color: $color-gray-90;
    padding: 6px;
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11);
    color: $color-chart-black;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    min-width: 130px;
    transform: translateX(-100%);
    top: -170%;

    .Triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px;
      border-color: $color-gray-90 transparent transparent transparent;
      top: 40%;
      left: 95%;
      transform: translateX(50%) rotate(270deg);
    }

    .Title {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }

    .Text {
      color: #fff;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
  }
}
