.FavoriteStar {
  visibility: visible;

  &.Loading {
    visibility: hidden;
  }

  .Icon {
    width: 24px;
    height: 24px;

    cursor: pointer;
  }
}
