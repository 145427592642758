@import 'global-styles/mixins/fonts';

.MarketIndexFactorDetail {
  .TitleContainer {
    margin-bottom: 24px;
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .Title {
    @include H1DarkLeft;

    margin-top: 0;
    margin-bottom: 0;
  }

  .SubfactorsContainerTitle {
    margin-bottom: 0px;
  }

  .ScreenshotFactorName {
    display: none;

    margin-bottom: 20px;
  }
}
