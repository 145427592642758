@import 'global-styles/mixins/fonts';
@import 'global-styles/mixins/factor-colors';

.ScoreLegendItem {
  @include FactorColors;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &:first-child {
    .range {
      border-radius: 4px 0px 0px 4px;
    }
  }

  &:last-child {
    .range {
      border-radius: 0px 4px 4px 0px;
    }
  }

  &.SM {
    margin-top: 25px;

    .LabelWrap {
      height: 0px;
      text-align: center;
      display: flex;
      align-items: flex-end;
    }

    .label {
      text-transform: capitalize;
    }

    .range {
      @include TinyLightCenter-Bold;
    }
  }

  &.MD {
    .label {
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 11px;
    }

    .range {
      @include Default-Dark-Center;
    }
  }

  .label {
    @include TinyDarkCenter;
  }

  .range {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
