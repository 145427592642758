$importPath: '../src/assets/fonts';
$extraLight: 200;
$light: 300;
$normal: 400;
$semiBold: 600;

@font-face {
  font-family: 'Scansky';
  src: url('#{$importPath}/Scansky-ExtraLight/Scansky-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('#{$importPath}/Scansky-ExtraLight/Scansky-ExtraLight.otf')
      format('opentype'),
    url('#{$importPath}/Scansky-ExtraLight/Scansky-ExtraLight.woff')
      format('woff'),
    url('#{$importPath}/Scansky-ExtraLight/Scansky-ExtraLight.ttf')
      format('truetype'),
    url('#{$importPath}/Scansky-ExtraLight/Scansky-ExtraLight.svg#Scansky-ExtraLight')
      format('svg');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Scansky';
  src: url('#{$importPath}/Scansky-Light/Scansky-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$importPath}/Scansky-Light/Scansky-Light.otf') format('opentype'),
    url('#{$importPath}/Scansky-Light/Scansky-Light.woff') format('woff'),
    url('#{$importPath}/Scansky-Light/Scansky-Light.ttf') format('truetype'),
    url('#{$importPath}/Scansky-Light/Scansky-Light.svg#Scansky-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Scansky';
  src: url('#{$importPath}/Scansky-Regular/Scansky-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$importPath}/Scansky-Regular/Scansky-Regular.otf') format('opentype'),
    url('#{$importPath}/Scansky-Regular/Scansky-Regular.woff') format('woff'),
    url('#{$importPath}/Scansky-Regular/Scansky-Regular.ttf') format('truetype'),
    url('#{$importPath}/Scansky-Regular/Scansky-Regular.svg#Scansky-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Scansky';
  src: url('#{$importPath}/Scansky-SemiBold/Scansky-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$importPath}/Scansky-SemiBold/Scansky-SemiBold.otf')
      format('opentype'),
    url('#{$importPath}/Scansky-SemiBold/Scansky-SemiBold.woff') format('woff'),
    url('#{$importPath}/Scansky-SemiBold/Scansky-SemiBold.ttf')
      format('truetype'),
    url('#{$importPath}/Scansky-SemiBold/Scansky-SemiBold.svg#Scansky-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

$brand-primary-font-family: 'Scansky', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
