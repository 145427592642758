@import 'global-styles/colors';

.CheckCircle {
  .Icon {
    width: 20px;
    height: 20px;

    &.Check {
      g {
        path:first-child {
          fill: $color-primary-90;
        }
      }
    }

    &.Circle {
      g {
        path {
          &:nth-child(2) {
            fill: $color-primary-90;
          }
        }
      }
    }
  }
}
