@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';
@import 'global-styles/breakpoints';

.ContentAnalysisKeywords {
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .HeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .TitleContainer {
    margin-bottom: 30px;

    display: flex;
    align-items: center;
  }

  .Title {
    @include H1DarkLeft;

    margin: 0;
  }

  .Paragraph {
    @include Paragraph;
  }

  .BrandContentContainer {
    position: relative;
  }

  .Description {
    @include Intro;
  }

  .BrandContentCompare {
    width: 100%;
    p {
      margin-top: 0;
    }
  }

  mark {
    position: relative;
    background-color: transparent;
    z-index: 0;
    font-weight: 600;
    &:after {
      content: '';
      position: absolute;
      height: 120%;
      top: -10%;
      width: 105%;
      left: -2.5%;
      z-index: -1;
      height: 1.4em;
      box-shadow: inset 0 -2px 0 0 #e8e48d;
      background-color: #fefcd3;
    }
  }

  .VerbatimsContainer {
    box-shadow: inset 0 1px 0 0 $color-gray-20;

    padding-top: 40px;
  }

  .ColumnHeader {
    padding: 16px 16px 16px 8px;
    width: 100%;
    &.ColumnHeaderWithDropdown {
      padding-left: 0;
    }
    @media (min-width: $breakpoint-md) {
      height: 80px;
    }
  }
}
