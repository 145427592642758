@import 'global-styles/mixins/fonts';

.FactorCard {
  padding: 16px;
  border-radius: 8px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px rgba(0, 0, 0, 0.05) !important;

  box-sizing: border-box;

  height: 100%;

  .Header {
    h4 {
      @include H5LinkLeft;

      margin-top: 0;
      margin-bottom: 16px;

      cursor: pointer;
    }
  }

  .Body {
    display: flex;
    flex-wrap: wrap;

    .Left {
      margin-right: 18px;
    }

    .Right {
      position: relative;
      width: calc(100% - 80px);
      height: 94px;

      .GraphWrap {
        height: 100%;
        width: 100%;
      }
    }
  }

  .Text {
    p {
      margin: 0;
    }
  }
}
