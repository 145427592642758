@import 'global-styles/colors';

.RecommendationsIcon {
  height: 32px;
  width: 32px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: $color-gray-80;

  z-index: 0;

  border-radius: 50%;

  .Icon {
    height: 24px;
    width: 24px;

    z-index: 1;
  }
}
