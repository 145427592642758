@import 'global-styles/colors';

.BrandLogoWrapper {
  padding-left: 3px;
  padding-right: 3px;
  height: 100%;
}

.BrandLogoContainer {
  width: 100%;
  height: 95%;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;

  .BrandLogo {
    flex-direction: column;
  }

  .BrandName {
    font-weight: 300;
    font-size: 12px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin-top: 3px;
  }
}

.BrandLogoContainerBackground {
  background-color: $color-gray-10;
}

.BrandLogoClickable {
  cursor: pointer;
}
