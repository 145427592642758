@import "global-styles/fonts";
@import "global-styles/colors";
@import "global-styles/breakpoints";

@mixin TitleLightRight {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: right;
  color: $ui-table-col;
}

@mixin TitleLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  color: $ui-table-col;
}

@mixin TitleLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: center;
  color: $ui-table-col;
}

@mixin TitleGreyRight {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin TitleGreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  color: $color-gray-base-60;
}

@mixin TitleGreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin TitleDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-90;
}

@mixin TitleDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  color: $color-gray-90;
}

@mixin TitleDarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 56px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-90;
}

@mixin H1LightRight {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: right;
  color: $ui-table-col;
}

@mixin H1LightLeft {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  color: $ui-table-col;
}

@mixin H1LightCenter {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: $ui-table-col;
}

@mixin H1GreyRight {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin H1GreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  color: $color-gray-base-60;
}

@mixin H1GreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin H1DarkRight {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-90;
}

@mixin H1DarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 32px;
  }
  @media (min-width: $breakpoint-lg) {
    font-size: 32px;
  }
}

@mixin H1DarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-90;
}

@mixin H2LinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  color: $color-primary-80-link;
}

@mixin H2LightRight {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  text-align: right;
  color: $ui-table-col;
}

@mixin H2LightLeft {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  color: $ui-table-col;
}

@mixin H2LightCenter {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: $ui-table-col;
}

@mixin H2GreyRight {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin H2GreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  color: $color-gray-base-60;
}

@mixin H2GreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin H2DarkRight {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 24px;
  }
}

@mixin H2DarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 400;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 24px;
    font-weight: 300;
  }
}

@mixin H2DarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 24px;
  }
}

@mixin KPICentered-Big-White {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  text-align: center;
  color: $ui-table-col;
}

@mixin KPICentered-Big {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  text-align: center;
  color: $color-gray-90;
}

@mixin H3LightRight {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: right;
  color: $ui-table-col;
}

@mixin H3LightLeft {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  color: $ui-table-col;
}

@mixin H3LightCenter {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  color: $ui-table-col;
}

@mixin H3GreyRight {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin H3GreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  color: $color-gray-base-60;
}

@mixin H3GreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin H3DarkRight {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: right;
  color: $color-gray-90;
}

@mixin H3DarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  color: $color-gray-90;
}

@mixin H3DarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  color: $color-gray-90;
}

@mixin Symbol-OrganizerGroup-Title {
  font-family: SFProText;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: $color-black;
}

@mixin H4LinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  line-height: 0.89;
  color: $color-primary-80-link;
}

@mixin H4GreyRight {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin H4GreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  color: $color-gray-base-60;
}

@mixin H4GreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin H4DarkRight {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  text-align: right;
  color: $color-gray-90;
}

@mixin H4DarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  color: $color-gray-90;
}

@mixin H4DarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  text-align: center;
  color: $color-gray-90;
}

@mixin H4LightRight {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
  color: $ui-table-col;
}

@mixin H4LightLeft {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 300;
  color: $ui-table-col;
}

@mixin H4LightCenter {
  font-family: $brand-primary-font-family;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: $ui-table-col;
}

@mixin KPICentered-White {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: center;
  color: $ui-table-col;
  @media (min-width: $breakpoint-md) {
    font-size: 17px;
  }
}

@mixin KPICentered {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: center;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 17px;
  }
}

@mixin Menuh5---40 {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  line-height: 2.5;
  color: #303a42;
}

@mixin H5LinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  line-height: 1;
  color: $color-primary-80-link;
  font-weight: $light;
  @media (min-width: $breakpoint-md) {
    font-weight: $normal;
  }
}

@mixin H5LightRight {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: right;
  color: $ui-table-col;
}

@mixin H5LightLeft {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  color: $ui-table-col;
}

@mixin H5LightCenter {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: center;
  color: $ui-table-col;
}

@mixin H5GreyRight {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin H5GreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  color: $color-gray-base-60;
}

@mixin H5GreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin H5DarkRight {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: right;
  color: $color-gray-90;
}

@mixin H5DarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  color: $color-gray-90;
  font-weight: $light;
  @media (min-width: $breakpoint-md) {
    font-weight: $normal;
  }
}

@mixin H5DarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  text-align: center;
  color: $color-gray-90;
}

@mixin Intro---Centered {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  text-align: center;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-weight: 200;
  }
}

@mixin Paragraph {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  color: $color-gray-90;
}

@mixin Intro {
  font-family: $brand-primary-font-family;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-weight: 200;
  }
}

@mixin H5LinkRight {
  font-family: SFProText;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.07;
  text-align: right;
  color: #1e9cd6;
}

@mixin DefaultRedRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin DefaultRedLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  color: $color-semantic-error-base-50;
}

@mixin DefaultRedCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin DefaultLinkRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin DefaultLinkLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  color: $color-primary-80-link;
}

@mixin DefaultLinkCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin DefaultLightRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: right;
  color: $ui-table-col;
}

@mixin DefaultLightLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  color: $ui-table-col;
}

@mixin DefaultLightCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
  color: $ui-table-col;
}

@mixin DefaultGreyRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin DefaultGreyLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  color: $color-gray-base-60;
}

@mixin DefaultGreyCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin DefaultDarkRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: right;
  color: $color-gray-90;
}

@mixin DefaultDarkLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  color: $color-gray-90;
}

@mixin Default-Dark-Center-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 13px;
  }
}

@mixin MenuLink-32 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 2.46;
  color: #1e9cd6;
}

@mixin MenuDefault-48-Right {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 3.69;
  text-align: right;
  color: #303a42;
}

@mixin MenuDefault-48 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 3.69;
  color: #303a42;
}

@mixin DefaultLightRight {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $ui-table-col;
}

@mixin DefaultLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 1.23;
  color: $ui-table-col;
}

@mixin DefaultLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $ui-table-col;
}

@mixin MenuLink-40 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  color: $color-primary-80-link;
}

@mixin MenuDefaultWhite---40 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  color: $ui-table-col;
}

@mixin MenuDefault-40-White {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  color: $ui-table-col;
}

@mixin MenuDefault-40-Right {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  text-align: right;
  color: #303a42;
}

@mixin MenuDefault-40-Grey {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  color: $color-gray-base-60;
}

@mixin MenuDefault-40 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 3.08;
  color: #303a42;
}

@mixin MenuDefault-32-Right {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.46;
  text-align: right;
  color: #303a42;
}

@mixin MenuDefault-32 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.46;
  color: #303a42;
}

@mixin MenuDefault-24-Right {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.85;
  text-align: right;
  color: #303a42;
}

@mixin MenuDefault-24 {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.85;
  color: #303a42;
}

@mixin DefaultRedRight {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin DefaultRedLeft {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-semantic-error-base-50;
}

@mixin DefaultRedCenter {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin DefaultLinkRight {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin DefaultLinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-primary-80-link;
  text-decoration: none;
}

@mixin DefaultLinkCenter {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin DefaultLink-Light-Left {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: #bfebff;
}

@mixin DefaultGreyRight {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin DefaultGreyLeft---Italic {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-gray-base-60;
}

@mixin DefaultGreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-gray-base-60;
}

@mixin DefaultGreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin DefaultDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: right;
  color: $color-gray-90;
}

@mixin DefaultDarkLeft---Italic {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-gray-90;
}

@mixin DefaultDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: $color-gray-90;
}

@mixin DefaultButtonCenter {
  font-family: $brand-primary-font-family;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  text-align: center;
  color: $color-secondary-80;
}

@mixin Default-Dark-Center {
  font-family: $brand-primary-font-family !important;
  font-size: 13px;
  font-weight: $normal;
  line-height: 1.23;
  text-align: center;
  color: $color-gray-90;
  @media (min-width: $breakpoint-md) {
    font-size: 13px;
    font-weight: $light;
  }
}

@mixin SmallRedRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin SmallRedLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  color: $color-semantic-error-base-50;
}

@mixin SmallRedCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin SmallLinkRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin SmallLinkLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  color: $color-primary-80-link;
}

@mixin SmallLinkCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin SmallLightRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: right;
  color: $ui-table-col;
}

@mixin SmallLightLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  color: $ui-table-col;
}

@mixin SmallLightCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: center;
  color: $ui-table-col;
}

@mixin SmallGreyRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin SmallGreyLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  color: $color-gray-base-60;
}

@mixin SmallGreyCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin SmallDarkRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: right;
  color: $color-gray-90;
}

@mixin SmallDarkLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  color: $color-gray-90;
}

@mixin SmallDarkCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 1.39;
  text-align: center;
  color: $color-gray-90;
}

@mixin MenuSmall-48-Right-Grey {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 4.17;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin LabelRedRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin LabelRedLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  color: $color-semantic-error-base-50;
}

@mixin LabelRedCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin LabelLinkRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin LabelLinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  color: $color-primary-80-link;
}

@mixin LabelLinkCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  line-height: 1.39;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin LabelLightRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: right;
  color: $ui-table-col;
}

@mixin LabelLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  color: $ui-table-col;
}

@mixin LabelLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: center;
  color: $ui-table-col;
}

@mixin LabelGreyRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin LabelGreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  color: $color-gray-base-60;
}

@mixin LabelGreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin LabelDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: right;
  color: $color-gray-90;
}

@mixin LabelDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  color: $color-gray-90;
}

@mixin LabelDarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  text-align: center;
  color: $color-gray-90;
}

@mixin SmallRedRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin SmallRedLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  color: $color-semantic-error-base-50;
}

@mixin SmallRedCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin SmallLinkRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin SmallLinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  color: $color-primary-80-link;
}

@mixin SmallLinkCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin SmallLightRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: right;
  color: $ui-table-col;
}

@mixin SmallLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  color: $ui-table-col;
}

@mixin SmallLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $ui-table-col;
}

@mixin SmallGreyRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin SmallGreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  color: $color-gray-base-60;
}

@mixin SmallGreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin SmallDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: right;
  color: $color-gray-90;
}

@mixin SmallDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  color: $color-gray-90;
}

@mixin SmallDarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-gray-90;
}

@mixin SmallButtonCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-secondary-80;
}

@mixin MenuSmall-40-Right-Grey {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 3.48;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin MenuSmall-40-Right {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 3.48;
  text-align: right;
  color: #303a42;
}

@mixin MenuSmall-40 {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 3.48;
  color: #303a42;
}

@mixin MenuSmall-32-White {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.78;
  color: $ui-table-col;
}

@mixin MenuSmall-32-Right-Grey {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.78;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin MenuSmall-32-Right {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.78;
  text-align: right;
  color: #303a42;
}

@mixin MenuSmall-32-Grey {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.78;
  color: $color-gray-base-60;
}

@mixin MenuSmall-32 {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.78;
  color: #303a42;
}

@mixin MenuSmall-24-White {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.09;
  color: $ui-table-col;
}

@mixin MenuSmall-24-Right-Grey {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.09;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin MenuSmall-24-Right {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.09;
  text-align: right;
  color: #303a42;
}

@mixin MenuSmall-24 {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 2.09;
  color: #303a42;
}

@mixin DefaultSmallCenter {
  font-family: $brand-primary-font-family;
  font-size: 11.5px;
  font-weight: 300;
  line-height: 1.39;
  text-align: center;
  color: $color-secondary-80;
}

@mixin TinyLightRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  text-align: right;
  color: $ui-table-col;
}

@mixin TinyLightLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  color: $ui-table-col;
}

@mixin TinyLightCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: $ui-table-col;
}

@mixin TinyDarkRight-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  text-align: right;
  color: $color-gray-90;
}

@mixin TinyDarkLeft-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  color: $color-gray-90;
}

@mixin TinyDarkCenter-Bold {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: $color-gray-90;
}

@mixin TinyLightRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: right;
  color: $ui-table-col;
}

@mixin TinyLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  color: $ui-table-col;
}

@mixin TinyLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: center;
  color: $ui-table-col;
}

@mixin TinyDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: right;
  color: $color-gray-90;
}

@mixin TinyDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  color: $color-gray-90;
}

@mixin TinyDarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: center;
  color: $color-gray-90;
}

@mixin TagRedRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $color-semantic-error-base-50;
}

@mixin TagRedLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  color: $color-semantic-error-base-50;
}

@mixin TagRedCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $color-semantic-error-base-50;
}

@mixin TagLinkRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $color-primary-80-link;
}

@mixin TagLinkLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  color: $color-primary-80-link;
}

@mixin TagLinkCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $color-primary-80-link;
}

@mixin TagLightRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: right;
  color: $ui-table-col;
}

@mixin TagLightLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  color: $ui-table-col;
}

@mixin TagLightCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: center;
  color: $ui-table-col;
}

@mixin TagGreyRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: right;
  color: $color-gray-base-60;
}

@mixin TagGreyLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  color: $color-gray-base-60;
}

@mixin TagGreyCenter {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: center;
  color: $color-gray-base-60;
}

@mixin TagDarkRight {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  text-align: right;
  color: $color-gray-90;
}

@mixin TagDarkLeft {
  font-family: $brand-primary-font-family;
  font-size: 10px;
  color: $color-gray-90;
}

@mixin TagDarkCenter {
  font-family: $brand-primary-font-family;
  font-size: 12px;
  text-align: center;
  color: $color-gray-90;
  font-weight: $normal;
  @media (min-width: $breakpoint-md) {
    font-size: 10px;
  }
}

@mixin ArchetypeCardTitle {
  font-family: $brand-primary-font-family;
  font-size: 25px;
  font-weight: $semiBold;
}
