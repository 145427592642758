@import "global-styles/colors";
@import "global-styles/mixins/fonts";
@import "global-styles/breakpoints";

.BrandTopicContainer {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    right: -4px;
    top: 0;
    height: 100%;
    width: 64px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 83%
    );

    z-index: 3;
  }
  [class*="BrandLogo_BrandLogo"] {
    margin-bottom: 8px;
  }
  .KeywordColumn {
    position: relative;
    flex: none;
    padding: 8px;
    background-color: white;
    box-shadow: inset -1px 0 0 0 $color-gray-20;
    text-align: center;
    @media (min-width: $breakpoint-mobile) {
      width: 272px;
      &:last-of-type {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 50px;
          height: 100%;
          background-color: white;
        }
      }
    }
    .Header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      @media (min-width: $breakpoint-mobile) {
        p {
          height: 4em;
        }
      }
    }
  }

  .BrandTopicContainerInner {
    display: flex;
    overflow-x: auto;
    width: auto;
  }
  .TopicColumnSelectedBrand {
    position: relative;
    box-shadow: 1px 0px 9px $color-gray-20;
  }
}

.TrendCard {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  padding: 16px 16px 24px 40px;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: white;

  z-index: 4;

  .TrendCardControls {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .Close {
    width: 32px;
    height: 32px;
    padding: 0 !important;
    svg {
      height: 100%;
      width: 100%;
      path + path {
        fill: $color-secondary-90;
      }
    }
  }
  .TrendCardColumn {
    padding: 0 10px;
    .ColumnTitle {
      text-transform: uppercase;
      @include LabelGreyCenter;
      margin-bottom: 16px;
    }
  }
  .TrendCardLogoContainer {
    margin-bottom: 20px;
  }
}
