@import 'global-styles/mixins/fonts';

.ScoreTilePercent {
  @include H5DarkCenter;

  height: 80px;
  width: 80px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 1px solid transparent;

  transition: all 0.2s ease-in-out;
  animation: score-tile-enter 1s ease-in;

  .DefaultColor {
    background-color: $color-gray-20;
  }

  .Value {
    line-height: 1;
    color: $color-gray-90;
  }

  &.DisableAnimation {
    transition: none;
    animation: none;
  }
}

@keyframes score-tile-enter {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
