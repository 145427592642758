@import 'global-styles/mixins/fonts';

.Alert {
  @include DefaultDarkLeft;

  width: 100%;

  border-radius: 8px;

  padding: 12px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .CloseIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &.Warning {
    background-color: #fff9e2;
  }

  .Right {
    display: flex;
    align-items: center;
  }
}
