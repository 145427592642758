@import 'global-styles/colors';
@import '../../../WidgetSettingsShared/sharedStyles';

.SingleMetricSettingsForm {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Label {
    color: $color-gray-base-60;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }

  .Dropdown {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    border-radius: 8px;

    box-shadow: 0px 1px 2px 0px $shadow-input;
  }

  .Required {
    @include requiredAstrik;
  }

  .Field {
    @include formInput;
  }
}
