@import 'global-styles/_colors.scss';

.CreativeAdvertisingCard {
  width: 400px;
  height: 160px;
  padding: 12px;
  margin-top: 8px;
  background: white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .Total {
    color: $color-secondary-90;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    margin: 0;
  }

  &:hover {
    transition: all 0.3s ease-out;
    cursor: pointer;
    transform: translate3d(0px, -5px, 0px);
    box-shadow: 0px 10px 14px $shadow-hover-gray;
    border-radius: 8px;
  }
}

.ChartContainer {
  height: 48%;
  width: 98%;
}
