@import 'global-styles/mixins/fonts';
@import 'global-styles/colors';

.ModalPaper {
  max-width: 616px;
  min-width: 300px;

  width: 100%;

  border-radius: 8px !important;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px;

    background-color: $color-gray-90;

    .Title {
      @include H4LightLeft;

      margin: 0;
    }

    .Icon {
      height: 24px;
      width: 24px;

      cursor: pointer;
      color: $color-secondary-90;
    }
  }

  .Footer {
    background-color: $color-secondary-10;

    padding: 16px;
  }
}
