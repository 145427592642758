@import 'global-styles/mixins/fonts';

.ShareMenuItem {
  border-radius: 8px;
  border: solid 1px transparent;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.05);
  }

  .LogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .Logo {
      height: 24px;
      width: 24px;
    }
  }

  .Label {
    @include SmallDarkLeft;
  }
}
