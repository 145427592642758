@import 'global-styles/colors';

.Container {
  display: flex;
  gap: 2px;
  align-items: center;
}

.Up {
  color: #0d7e47;
}
.Down {
  color: #820000;
}
.Right {
  color: #7d7d7d;
}

svg.Icon {
  width: 9.27px;
  height: 9.27px;
  &:hover {
    cursor: default;
  }
}

.LabelDescription {
  color: $color-gray-70;
  font-size: 9px;
  line-height: 11px;
  font-weight: 300;
  text-transform: uppercase;

  span {
    font-size: 11px;
    letter-spacing: -0.2px;
    text-transform: none;
  }
}
