@import 'global-styles/colors';

@mixin IconButton {
  color: $color-secondary-90;
  background-color: $color-secondary-10;
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;
  min-height: 24px;
  min-width: 24px;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: $color-primary-20;
    color: $icon-default;
  }

  &:active {
    background-color: $color-secondary-30;
    color: $icon-default;
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #7e9397;
  }
}
