@import 'global-styles/colors';
@import 'global-styles/mixins/fonts';

.UserCount {
  .LabelContainer {
    margin-bottom: 8px;

    .Label {
      @include DefaultDarkLeft-Bold;
    }

    .Count {
      @include DefaultDarkLeft---Italic;
    }
  }

  .DotCounter {
    display: flex;

    .Dot {
      height: 8px;
      width: 8px;

      margin-right: 4px;

      background-color: $input-disabled;

      border-radius: 50%;

      &:last-child {
        margin-right: 0;
      }

      &.Filled {
        background-color: $color-primary-base-60;
      }
    }
  }

  .BarCounter {
    max-width: 176px;
    width: 100%;
    height: 8px;

    border-radius: 4px;

    background-color: $input-disabled;

    position: relative;

    .Fill {
      position: absolute;
      left: 0;

      max-width: 176px;

      height: 100%;

      border-radius: 4px;

      background-color: $color-primary-base-60;
    }
  }
}
