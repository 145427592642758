@import 'global-styles/colors';

.VerbatimBrand {
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 8px;
  padding: 16px 16px 16px 8px;

  border: solid 1px $color-gray-20;
  border-radius: 8px;

  & + .VerbatimBrand {
    margin-top: 30px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 20px;
  }

  &:before {
    top: 100%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-top: 15px solid $color-gray-20;

    z-index: 3;
  }

  &:after {
    top: calc(100% - 1.5px);

    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-top: 15px solid white;
    z-index: 4;
  }

  .Tag {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    justify-self: end;
  }
}
