@import 'global-styles/_colors.scss';

.DiffPillMiniContainer {
  border: none;
  padding: 1px 5px;
  width: 50px;
  height: 20px;

  text-align: center;
  text-decoration: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;

  .Text {
    margin: 0;
    font-weight: 400;
    line-height: 13px;
    font-size: 10px;
  }

  &.Positive {
    background-color: $color-semantic-success-10;

    .Text {
      color: $color-semantic-success-70;
    }
  }

  &.Negative {
    background-color: $color-semantic-error-10;

    .Text {
      color: $color-semantic-error-70;
    }
  }
}
