@import 'global-styles/colors';
@import '../../../WidgetSettingsShared/sharedStyles';

.MetricComparisonSettingsForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Label {
  @include label;
}

.Dropdown {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;

  border-radius: 8px;

  box-shadow: 0 1px 2px 0 $shadow-input;
}

.Required {
  @include requiredAstrik;
}

.Field {
  @include formInput;
}
