@import 'global-styles/colors';

.App {
  min-height: 100vh;
  max-width: 100vw;

  min-width: 320px;

  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &.HideNavs {
    .Content {
      padding: 0 0 140px 0;
    }

    .Footer {
      padding-left: 0;
    }
  }
}
