@import "global-styles/colors";
@import "global-styles/mixins/fonts";

.Wrap {
    padding: 120px 0 120px 0;

    .GradientBar {
        position: relative;
        width: 100%;
        height: 16px;
        border-radius: 8px;
        background: rgb(2, 0, 36);
        background: linear-gradient(90deg,
                $brand-color1 22%,
                $brand-color2 33%,
                $brand-color3 44%,
                $brand-color4 55%,
                $brand-color5 65%,
                $brand-color6 75%,
                $brand-color7 100%);

        .Pin {
            position: absolute;

            &.Top {
                bottom: 22px;
            }

            &.Bottom {
                top: 22px;
            }
        }
    }

    .Dividers {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        color: transparent;

        .Divider {
            border-right: 1px solid black;
            flex-grow: 1;
            margin: 0;
            height: 100%;
            position: relative;

            &:first-child {
                flex-grow: 2;
            }

            &:last-child {
                flex-grow: 2;
                border-right: 0;
            }

            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4) {
                border-color: white;
            }
        }
    }

    .LimitsWrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 11.5px;
        font-weight: 600;
        line-height: 1.39;
        color: #2b3a45;

        .LimitsLeft {
            width: 20px;
            display: flex;
            flex-direction: column;
            padding-left: 4px;

            .Mark {
                height: 8px;
                border-left: 1px solid #2b3a45;
                width: 100%;
                margin-bottom: 4px;
            }

            .Amount {
                text-align: left;
                width: 100%;
            }
        }

        .LimitsRight {
            width: 20px;
            display: flex;
            flex-direction: column;
            padding-right: 4px;

            .Mark {
                height: 8px;
                border-right: 1px solid #2b3a45;
                width: 100%;
                margin-bottom: 4px;
            }

            .Amount {
                text-align: right;
                width: 100%;
            }
        }
    }
}