@import 'global-styles/mixins/fonts';

.MarketIndexFactorsOverview {
  .TitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Title {
      @include H1DarkLeft;

      margin: 0;
    }
  }

  .Header {
    @include H5DarkLeft;

    display: flex;
    align-items: center;
  }

  .Title {
    @include H1DarkLeft;
  }

  .SubfactorChartContainer {
    width: 100%;
    height: 430px;

    margin-bottom: 28px;
  }

  .ChartLegendContainer {
    margin-bottom: 24px;

    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  .ScoreLegendContainer {
    width: 100%;
  }

  .Paragraph {
    @include Intro;
  }

  .Subtitle {
    @include H2DarkLeft;
  }
}
